import {
  JiraSettingsInput,
  type JiraProjectType,
  type JiraSettingsType,
} from '@/shared/constants/jira'
import dayjs from '@/shared/singletons/dayjs'
import { Button } from '@/web/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/web/components/ui/form'
import { Skeleton } from '@/web/components/ui/skeleton'
import { Switch } from '@/web/components/ui/switch'
import { env } from '@/web/env'
import { useIntegration } from '@/web/hooks/use-integration'
import { api } from '@/web/utils/api'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { IntegrationName } from '@prisma/client'
import Image from 'next/image'
import pluralize from 'pluralize'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

function getProjectSettings(
  projects: JiraProjectType[],
  projectSettings: JiraSettingsType['projects'],
  options: { id?: string; isEnabled: boolean }
): JiraSettingsType['projects'] {
  return projects.map(project => {
    const existingSetting = projectSettings.find(
      projectSetting => projectSetting.id === project.id
    )
    return {
      id: project.id,
      name: project.name,
      isEnabled:
        options.id === project.id || !options.id
          ? options.isEnabled
          : existingSetting?.isEnabled ?? false,
    }
  })
}

export function JiraSettings() {
  const jiraIntegration = useIntegration(IntegrationName.JIRA)

  const jiraProjectsQuery = api.jira.projects.useQuery()

  const updateJiraSettingsMutation = api.jira.updateSettings.useMutation()

  const form = useForm<JiraSettingsType>({
    resolver: zodResolver(JiraSettingsInput),
    defaultValues: {
      projects: [],
    },
  })

  const onSubmit = (data: JiraSettingsType) => {
    console.log('Saving settings', data)
    updateJiraSettingsMutation.mutate(data)
  }

  const utils = api.useUtils()
  useEffect(() => {
    console.log('Jira access token changed - refreshing projects', {
      accessToken: jiraIntegration?.accessToken,
    })
    utils.jira.projects.invalidate()
  }, [jiraIntegration?.accessToken])

  // Save form on change
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [form.handleSubmit, form.watch])

  // Set initial values from integration
  useEffect(() => {
    if (
      jiraIntegration?.settings &&
      JiraSettingsInput.parse(jiraIntegration.settings)
    ) {
      form.reset(jiraIntegration.settings as JiraSettingsType)
    }
  }, [jiraIntegration?.settings])

  if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log('Jira settings', form.formState)
    }, [form.formState])
  }

  if (jiraProjectsQuery.isLoading) {
    return <Skeleton className='h-48 w-full' />
  }

  if (jiraProjectsQuery.isError) {
    return <p className='text-error'>Error loading projects</p>
  }

  if (!jiraProjectsQuery?.data?.length) {
    return <p className='text-muted-foreground italic'>No projects found</p>
  }

  const projects = jiraProjectsQuery.data

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <>
          <FormMessage>
            {form.formState.errors.root?.message ??
              updateJiraSettingsMutation.error?.message}
          </FormMessage>
          <FormField
            control={form.control}
            name='projects'
            render={({ field }) => (
              <FormItem className='space-y-4'>
                <div>
                  <FormLabel className='text-base'>Projects</FormLabel>
                  <FormDescription>
                    Select the projects you want Pulse to manage.
                  </FormDescription>
                </div>
                <div className='grid gap-4 md:grid-cols-2'>
                  <Button
                    variant='outline'
                    theme='success'
                    onClick={() =>
                      field.onChange(
                        getProjectSettings(projects, field.value, {
                          isEnabled: true,
                        })
                      )
                    }
                    disabled={
                      (!!field.value.length &&
                        field.value.every(
                          projectSetting => projectSetting.isEnabled
                        )) ??
                      false
                    }
                    startIcon={<FontAwesomeIcon icon={faCheck} />}>
                    Enable All Projects
                  </Button>
                  <Button
                    variant='outline'
                    theme='destructive'
                    onClick={() =>
                      form.setValue(
                        'projects',
                        getProjectSettings(projects, field.value, {
                          isEnabled: false,
                        })
                      )
                    }
                    disabled={
                      field.value.every(
                        projectSetting => !projectSetting.isEnabled
                      ) ?? true
                    }
                    startIcon={<FontAwesomeIcon icon={faTimes} />}>
                    Disable All Projects
                  </Button>
                </div>
                {form.formState.errors.projects ? (
                  <FormMessage>
                    {form.formState.errors.projects.message}
                  </FormMessage>
                ) : null}
                <div className='space-y-1'>
                  {projects
                    ?.slice(0)
                    .sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
                    .map(
                      ({
                        id,
                        name,
                        avatarUrl,
                        issueCount,
                        lastIssueUpdatedDate,
                      }) => {
                        return (
                          <FormItem
                            key={id}
                            className='flex flex-row items-center justify-start gap-2'>
                            <FormControl>
                              <Switch
                                id={id}
                                theme='success'
                                checked={
                                  field.value.find(
                                    projectSetting => projectSetting.id === id
                                  )?.isEnabled ?? false
                                }
                                onCheckedChange={checked => {
                                  const updatedValue = getProjectSettings(
                                    projects,
                                    field.value,
                                    {
                                      id,
                                      isEnabled: checked,
                                    }
                                  )
                                  console.log('Updating projects', updatedValue)
                                  field.onChange(updatedValue)
                                }}
                              />
                            </FormControl>
                            <FormLabel
                              htmlFor={id}
                              className='flex flex-row items-center space-x-2'>
                              <Image
                                src={avatarUrl}
                                width={20}
                                height={20}
                                alt={name}
                              />
                              <span>{name}</span>
                              {issueCount ? (
                                <small className='text-muted-foreground'>
                                  {issueCount} {pluralize('issue', issueCount)},
                                  last updated{' '}
                                  {dayjs(lastIssueUpdatedDate).fromNow()}
                                </small>
                              ) : null}
                            </FormLabel>
                          </FormItem>
                        )
                      }
                    )}
                </div>
              </FormItem>
            )}
          />
        </>
      </form>
    </Form>
  )
}
