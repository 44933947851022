import { type ActionParametersType } from '@/shared/constants/action'
import { SAMPLE_TASK_1 } from '@/shared/constants/demo'
import { GMAIL_SCOPES } from '@/shared/constants/gmail'
import { GOOGLE_CALENDAR_SCOPES } from '@/shared/constants/google-calendar'
import { JIRA_SCOPES } from '@/shared/constants/jira'
import {
  SLACK_USER_SCOPES,
  type SlackMemberMap,
} from '@/shared/constants/slack'
import { type TaskWithContextItems } from '@/shared/constants/task'
import { SCOPES as YOUTUBE_SCOPES } from '@/shared/constants/youtube'
import { ActionType, IntegrationName } from '@prisma/client'
import { z } from 'zod'
import { REDDIT_SCOPES } from './reddit'

export type IntegrationActionType<
  // TIntegrationName extends IntegrationName,
  TActionType extends ActionType = ActionType,
> = {
  type: TActionType
  description: string
  defaultParameters: Partial<
    // IntegrationActionParametersType<TIntegrationName, TActionType>
    ActionParametersType<TActionType>
  >
  task: TaskWithContextItems
}

export type IntegrationData<T extends IntegrationName> =
  T extends typeof IntegrationName.SLACK ? IntegrationSlackData : unknown

export type IntegrationSlackData = {
  ok: boolean
  team: {
    id: string
    name: string
  }
  scope: string
  app_id: string
  enterprise: null
  token_type: string
  authed_user: {
    id: string
  }
  bot_user_id: string
  access_token: string
  is_enterprise_install: boolean
  simpleMembersMap: SlackMemberMap
}

export enum IntegrationCategory {
  A_B_TESTING = 'A/B Testing',
  ADVERTISING = 'Advertising',
  ANALYTICS = 'Analytics',
  ATTRIBUTION = 'Attribution',
  COLLABORATION = 'Collaboration',
  COMMUNICATION = 'Communication',
  CUSTOMER_SUCCESS = 'Customer Success',
  DESIGN = 'Design',
  DEVELOPMENT = 'Development',
  ECOMMERCE = 'E-Commerce',
  MARKETING = 'Marketing',
  PAYMENTS = 'Payments',
  PERFORMANCE_MONITORING = 'Performance Monitoring',
  PRODUCTIVITY = 'Productivity',
  SALES = 'Sales',
  SOCIAL = 'Social',
  STORAGE = 'Storage',
  SURVEYS = 'Surveys',
}

export const AVAILABLE_INTEGRATION_NAMES: IntegrationName[] = [
  IntegrationName.REDDIT,
  IntegrationName.YOUTUBE,
  IntegrationName.GMAIL,
  IntegrationName.GOOGLE_CALENDAR,
  IntegrationName.JIRA,
  IntegrationName.SLACK,
  IntegrationName.NOTION,
]
export const COMING_SOON_INTEGRATION_NAMES: IntegrationName[] = [
  IntegrationName.MICROSOFT_TEAMS,
  IntegrationName.OUTLOOK,
  IntegrationName.CONFLUENCE,
  // IntegrationName.FIGMA,
]
export const INTEGRATION_GROUPS = {
  Available: AVAILABLE_INTEGRATION_NAMES,
  'Coming Soon': COMING_SOON_INTEGRATION_NAMES,
}

export const IntegrationUpdateSchema = z.object({
  name: z.nativeEnum(IntegrationName),
  isEnabled: z.boolean().default(true),
})

export type IntegrationBasicData = {
  displayName: string
  description: string
  categories: IntegrationCategory[]
  scopes: string[]
  questionsAnswers?: {
    question: string
    answer: string
  }[]
  actions?: IntegrationActionType[]
}

export const INTEGRATION_BASIC_DATA: Record<
  IntegrationName,
  IntegrationBasicData
> = {
  [IntegrationName.REDDIT]: {
    displayName: 'Reddit',
    description: 'Increase your karma and promote your business tastefully.',
    categories: [IntegrationCategory.SOCIAL],
    scopes: REDDIT_SCOPES,
    questionsAnswers: [
      {
        question: 'How does the Reddit integration help me?',
        answer:
          'The integration helps you manage your Reddit account, post content, and engage with your audience.',
      },
      {
        question: 'What can I do with the Reddit integration?',
        answer:
          'You can post content, comment on posts, reply to comments, and monitor keywords to grow your karma and promote your business without getting banned or flagged as spam.',
      },
      {
        question: 'How do comments and posts get created?',
        answer:
          'Pulse will identify where and how you should engage, offering you the text to copy and the link to click. You simply paste and create the post/comment on Reddit yourself.',
      },
      {
        question: 'Will this get me banned on Reddit?',
        answer:
          'No, Pulse will help you engage in a way that is respectful of Reddit’s rules and guidelines. We will never suggest anything that could get you banned, nor do we actually post or comment for you. We simply make it 10x faster to grow your karma and promote your business tastefully.',
      },
      {
        question: 'Do I need to connect with Reddit?',
        answer:
          'No, you can use Pulse for Reddit without connecting your account. Connecting Pulse to Reddit will ensure you don’t spam or post duplicate content. Without knowing your account, Pulse can’t determine which user you are, and the amount of activities you can do might be limited due to Reddit’s API call limitations.',
      },
    ],
  },
  [IntegrationName.GMAIL]: {
    displayName: 'Gmail',
    description: 'Turn your emails into actionable tasks.',
    categories: [IntegrationCategory.COMMUNICATION],
    scopes: GMAIL_SCOPES,
    actions: [
      {
        type: ActionType.SEND_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Sends an email.',
        defaultParameters: {
          to: ['person@company.com'],
          from: 'me@company.com',
          title: 'Send email',
          message: 'Send an email.',
        },
      },
      {
        type: ActionType.REPLY_TO_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Replies to an email.',
        defaultParameters: {
          emailId: 'abc123',
          to: ['person@company.com'],
          from: 'me@company.com',
          message: 'Reply to an email.',
        },
      },
      {
        type: ActionType.FORWARD_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Forwards an email.',
        defaultParameters: {
          forwardTo: 'person@company.com',
          emailId: 'abc123',
        },
      },
      {
        type: ActionType.ARCHIVE_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Archives an email.',
        defaultParameters: { emailId: 'abc123' },
      },
      {
        type: ActionType.ARCHIVE_EMAILS,
        task: SAMPLE_TASK_1,
        description: 'Archives emails.',
        defaultParameters: { emailIds: ['abc123', 'def456', 'ghi789'] },
      },
      {
        type: ActionType.REPORT_SPAM_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Reports email as spam.',
        defaultParameters: { emailId: 'abc123' },
      },
      {
        type: ActionType.REPORT_SPAM_EMAILS,
        task: SAMPLE_TASK_1,
        description: 'Reports emails as spam.',
        defaultParameters: { emailIds: ['abc123', 'def456', 'ghi789'] },
      },
    ],
    questionsAnswers: [
      {
        question: 'How does the Gmail integration help manage my inbox?',
        answer:
          'The integration analyzes your emails to provide concise summaries, priority sorting, and simple workflows. This helps you stay on top of important communications efficiently.',
      },
    ],
  },
  [IntegrationName.YOUTUBE]: {
    displayName: 'YouTube',
    description: 'Respond to comments on your videos.',
    categories: [IntegrationCategory.SOCIAL],
    scopes: YOUTUBE_SCOPES,
    questionsAnswers: [
      {
        question: 'How does the YouTube integration work?',
        answer:
          'Once connected, you’ll see a new item in the sidebar that shows all your videos. You can click on a video to see its comments and reply to them directly from the app.',
      },
    ],
  },
  [IntegrationName.OUTLOOK]: {
    displayName: 'Outlook',
    description: 'Turn your emails into actionable tasks.',
    categories: [IntegrationCategory.COMMUNICATION],
    scopes: [], //OUTLOOK_SCOPES,
    actions: [
      {
        type: ActionType.SEND_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Sends an email.',
        defaultParameters: {
          to: ['person@company.com'],
          from: 'me@company.com',
          title: 'Send email',
          message: 'Send an email.',
        },
      },
      {
        type: ActionType.REPLY_TO_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Replies to an email.',
        defaultParameters: {
          emailId: 'abc123',
          to: ['person@company.com'],
          from: 'me@company.com',
          message: 'Reply to an email.',
        },
      },
      {
        type: ActionType.FORWARD_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Forwards an email.',
        defaultParameters: {
          forwardTo: 'person@company.com',
          emailId: 'abc123',
        },
      },
      {
        type: ActionType.ARCHIVE_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Archives an email.',
        defaultParameters: { emailId: 'abc123' },
      },
      {
        type: ActionType.ARCHIVE_EMAILS,
        task: SAMPLE_TASK_1,
        description: 'Archives emails.',
        defaultParameters: { emailIds: ['abc123', 'def456', 'ghi789'] },
      },
      {
        type: ActionType.REPORT_SPAM_EMAIL,
        task: SAMPLE_TASK_1,
        description: 'Reports email as spam.',
        defaultParameters: { emailId: 'abc123' },
      },
    ],
    questionsAnswers: [
      {
        question: 'How does the Outlook integration help manage my inbox?',
        answer:
          'The integration analyzes your emails to provide concise summaries, priority sorting, and simple workflows. This helps you stay on top of important communications efficiently.',
      },
    ],
  },
  [IntegrationName.GOOGLE_CALENDAR]: {
    displayName: 'Google Calendar',
    description: 'Seamlessly organize your schedule.',
    categories: [IntegrationCategory.PRODUCTIVITY],
    scopes: GOOGLE_CALENDAR_SCOPES,

    actions: [
      {
        type: ActionType.CREATE_EVENT,
        task: SAMPLE_TASK_1,
        description: 'Create a new event in Google Calendar.',
        defaultParameters: {
          title: 'New Event',
          details: 'This will be filled in automatically.',
          start: new Date(),
          end: new Date(),
          attendees: ['someone@example.com'],
        },
      },
    ],
    questionsAnswers: [
      {
        question:
          'How does the Google Calendar integration assist in scheduling?',
        answer:
          'The integration offers insights into your schedule, providing analyses of your busiest days, event summaries, and productivity metrics, assisting you in making informed scheduling decisions.',
      },
      {
        question: 'Can I choose which calendars are used?',
        answer:
          'Yes, simply enable the calendars you want to include and disable the ones you do not.',
      },
    ],
  },
  [IntegrationName.JIRA]: {
    displayName: 'Jira',
    description:
      'Optimize your project management with insights and workflows from your Jira boards.',
    categories: [IntegrationCategory.DEVELOPMENT],
    scopes: JIRA_SCOPES,
    actions: [
      {
        type: ActionType.CREATE_TASK,
        task: SAMPLE_TASK_1,
        description: 'Create new issues in Jira.',
        defaultParameters: {
          cloudId: 'cloudId',
          projectId: 'projectId',
          issueTypeId: 'issueTypeId',
          summary: 'New issue',
          description: 'This will be filled in automatically.',
          additionalProperties: {},
        },
      },
      {
        type: ActionType.UPDATE_TASK,
        task: SAMPLE_TASK_1,
        description: 'Update an existing issue in Jira.',
        defaultParameters: {
          cloudId: 'cloudId',
          projectId: 'projectId',
          issueId: 'issueId',
          originalSummary: 'Existing issue',
          summary: 'Updated summary',
          description: 'This will be filled in automatically.',
          additionalProperties: {},
        },
      },
      {
        type: ActionType.COMMENT,
        task: SAMPLE_TASK_1,
        description: 'Comment on an existing issue in Jira.',
        defaultParameters: {
          id: '123',
          title: 'Existing issue',
          comment: 'This will be filled in automatically.',
        },
      },
    ],
    questionsAnswers: [
      {
        question: 'How can the Jira integration enhance my project management?',
        answer:
          'It provides insights into issue trends, team performance, and project progress, helping managers make informed decisions.',
      },
      {
        question: 'Is my Jira data secure?',
        answer:
          'Absolutely! With our robust privacy controls, you decide which data is accessed, ensuring project information remains confidential.',
      },
    ],
  },
  [IntegrationName.NOTION]: {
    displayName: 'Notion',
    description:
      'Transform your Notion workspace with comprehensive insights and summaries.',
    categories: [IntegrationCategory.COLLABORATION],
    scopes: [],
  },
  [IntegrationName.CONFLUENCE]: {
    displayName: 'Confluence',
    description:
      'Transform your Confluence workspace with comprehensive insights and summaries.',
    categories: [IntegrationCategory.COLLABORATION],
    scopes: [],
  },
  [IntegrationName.SLACK]: {
    displayName: 'Slack',
    description: 'Transform your Slack conversations into actionable insights.',
    categories: [IntegrationCategory.COMMUNICATION],
    scopes: SLACK_USER_SCOPES,
    actions: [
      {
        type: ActionType.SEND_MESSAGE,
        task: SAMPLE_TASK_1,
        description: 'Send messages to your Slack conversations.',
        defaultParameters: {
          conversationName: 'your conversation',
          conversationId: 'abc123',
          messageOptions: [
            {
              message: 'Hello',
              title: 'Say hello',
            },
            {
              message: 'Goodbye',
              title: 'Say goodbye',
            },
          ],
        },
      },
      {
        type: ActionType.REPLY_TO_MESSAGE,
        task: SAMPLE_TASK_1,
        description: 'Reply to messages in your Slack conversations.',
        defaultParameters: {
          conversationId: 'abc123',
          conversationName: 'your conversation',
          messageOptions: [
            {
              message: 'Hello',
              title: 'Say hello',
            },
            {
              message: 'Goodbye',
              title: 'Say goodbye',
            },
          ],
          originalMessageId: '123456789',
        },
      },
    ],
    questionsAnswers: [
      {
        question: 'How do I connect multiple Slack workspaces?',
        answer: 'You can only connect a single Slack workspace at this time.',
      },
      {
        question: 'Can I choose which channels are accessed?',
        answer:
          "Yes, once you've connected your workspace, you'll have the option to select which channels can be accessed. You can edit them on this page at any time.",
      },
      {
        question: 'Is my data safe?',
        answer:
          'Absolutely! We prioritize your data security. All data transmissions are encrypted, and we adhere to strict privacy standards.',
      },
    ],
  },
  [IntegrationName.MICROSOFT_TEAMS]: {
    displayName: 'Microsoft Teams',
    description:
      'Transform your Microsoft Teams conversations into actionable insights.',
    categories: [IntegrationCategory.COMMUNICATION],
    scopes: [], //MICROSOFT_TEAMS_USER_SCOPES,
    actions: [
      {
        type: ActionType.SEND_MESSAGE,
        task: SAMPLE_TASK_1,
        description: 'Send messages to your Microsoft Teams conversations.',
        defaultParameters: {
          conversationName: 'your conversation',
          conversationId: 'abc123',
          messageOptions: [
            {
              message: 'Hello',
              title: 'Say hello',
            },
            {
              message: 'Goodbye',
              title: 'Say goodbye',
            },
          ],
        },
      },
      {
        type: ActionType.REPLY_TO_MESSAGE,
        task: SAMPLE_TASK_1,
        description: 'Reply to messages in your Microsoft Teams conversations.',
        defaultParameters: {
          conversationId: 'abc123',
          conversationName: 'your conversation',
          messageOptions: [
            {
              message: 'Hello',
              title: 'Say hello',
            },
            {
              message: 'Goodbye',
              title: 'Say goodbye',
            },
          ],
          originalMessageId: '123456789',
        },
      },
    ],
    questionsAnswers: [
      {
        question: 'How do I connect multiple Teams workspaces?',
        answer: 'You can only connect a single Teams workspace at this time.',
      },
      {
        question: 'Can I choose which channels are accessed?',
        answer:
          "Yes, once you've connected your workspace, you'll have the option to select which channels can be accessed. You can edit them on this page at any time.",
      },
      {
        question: 'Is my data safe?',
        answer:
          'Absolutely! We prioritize your data security. All data transmissions are encrypted, and we adhere to strict privacy standards.',
      },
    ],
  },
}
