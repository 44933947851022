import { type EmailListType } from '@/shared/constants/action'
import {
  type NonEmptySlackContextDataType,
  type SlackConversation,
  type SlackConversationType,
  type SlackMember,
  type SlackMessage,
} from '@/shared/constants/slack'
import {
  type TaskWithContextItems,
  type TaskWithIntegrationContextItems,
} from '@/shared/constants/task'
import { hasContext } from '@/shared/utils/context'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'

export function getConversationTypePrefix(type: SlackConversationType): string {
  return type === 'channel' ? '#' : type === 'dm' ? '@' : ''
}

export function getFullConversationName({
  name,
  type,
}:
  | {
      name?: string | null
      type: SlackConversationType
    }
  | SlackConversation): string {
  return `${getConversationTypePrefix(type)}${name ?? 'unknown'}`
}

export function hasSlackData(
  task: TaskWithContextItems
): task is TaskWithIntegrationContextItems<typeof IntegrationName.SLACK> {
  return hasContext(task, IntegrationName.SLACK)
}

export function isSlackTask(
  task: TaskWithContextItems
): task is TaskWithIntegrationContextItems<typeof IntegrationName.SLACK> & {
  integrationName: typeof IntegrationName.SLACK
} {
  return task.integrationName === IntegrationName.SLACK && hasSlackData(task)
}

export function isSlackData(
  data: unknown
): data is NonEmptySlackContextDataType {
  return (
    !!data &&
    typeof data === 'object' &&
    'conversations' in data &&
    Array.isArray(data.conversations) &&
    data.conversations?.length > 0 &&
    data.conversations.some(conversation => conversation.messages?.length > 0)
  )
}

export function getEmailList(conversation: SlackConversation): EmailListType {
  return (
    (conversation.messages
      ?.flatMap(message => [
        {
          name: message.user.name,
          email: message.user.email,
        },
        ...(message.replies?.map(reply => ({
          name: reply.user.name,
          email: reply.user.email,
        })) ?? []),
      ])
      .filter(({ name, email }) => name && email) as EmailListType) ?? []
  )
}

function getFlatMessages(messages?: SlackMessage[] | null): SlackMessage[] {
  if (!messages) {
    return []
  }
  return messages.concat(
    ...messages.flatMap(({ replies }) =>
      replies ? getFlatMessages(replies) : []
    )
  )
}

export function getMostRecentMessage(
  messages?: SlackMessage[] | null
): SlackMessage | null {
  const allMessages = getFlatMessages(messages)
  return (
    allMessages.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    )[0] ?? null
  )
}

export function getMessageCount(messages?: SlackMessage[] | null): number {
  return (
    messages?.reduce((count, message) => {
      return count + 1 + (message.replies?.length ?? 0)
    }, 0) ?? 0
  )
}

export function getConversationsMessageCount(
  conversations: SlackConversation[]
): number {
  return conversations.reduce((count, conversation) => {
    return count + getMessageCount(conversation.messages)
  }, 0)
}

export function getConversationUsers(
  conversation: SlackConversation
): SlackMember[] {
  return getMessagesUsers(conversation.messages)
}

export function getMessagesUsers(
  messages?: SlackMessage[] | null
): SlackMember[] {
  return _.uniqWith(
    messages?.flatMap(({ user, replies }) => [
      user,
      ...getMessagesUsers(replies),
    ]),
    _.isEqual
  )
}

export function getMessageUserIds(messages?: SlackMessage[]): string[] {
  return _.uniq(
    messages?.flatMap(({ user, replies }) => [
      user.id,
      ...getMessageUserIds(replies),
    ])
  )
}

export function getConversationUser(
  conversation: SlackConversation,
  userId: string
): SlackMember | null {
  if (!conversation.messages?.length) {
    return null
  }
  for (const message of conversation.messages) {
    if (message.user.id === userId) {
      return message.user
    }
    if (message.replies?.length) {
      for (const reply of message.replies) {
        if (reply.user.id === userId) {
          return reply.user
        }
      }
    }
  }

  return null
}

export function getTaskOriginConversation(
  contextData: NonEmptySlackContextDataType
): SlackConversation | null {
  return (
    contextData.conversations.find(
      conversation =>
        conversation.messages?.some(message => message.isTaskOrigin)
    ) ?? null
  )
}
