import { KEYBOARD_SHORTCUT } from '@/web/constants/keyboard'
import { useKeyboardShortcut } from '@/web/hooks/useKeyboardShortcut'
import { currentTaskIdState } from '@/web/state/current-task-id'
import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'

export const useGlobalKeyboardShortcuts = () => {
  // Blur on ESCAPE
  const setCurrentTaskId = useSetRecoilState(currentTaskIdState)
  const blurTask = useCallback(() => {
    setCurrentTaskId(null)
  }, [setCurrentTaskId])

  useKeyboardShortcut(KEYBOARD_SHORTCUT.TASK_BLUR, blurTask)

  return null
}
