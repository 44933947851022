'use client'

import { env } from '@/web/env'
import { useTheme } from 'next-themes'
import { Toaster as Sonner, toast } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

declare global {
  interface Window {
    toast: typeof toast
  }
}
// Enable testing of toast
if (
  env.NEXT_PUBLIC_NODE_ENV === 'development' &&
  typeof window !== 'undefined'
) {
  window.toast = toast
}

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'light' } = useTheme()

  return (
    <Sonner
      position='bottom-left'
      theme={theme as ToasterProps['theme']}
      gap={12}
      className='toaster group pointer-events-auto'
      toastOptions={{
        duration: env.NEXT_PUBLIC_NODE_ENV === 'production' ? 8000 : 20000,
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg flex flex-row h-auto bottom-4 left-4',
          content: 'w-full max-w-lg',
          title: 'line-clamp-1 whitespace-normal',
          description:
            'group-[.toast]:text-muted-foreground line-clamp-3 md:line-clamp-2 text-balance',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      closeButton
      richColors
      {...props}
    />
  )
}

export { Toaster }
