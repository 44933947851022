import validator from 'validator'

export function getInitials(input?: string, ignoreList?: string[]): string {
  if (!input) {
    return ''
  }

  // Replace dashes with spaces and remove non-alphabetic characters except spaces
  input = input
    .replace(/-/g, ' ')
    .replace(/[^a-zA-Z\s]/g, '')
    .trim()
  if (!input) {
    return ''
  }

  // Function to extract initials from a single name
  const extractInitial = (name: string) => name.charAt(0).toUpperCase()

  // Check if the input is an email
  if (validator.isEmail(input)) {
    const emailParts = input.split('@')
    if (!emailParts[0]) {
      return ''
    }
    // For email, use the part before '@'
    return extractInitial(emailParts[0])
  }

  // Split the name by spaces and get the first letter of the first two words
  const words = input.trim().split(/\s+/)
  const initials = words.slice(0, 2).map(extractInitial)
  if (!initials.length || !initials[0]) {
    return ''
  }

  if (ignoreList?.includes(initials[0])) {
    return initials.join('')
  } else if (ignoreList) {
    return initials[0]
  }

  return initials.join('') // Changed to join initials instead of returning just the first one
}

export function cleanEmailString(emailString: string): {
  name?: string
  email: string
} {
  // Remove any leading or trailing quotes and whitespace
  emailString = emailString.trim().replace(/^['"]+|['"]+$/g, '')

  // Extract the name and email using a regular expression
  const emailRegex = /(?:^|<)([^<>]+@[^<>]+)(?:>|$)/
  const nameRegex = /^(.*?)</

  let email = (emailString.match(emailRegex) || [])[1]
  let name = (emailString.match(nameRegex) || [])[1]

  // If no email was found, use the entire string
  if (!email) {
    email = emailString
  }

  if (name) {
    // Replace hyphens with spaces and remove non-alphabetic characters except spaces
    name = name
      .replace(/-/g, ' ')
      .replace(/[^a-zA-Z\s]/g, '')
      .trim()
  }

  return { name, email }
}

export function getEmailString({
  name,
  email,
}: {
  name?: string
  email: string
}): string {
  // Check if the name is provided and not just empty spaces
  if (name && name.trim() !== '') {
    return `${name.trim()} <${email}>`
  } else {
    return email
  }
}

export function getCleanedEmailString(emailString: string): string {
  const { name, email } = cleanEmailString(emailString)
  return getEmailString({ name, email })
}

export function areEmailsEqual(email1: string, email2: string): boolean {
  return validator.normalizeEmail(email1) === validator.normalizeEmail(email2)
}

export function indent(string: string): string {
  const regex = /^(?!\s*$)/gm
  return string.replace(regex, '  ')
}

export function indentAfterLine(text: string, lineNumber: number): string {
  const lines = text.split('\n')
  if (!text || !lines.length) {
    return ''
  }

  const [textBeforeLine, textAfterLine] = [
    lines.slice(0, lineNumber).join('\n'),
    lines.slice(lineNumber).join('\n'),
  ]
  if (!textAfterLine) {
    return textBeforeLine
  }

  return (textBeforeLine ? textBeforeLine + '\n' : '') + indent(textAfterLine)
}
