'use client'

import { cn } from '@/web/libs/utils'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

const TooltipProvider = (
  props: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>
) => <TooltipPrimitive.Provider delayDuration={0} {...props} />

const Tooltip = (
  props: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>
) => <TooltipPrimitive.Root disableHoverableContent {...props} />

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    portalProps?: React.ComponentProps<typeof TooltipPrimitive.Portal>
  }
>(({ className, sideOffset = 4, portalProps, ...props }, ref) => (
  <TooltipPrimitive.Portal {...portalProps}>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'bg-popover text-popover-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 max-w-sm rounded-md border px-3 py-1.5 text-sm shadow-md md:max-w-md xl:max-w-xl',
        className
      )}
      {...props}
    />
  </TooltipPrimitive.Portal>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger }
