import {
  AVAILABLE_INTEGRATION_NAMES,
  type IntegrationData,
  type IntegrationSlackData,
} from '@/shared/constants/integration'
import { IntegrationName, type Integration } from '@prisma/client'
import { generateUniqueState } from './auth'

export function hasAvailableIntegrations(integrationNames?: IntegrationName[]) {
  return AVAILABLE_INTEGRATION_NAMES.some(
    availableIntegrationName =>
      !integrationNames?.includes(availableIntegrationName)
  )
}

type KnownAuthParamsType = {
  clientId: string
  redirectUri: string
  baseUrl: string
  scopes?: string[]
}
type AdditionalAuthParamsType = Record<string, string>
export function generateAuthUrl(
  params: KnownAuthParamsType,
  additionalParams: AdditionalAuthParamsType
): string {
  const { baseUrl, clientId, redirectUri, scopes } = params

  const state = generateUniqueState()

  const urlParams = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    ...(scopes && { scope: scopes.join(' ') }),
    state,
    response_type: 'code',
    ...additionalParams,
  })

  return `${baseUrl}?${urlParams.toString()}`
}

export function getIntegrationData<T extends IntegrationName>(
  integration: Integration & { name: T }
): IntegrationData<T> | null {
  if (!integration) {
    return null
  }

  switch (integration.name) {
    case IntegrationName.SLACK:
      return integration.data as IntegrationSlackData
  }

  return null
}

export function getSubtitle(integration: Integration): string | null {
  if (!integration) {
    return null
  }

  switch (integration.name) {
    case IntegrationName.SLACK:
      return (integration.data as IntegrationSlackData).team.name
  }

  return null
}

export function getMissingScopes({
  requiredScopes,
  permittedScopes,
}: {
  requiredScopes: string[]
  permittedScopes: string[]
}): string[] {
  const missingScopes = requiredScopes.filter(
    requiredScope => !permittedScopes.includes(requiredScope)
  )

  return missingScopes
}
