import { type Route } from 'next'

export const ROUTE = {
  DASHBOARD: '/dashboard',
  GDPR_COMMITMENT: '/gdpr-commitment',
  GMAIL: '/gmail',
  HOME: '/',
  INVITE: '/invite/[token]' as Route,
  REDDIT: '/reddit',
  REDDIT_DASHBOARD: '/apps/reddit',
  REDDIT_COMMENTS: '/apps/reddit/comments',
  REDDIT_KEYWORDS: '/apps/reddit/keywords',
  REDDIT_REPLIES: '/apps/reddit/replies',
  REDDIT_POSTS: '/apps/reddit/posts',
  REDDIT_SETTINGS: '/apps/reddit/settings',
  REDDIT_SUBSCRIPTION: '/apps/reddit/subscription',
  REDDIT_SUBSCRIPTION_CHANGE_PLAN: '/apps/reddit/subscription/change-plan',
  PRICING: '/pricing',
  PRIVACY_SHIELD: '/privacy-shield',
  PRIVACY: '/privacy',
  SETTINGS_ADMIN: '/settings/admin',
  SETTINGS_CHANGE_PASSWORD: '/settings/change-password',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_SUBSCRIPTION_CHANGE_PLAN: '/settings/subscription/change-plan',
  SETTINGS_SUBSCRIPTION: '/settings/subscription',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/email-sign-up',
  SIGN_IN_EMAIL: '/email-sign-in',
  SLACK: '/slack',
  SUPPORT: '/support',
  TERMS: '/terms',
  THEME: '/theme',
  YOUTUBE: '/youtube',
  // TODO
} satisfies Record<string, Route>
export const NO_NAVBAR_PATHS: Route[] = [
  ROUTE.THEME,
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP,
  ROUTE.SIGN_IN_EMAIL,
  ROUTE.INVITE,
] as const
export const PUBLIC_PATHS: Route[] = [
  ROUTE.GDPR_COMMITMENT,
  ROUTE.GMAIL,
  ROUTE.HOME,
  ROUTE.INVITE,
  ROUTE.PRICING,
  ROUTE.PRIVACY_SHIELD,
  ROUTE.PRIVACY,
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP,
  ROUTE.SIGN_IN_EMAIL,
  ROUTE.SLACK,
  ROUTE.SUPPORT,
  ROUTE.TERMS,
  ROUTE.REDDIT,
  ROUTE.YOUTUBE,
] as const
export const NO_FOOTER_PATHS: Route[] = [
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP,
  ROUTE.SIGN_IN_EMAIL,
  ROUTE.INVITE,
] as const
