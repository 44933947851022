import { type ContextMetadataType } from '@/shared/constants/context'
import { type SummaryType } from '@/shared/constants/llm'
import type { SearchMatchType } from '@/shared/constants/search'
import { z } from 'zod'

export interface GoogleCalendarListEntryType {
  /**
   * Identifier of the calendar.
   */
  id: string
  /**
   * The effective access role that the authenticated user has on the calendar.
   */
  accessRole: 'freeBusyReader' | 'reader' | 'writer' | 'owner'
  /**
   * Description of the calendar.
   */
  description?: string
  /**
   * Title of the calendar.
   */
  summary: string
  /**
   * The time zone of the calendar.
   */
  timeZone?: string
  /**
   * The main color of the calendar.
   */
  backgroundColor?: string
  /**
   * The foreground color of the calendar.
   */
  foregroundColor?: string
  /**
   * Whether the calendar is the primary calendar of the authenticated user.
   */
  isPrimary?: boolean
}

// export type GoogleCalendarEventRecurrenceType = {
//   frequency?: 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY'
//   byDay?: 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU'
//   count?: number
//   interval?: number
//   until?: Date
// }

export type GoogleCalendarEventType = {
  id: string
  title: string
  description?: string
  start?: Date | string
  end?: Date | string
  attendees?: Array<{
    email: string
    responseStatus: 'needsAction' | 'declined' | 'tentative' | 'accepted'
    name?: string
  }>
  creator?: {
    email?: string
    name?: string
  }
  recurrence?: string
  isAllDay: boolean
  isCreator: boolean
  calendar: GoogleCalendarListEntryType
  createdDate?: Date | string
  updatedDate?: Date | string
  location?: string
  iCalUID?: string
  recurringEventId?: string
  status?: 'confirmed' | 'cancelled' | 'tentative'
  visibility?: 'default' | 'public' | 'private'
  url?: string
  isBusy?: boolean

  // Custom fields
  summary?: SummaryType
  contextMetadata?: ContextMetadataType
  matchType?: SearchMatchType
}

export const GoogleCalendarSettingsInput = z.object({
  calendars: z.array(
    z
      .object({
        id: z.string(),
        name: z.string(),
        isEnabled: z.boolean(),
      })
      .required()
  ),
})
export type GoogleCalendarSettingsType = z.infer<
  typeof GoogleCalendarSettingsInput
>
export type GoogleCalendarSettingsCalendarsType =
  GoogleCalendarSettingsType['calendars']

export const GoogleCalendarCreateEventSchema = z.object({
  title: z.string(),
  details: z.string().optional(),
  location: z.string().optional(),
  start: z.date(),
  end: z.date(),
  attendees: z.string().email().array(),
})
export type GoogleCalendarCreateEventType = z.infer<
  typeof GoogleCalendarCreateEventSchema
>

export const GOOGLE_CALENDAR_SCOPES = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events',
]

export type GoogleCalendarContextDataType = {
  events: GoogleCalendarEventType[]
}
