import {
  CONTEXT_INCLUDE_ITEMS,
  type ContextWithItems,
} from '@/shared/constants/context'
import { type GmailContextDataType } from '@/shared/constants/gmail'
import { type GoogleCalendarContextDataType } from '@/shared/constants/google-calendar'
import { type NotionContextDataType } from '@/shared/constants/notion'
import { type SlackContextDataType } from '@/shared/constants/slack'
import { type NonEmptyArray } from '@/shared/types'
import {
  Score,
  TaskStatus,
  TaskType,
  type Action,
  type Context,
  type ContextItem,
  type IntegrationName,
  type Prisma,
  type Task,
  type TaskHistory,
  type User,
} from '@prisma/client'
import { z } from 'zod'

export const GENERATED_TASK_TYPES = [
  TaskType.QUESTION,
  TaskType.REMINDER,
  TaskType.TODO,
  TaskType.UPDATE,
] as const

export const MASS_EMAIL_TASK_TYPES: readonly TaskType[] = [
  TaskType.MASS_ARCHIVE_EMAILS,
  TaskType.MASS_SPAM_EMAILS,
] as const
export const CUSTOM_TASK_TYPES: readonly TaskType[] = MASS_EMAIL_TASK_TYPES
export const TASK_TYPES_WITHOUT_CONTEXT: readonly TaskType[] =
  MASS_EMAIL_TASK_TYPES
export const TASK_TYPES_WITHOUT_ACTIONS: readonly TaskType[] =
  MASS_EMAIL_TASK_TYPES
export const TASK_TYPES_WITHOUT_DESCRIPTION: readonly TaskType[] =
  MASS_EMAIL_TASK_TYPES

export const TaskWithoutTitleSchema = z.object({
  description: z.string().optional(),
  links: z.array(z.string().url()),
  assignedDate: z.date(),
  scheduledDate: z.date().optional(),
  dueDate: z.date().optional(),
  expiryDate: z.date().optional(),
  reason: z.string(),
})
export type TaskWithoutTitleSchemaType = z.infer<typeof TaskWithoutTitleSchema>

export const TaskSchema = TaskWithoutTitleSchema.extend({
  title: z.string().describe('The title of the task'),
})
export type TaskSchemaType = z.infer<typeof TaskSchema>

export const TaskUpdateSchema = TaskSchema.extend({
  reason: z.string().describe('Why were the updates made'),
})

export const TasksSchema = z.object({
  tasks: z.array(TaskSchema).describe('A list of tasks.'),
})
export type TasksSchemaType = z.infer<typeof TasksSchema>

// Prioritizing
export const TaskTimeSchema = z.object({
  hours: z.union([
    z.literal(0),
    z.literal(1),
    z.literal(2),
    z.literal(4),
    z.literal(8),
    z.literal(20),
    z.literal(40),
    z.literal(80),
    z.literal(160),
  ]),
  minutes: z.union([
    z.literal(0),
    z.literal(1),
    z.literal(5),
    z.literal(15),
    z.literal(30),
    z.literal(45),
  ]),
  reason: z.string(),
})

// @deprecated use ContextDataType
export type TaskData = Partial<SlackContextDataType> &
  Partial<GmailContextDataType> &
  Partial<GoogleCalendarContextDataType> &
  Partial<NotionContextDataType>

export const CreateTaskInput = z.object({
  title: z.string(),
})
export type CreateTaskInputType = z.infer<typeof CreateTaskInput>

export const UpdateTaskInput = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().nullable().optional(),
  dueDate: z.date().nullable().optional(),
  importance: z.nativeEnum(Score).nullable().optional(),
  reachScore: z.nativeEnum(Score).nullable().optional(),
  impactScore: z.nativeEnum(Score).nullable().optional(),
  confidenceScore: z.nativeEnum(Score).nullable().optional(),
  effortScore: z.nativeEnum(Score).nullable().optional(),
  urgency: z.nativeEnum(Score).nullable().optional(),
  priority: z.nativeEnum(Score).nullable().optional(),
  time: z.nativeEnum(Score).nullable().optional(),
  status: z.nativeEnum(TaskStatus).optional(),
  scheduledDate: z.date().nullable().optional(),
  hours: z.number().nullable().optional(),
  minutes: z.number().nullable().optional(),
  assignedUserId: z.string().nullable().optional(),
  manualFields: z.array(z.string()).optional(),
})
export type UpdateTaskInputType = z.infer<typeof UpdateTaskInput>

export type TaskWithContext = Task & {
  contexts: Context[]
}
export type TaskWithAssignedUser = Task & {
  assignedUser: User | null
}
export type TaskWithContextItems = Task & {
  contexts: ContextWithItems[]
}

export const TASK_INCLUDE_ACTIONS = {
  actions: {
    orderBy: {
      order: 'asc',
    },
  },
} as const
export const TASK_INCLUDE_CONTEXT_ITEMS = {
  contexts: {
    include: CONTEXT_INCLUDE_ITEMS,
  },
} as const

export type TaskWithAtLeast1ContextItem = TaskWithContextItems & {
  contexts: [
    ContextWithItems & { items: NonEmptyArray<ContextItem> },
    ...ContextWithItems[],
  ]
}
export type TaskWithIntegrationContextItems<
  TIntegrationName extends IntegrationName,
> = Task & {
  contexts: [
    ContextWithItems & {
      integrationName: TIntegrationName
      items: NonEmptyArray<ContextItem>
    },
    ...ContextWithItems[],
  ]
}
export type TaskWithActions = Task & {
  actions: Action[]
}
export type TaskWithContextAndActions = TaskWithActions & {
  contexts: Context[]
}
export type TaskWithContextItemsAndActions = TaskWithActions & {
  contexts: ContextWithItems[]
}
export type TaskWithRelations = Task & {
  actions?: Action[] | null
  contexts?: ContextWithItems[] | null
  history?: TaskHistory[] | null
  assignedUser?: User | null
}
export type NewTask = Omit<
  Prisma.TaskUncheckedCreateInput,
  'actions' | 'manualFields' | 'contexts'
>
export type NewOrExistingTask = Task | NewTask
export type NewOrExistingTaskWithRelations =
  | TaskWithRelations
  | Partial<Omit<Prisma.TaskUncheckedCreateInput, 'actions' | 'manualFields'>>

export type NewOrExistingPartialTaskWithRelations = Partial<
  TaskWithRelations | Omit<Prisma.TaskUncheckedCreateInput, 'actions'>
>

export const TASK_STATUS_TITLE: Record<TaskStatus, string> = {
  TODO: 'Todo',
  IN_PROGRESS: 'In Progress',
  IN_REVIEW: 'In Review',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  BLOCKED: 'Blocked',
  SCHEDULED: 'Scheduled',
}

export const TASK_TODO_STATUSES: TaskStatus[] = [
  TaskStatus.TODO,
  TaskStatus.IN_PROGRESS,
  TaskStatus.IN_REVIEW,
  TaskStatus.BLOCKED,
  TaskStatus.SCHEDULED,
] as const

export const TIME_SCORE_HOUR: Record<NonNullable<Task['time']>, number> = {
  [Score.LOW]: 0.5,
  [Score.MEDIUM]: 2,
  [Score.HIGH]: 4,
}
