import { segment } from '@/web/singletons/segment'

export function identify(
  id: Parameters<typeof segment.identify>[0],
  traits: Parameters<typeof segment.identify>[1]
) {
  console.log('Identifying %o with traits %O', id, traits)
  segment.identify(id, traits)
}

export function track(
  event: Parameters<typeof segment.track>[0],
  properties?: Parameters<typeof segment.track>[1]
) {
  console.log('Tracking %o with properties %O', event, properties)
  segment.track(event, properties)
}

export function alias(...args: Parameters<typeof segment.alias>) {
  console.log('Aliasing %o to %o', ...args)
  segment.alias(...args)
}
