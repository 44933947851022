import { api } from '@/web/utils/api'
import { useSession } from 'next-auth/react'

export const useIntegrationListQueryResult = () => {
  const session = useSession()
  const integrationsList = api.integration.list.useQuery(undefined, {
    enabled: session.status === 'authenticated',
  })

  return integrationsList
}
