export function compareDate(a: Date | null, b: Date | null): number {
  if (!a && !b) {
    return 0
  }
  if (!a) {
    return 1
  }
  if (!b) {
    return -1
  }

  return a.getTime() - b.getTime()
}
