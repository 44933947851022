import { Avatar, AvatarFallback, AvatarImage } from '@/web/components/ui/avatar'
import { Button } from '@/web/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/web/components/ui/popover'
import { usePageTitle } from '@/web/hooks/usePageTitle'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

export const MobileAuthenticatedNavbar = ({
  setIsMobileSidebarOpen,
}: {
  setIsMobileSidebarOpen: (isOpen: boolean) => void
}) => {
  const session = useSession()
  const router = useRouter()

  const pageTitle = usePageTitle()

  if (
    session.status === 'loading' ||
    session.status === 'unauthenticated' ||
    router.pathname === '/'
  ) {
    return null
  }

  return (
    <div className='sticky top-0 z-40 flex h-[var(--mobile-navbar-height)] items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden'>
      <button
        type='button'
        className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
        onClick={() => setIsMobileSidebarOpen(true)}>
        <span className='sr-only'>Open sidebar</span>
        <Bars3Icon className='h-6 w-6' aria-hidden='true' />
      </button>
      <div className='flex-1 text-sm font-semibold leading-6 text-gray-900'>
        {pageTitle}
      </div>

      <Popover>
        <PopoverTrigger asChild>
          <Button variant='ghost' className='justify-start p-2'>
            <Avatar className='h-6 w-6'>
              {session.data?.user.image ? (
                <AvatarImage
                  src={session.data?.user.image}
                  alt={session.data?.user.name ?? ''}
                />
              ) : null}
              <AvatarFallback>
                {(session.data?.user.name ?? session.data?.user.email ?? '?')
                  ?.charAt(0)
                  .toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-64'>
          <Button
            theme='destructive'
            className='w-full'
            onClick={() => signOut()}>
            Sign out
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  )
}
