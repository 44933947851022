import {
  COMPANY_DESCRIPTION,
  COMPANY_NAME,
  COMPANY_SLOGAN,
  COMPANY_TITLE,
  DOMAIN,
} from '@/shared/constants/company'
import { GlobalHooks } from '@/web/components/GlobalHooks'
import { Layout } from '@/web/components/Layout'
import { Toaster } from '@/web/components/ui/sonner'
import { TooltipProvider } from '@/web/components/ui/tooltip'
import { env } from '@/web/env'
import { helveticaNeue } from '@/web/font'
import { usePageTitle } from '@/web/hooks/usePageTitle'
import '@/web/styles/globals.css'
import { api } from '@/web/utils/api'
import { normalizerConfig } from '@/web/utils/normy'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { QueryNormalizerProvider } from '@normy/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { type Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { type AppType } from 'next/app'
import Head from 'next/head'
import { RecoilRoot } from 'recoil'

if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
  console.log('Environment', env)
}

config.autoAddCss = false

const App: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const queryClient = useQueryClient()
  const pageTitle = usePageTitle()

  return (
    <QueryNormalizerProvider
      queryClient={queryClient}
      normalizerConfig={normalizerConfig}>
      <SpeedInsights />
      <RecoilRoot>
        <SessionProvider session={session}>
          <Head>
            <title>
              {`${
                pageTitle ? `${pageTitle} | ` : ''
              }${COMPANY_NAME} - ${COMPANY_SLOGAN}`}
            </title>
            <meta
              key='description'
              name='description'
              content={COMPANY_DESCRIPTION}
            />
            <meta key='og:type' property='og:type' content='website' />
            <meta
              key='og:site_name'
              property='og:site_name'
              content={COMPANY_NAME}
            />
            <meta key='og:title' property='og:title' content={COMPANY_TITLE} />
            <meta
              key='og:description'
              property='og:description'
              content={COMPANY_DESCRIPTION}
            />
            <meta
              key='og:image'
              property='og:image'
              content='/logo/Vertical.png'
            />
            <meta key='og:url' property='og:url' content={DOMAIN} />
            <meta
              key='twitter:card'
              name='twitter:card'
              content='summary_large_image'
            />
            <meta
              key='twitter:title'
              name='twitter:title'
              content={COMPANY_TITLE}
            />
            <meta
              key='twitter:description'
              name='twitter:description'
              content={COMPANY_DESCRIPTION}
            />
            <meta
              key='twitter:image'
              name='twitter:image'
              content='/logo/Vertical.png'
            />
            <meta key='twitter:url' name='twitter:url' content={DOMAIN} />
            <meta
              key='viewport'
              name='viewport'
              content='width=device-width, initial-scale=1'
            />

            <link
              rel='apple-touch-icon'
              sizes='180x180'
              href='/favicon/apple-touch-icon.png'
            />
            <link
              rel='icon'
              type='image/png'
              sizes='32x32'
              href='/favicon/favicon-32x32.png'
            />
            <link
              rel='icon'
              type='image/png'
              sizes='16x16'
              href='/favicon/favicon-16x16.png'
            />
            <link rel='manifest' href='/favicon/site.webmanifest' />

            <style>{`
            html {
              font-family: ${helveticaNeue.style.fontFamily} !important;
            }
          `}</style>
          </Head>
          <Layout>
            <TooltipProvider>
              <Component {...pageProps} />
            </TooltipProvider>
            <Toaster />
          </Layout>
          <GlobalHooks />
          <Analytics />
          {/* <ReactQueryDevtools /> */}
        </SessionProvider>
      </RecoilRoot>
    </QueryNormalizerProvider>
  )
}
export default api.withTRPC(App)
// const TRPCApp = api.withTRPC(App)

// const NormalizedTRPCApp: AppType<{ session: Session | null }> = props => {
//   return (
//     <QueryNormalizerProvider
//       queryClient={queryClient}
//       normalizerConfig={{ devLogging: true }}>
//       <TRPCApp />
//     </QueryNormalizerProvider>
//   )
// }
// export default NormalizedTRPCApp

// @see https://github.com/trpc/trpc/discussions/1987
// export default withTRPC<AppRouter>({
//   config({ ctx }) {
//     /**
//      * If you want to use SSR, you need to use the server's full URL
//      * @link https://trpc.io/docs/ssr
//      */
//     const url = env.
//     return {
//       links
//       url,
//       queryClientConfig: {
//         defaultOptions: {
//           queries: {
//             refetchOnMount: false,
//             refetchOnWindowFocus: false,
//           },
//         },
//       },
//     }
//   },
//   /**
//    * @link https://trpc.io/docs/ssr
//    */
//   ssr: true,
// })(App)
