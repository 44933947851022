import { GmailSettingsInput } from '@/shared/constants/gmail'
import { Select } from '@/web/components/Select'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/web/components/ui/form'
import { Skeleton } from '@/web/components/ui/skeleton'
import { env } from '@/web/env'
import { api } from '@/web/utils/api'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'

export function GmailSettings() {
  const labelsQueryResult = api.gmail.labels.useQuery()
  const labels = labelsQueryResult.data
  const defaultCategoryLabelsQueryResult =
    api.gmail.defaultCategoryLabels.useQuery()
  const defaultCategoryLabels = defaultCategoryLabelsQueryResult.data

  const utils = api.useUtils()
  const updateGmailSettingsMutationResult =
    api.gmail.updateSettings.useMutation({
      onSuccess: () => {
        utils.integration.list.invalidate()
      },
    })

  const form = useForm<z.infer<typeof GmailSettingsInput>>({
    resolver: zodResolver(GmailSettingsInput),
  })

  const onSubmit = (data: z.infer<typeof GmailSettingsInput>) => {
    console.log('Saving settings', data)
    updateGmailSettingsMutationResult.mutate(data)
  }

  // Save form on change
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [form.handleSubmit, form.watch])

  // Default category labels
  useEffect(() => {
    if (!defaultCategoryLabels) {
      return
    }

    form.reset({
      draftLabel: defaultCategoryLabels.draft?.id,
      inboxLabel: defaultCategoryLabels.inbox?.id,
      sentLabel: defaultCategoryLabels.sent?.id,
    })
  }, [!!defaultCategoryLabels])

  if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log(form.formState)
    }, [form.formState])
  }

  if (labelsQueryResult.isLoading) {
    return <Skeleton className='h-48 w-full' />
  }

  if (!labels?.length) {
    return <p className='italic'>No labels found</p>
  }

  const labelOptions = labels.map(label => ({
    value: label.id,
    label: label.name,
  }))
  return (
    <Form {...form}>
      <FormMessage>{form.formState.errors.root?.message}</FormMessage>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='grid grid-cols-1 gap-4 xl:grid-cols-3'>
        <FormField
          control={form.control}
          name='inboxLabel'
          render={({ field }) => (
            <FormItem className='space-y-2'>
              <FormLabel htmlFor={field.name} className='block'>
                Inbox Label
              </FormLabel>
              <FormControl>
                {labelsQueryResult.isLoading ? (
                  <Skeleton className='h-10' />
                ) : (
                  <Select
                    size='default'
                    placeholder='Labels'
                    options={labelOptions}
                    {...field}
                  />
                )}
              </FormControl>
              <FormDescription>
                Your Gmail label for inbox emails.
              </FormDescription>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='sentLabel'
          render={({ field }) => (
            <FormItem className='space-y-2'>
              <FormLabel htmlFor={field.name} className='block'>
                Sent Label
              </FormLabel>
              <FormControl>
                {labelsQueryResult.isLoading ? (
                  <Skeleton className='h-10' />
                ) : (
                  <Select
                    size='default'
                    placeholder='Labels'
                    options={labelOptions}
                    {...field}
                  />
                )}
              </FormControl>
              <FormDescription>
                Your Gmail label for sent emails.
              </FormDescription>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='draftLabel'
          render={({ field }) => (
            <FormItem className='space-y-2'>
              <FormLabel htmlFor={field.name} className='block'>
                Draft Label
              </FormLabel>
              <FormControl>
                {labelsQueryResult.isLoading ? (
                  <Skeleton className='h-10' />
                ) : (
                  <Select
                    size='default'
                    placeholder='Labels'
                    options={labelOptions}
                    {...field}
                  />
                )}
              </FormControl>
              <FormDescription>
                Your Gmail label for draft emails.
              </FormDescription>
            </FormItem>
          )}
        />
        {/* <FormField
            control={form.control}
            name='draftLabel'
            render={({ field }) => (
              <FormItem className='space-y-4'>
                <div>
                  <FormLabel className='text-base'>Draft</FormLabel>
                  <FormDescription>
                    Select the labels you want to include in your summaries.
                  </FormDescription>
                </div>
                <div className='flex gap-4'>
                  <Button
                    variant='outline'
                    size='sm'
                    theme='success'
                    onClick={() =>
                      form.setValue(
                        'labels',
                        getSettingsLabels({ update: { isEnabled: true } })
                      )
                    }
                    disabled={
                      (labelSettings.length > 0 &&
                        labelSettings?.every(
                          labelSetting => labelSetting.isEnabled
                        )) ||
                      false
                    }
                    startIcon={<FontAwesomeIcon icon={faCheck} />}>
                    Enable All Labels
                  </Button>
                  <Button
                    variant='outline'
                    theme='destructive'
                    size='sm'
                    onClick={() =>
                      form.setValue(
                        'labels',
                        getSettingsLabels({ update: { isEnabled: false } })
                      )
                    }
                    disabled={labelSettings?.every(
                      settingsLabel => !settingsLabel.isEnabled
                    )}
                    startIcon={<FontAwesomeIcon icon={faTimes} />}>
                    Disable All Labels
                  </Button>
                </div>
                {form.formState.errors.labels ? (
                  <FormMessage>
                    {form.formState.errors.labels.message}
                  </FormMessage>
                ) : null}
                <div className='space-y-1'>
                  {labels
                    ?.slice(0)
                    .filter(label => label.visibility !== 'labelHide')
                    .sort(
                      (
                        { type: typeA, visibility: visibilityA, id: idA },
                        { type: typeB, visibility: visibilityB, id: idB }
                      ) =>
                        (DEFAULT_ENABLED_LABEL_IDS.includes(idA) &&
                        DEFAULT_ENABLED_LABEL_IDS.includes(idB)
                          ? DEFAULT_ENABLED_LABEL_IDS.indexOf(idA) -
                            DEFAULT_ENABLED_LABEL_IDS.indexOf(idB)
                          : DEFAULT_ENABLED_LABEL_IDS.includes(idA)
                          ? -1
                          : DEFAULT_ENABLED_LABEL_IDS.includes(idB)
                          ? 1
                          : false) ||
                        (typeA !== typeB && typeA === 'system' ? -1 : 1) ||
                        (visibilityA === visibilityB
                          ? 0
                          : visibilityA === 'labelShow'
                          ? -1
                          : visibilityA === 'labelHide'
                          ? 1
                          : -1)
                    )
                    .map(({ id, name, messagesTotal, type, visibility }) => {
                      return (
                        <FormItem
                          key={id}
                          className='flex flex-row items-center justify-start gap-2'>
                          <FormControl>
                            <Switch
                              id={id}
                              theme='success'
                              checked={
                                field.value.find(
                                  labelSetting => labelSetting.id === id
                                )?.isEnabled ??
                                DEFAULT_ENABLED_LABEL_IDS.includes(id)
                              }
                              onCheckedChange={checked => {
                                const updatedValue = getSettingsLabels({
                                  existingLabelSettings: field.value,
                                  update: { id, isEnabled: checked },
                                })
                                console.log('Updating labels', updatedValue)
                                field.onChange(updatedValue)
                              }}
                            />
                          </FormControl>
                          <FormLabel
                            htmlFor={id}
                            className='flex flex-row items-center space-x-2'>
                            <span>{name}</span>
                            <small className='text-muted-foreground'>{`${messagesTotal} ${pluralize(
                              'message',
                              messagesTotal
                            )}${type === 'user' ? ', custom' : ''}`}</small>
                          </FormLabel>
                        </FormItem>
                      )
                    })}
                </div>
              </FormItem>
            )}
          /> */}
      </form>
    </Form>
  )
}
