'use client'

import { cn } from '@/web/libs/utils'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const rootVariants = cva(
  'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-input',
  {
    variants: {
      theme: {
        default: 'data-[state=checked]:bg-primary',
        success: 'data-[state=checked]:bg-success',
        destructive: 'data-[state=checked]:bg-destructive',
      },
      size: {
        default: 'h-[24px] w-[44px]',
        lg: 'h-[32px] w-[64px]',
      },
    },
    defaultVariants: {
      theme: 'default',
      size: 'default',
    },
  }
)

const thumbVariants = cva(
  'pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform',
  {
    variants: {
      size: {
        default:
          'h-5 w-5 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
        lg: 'h-7 w-7 data-[state=checked]:translate-x-8 data-[state=unchecked]:translate-x-0',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
)

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
    VariantProps<typeof rootVariants>
>(({ className, size, theme, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(rootVariants({ size, theme }), className)}
    {...props}
    ref={ref}>
    <SwitchPrimitives.Thumb className={cn(thumbVariants({ size }))} />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
