import { Avatar, AvatarFallback, AvatarImage } from '@/web/components/ui/avatar'
import { type User } from '@prisma/client'
import { type Session } from 'next-auth'
import { type ComponentProps } from 'react'

export const UserAvatar = ({
  user,
  ...props
}: { user?: User | Session['user'] } & ComponentProps<typeof Avatar>) => {
  return (
    <Avatar {...props}>
      {user?.image ? (
        <AvatarImage src={user?.image} alt={user?.name ?? ''} />
      ) : null}
      <AvatarFallback>
        {(user?.name ?? user?.email ?? '?')?.charAt(0).toUpperCase()}
      </AvatarFallback>
    </Avatar>
  )
}
