import { createEnv } from '@t3-oss/env-nextjs'
import debug from 'debug'
import { z } from 'zod'

const log = debug('@/web/env')

log('Loading web env variables')

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NODE_ENV: z.enum(['development', 'test', 'production']),

    // Database
    DATABASE_URL: z.string().url(),
    DATABASE_URL_NON_POOLING: z.string().url(),

    // Next Auth
    NEXTAUTH_SECRET:
      process.env.NODE_ENV === 'production'
        ? z.string().min(1)
        : z.string().min(1).optional(),
    NEXTAUTH_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      str => process.env.VERCEL_URL ?? str ?? 'http://localhost:3000',
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.VERCEL ? z.string().min(1) : z.string().url()
    ),

    // Next Auth Providers
    GOOGLE_SIGNUP_CLIENT_ID: z.string().min(1),
    GOOGLE_SIGNUP_CLIENT_SECRET: z.string().min(1),
    MICROSOFT_AZURE_CLIENT_ID: z.string().min(1),
    MICROSOFT_AZURE_CLIENT_SECRET: z.string().min(1),
    MICROSOFT_AZURE_TENANT_ID: z.string().min(1),
    LINKEDIN_CLIENT_ID: z.string().min(1),
    LINKEDIN_CLIENT_SECRET: z.string().min(1),

    // Integrations
    GMAIL_CLIENT_ID: z.string().min(1),
    GMAIL_CLIENT_SECRET: z.string().min(1),
    GOOGLE_CALENDAR_CLIENT_ID: z.string().min(1),
    GOOGLE_CALENDAR_CLIENT_SECRET: z.string().min(1),
    GOOGLE_DRIVE_CLIENT_ID: z.string().min(1),
    GOOGLE_DRIVE_CLIENT_SECRET: z.string().min(1),
    JIRA_CLIENT_ID: z.string().min(1),
    JIRA_CLIENT_SECRET: z.string().min(1),
    NOTION_CLIENT_ID: z.string().min(1),
    NOTION_CLIENT_SECRET: z.string().min(1),
    REDDIT_CLIENT_ID: z.string().min(1),
    REDDIT_CLIENT_SECRET: z.string().min(1),
    YOUTUBE_CLIENT_ID: z.string().min(1),
    YOUTUBE_CLIENT_SECRET: z.string().min(1),

    // Slack
    SLACK_CLIENT_ID: z.string().min(1),
    SLACK_CLIENT_SECRET: z.string().min(1),
    SLACK_SIGNING_SECRET: z.string().min(1),

    // Pinecone
    PINECONE_API_KEY: z.string().min(1),
    PINECONE_INDEX: z.string().min(1),

    // OpenAI
    OPENAI_API_KEY: z.string().min(1),

    // Github
    GITHUB_TOKEN: z.string().min(1),
    GITHUB_OWNER: z.string().min(1),
    GITHUB_REPO: z.string().min(1),

    // Pusher
    PUSHER_APP_ID: z.string().min(1),
    PUSHER_KEY: z.string().min(1),
    PUSHER_SECRET: z.string().min(1),
    PUSHER_CLUSTER: z.string().min(1),

    // Segment
    SEGMENT_WRITE_KEY: z.string().min(1),

    // Vercel
    VERCEL_PROJECT_ID: z.string().min(1),
    VERCEL_TEAM_ID: z.string().min(1),
    VERCEL_API_TOKEN: z.string().min(1),

    // Jobs
    JOB_API_URL: z.string().min(1),

    // Sendgrid
    SENDGRID_API_KEY: z.string().min(1),

    // Stripe
    STRIPE_SECRET_KEY: z.string().min(1),
    STRIPE_STARTER_PRICE_ID: z.string().min(1),
    STRIPE_PREMIUM_PRICE_ID: z.string().min(1),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_NODE_ENV: z.enum(['development', 'test', 'production']),
    NEXT_PUBLIC_HOST: z.string().url().min(1),
    NEXT_PUBLIC_DEBUG: z.string().optional(),

    // Integrations v2
    NEXT_PUBLIC_GOOGLE_CALENDAR_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_GMAIL_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_NOTION_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_REDDIT_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_SLACK_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_JIRA_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_YOUTUBE_CLIENT_ID: z.string().min(1),

    // Pusher
    NEXT_PUBLIC_PUSHER_KEY: z.string().min(1),
    NEXT_PUBLIC_PUSHER_CLUSTER: z.string().min(1),

    // Segment
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string().min(1),

    // Stripe
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    // Node
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_HOST: process.env.NEXT_PUBLIC_HOST,
    NEXT_PUBLIC_DEBUG: process.env.NEXT_PUBLIC_DEBUG,
    // Database
    DATABASE_URL: process.env.DATABASE_URL,
    DATABASE_URL_NON_POOLING: process.env.DATABASE_URL_NON_POOLING,
    // Integrations (Client)
    NEXT_PUBLIC_GOOGLE_CALENDAR_CLIENT_ID:
      process.env.NEXT_PUBLIC_GOOGLE_CALENDAR_CLIENT_ID,
    NEXT_PUBLIC_GMAIL_CLIENT_ID: process.env.NEXT_PUBLIC_GMAIL_CLIENT_ID,
    NEXT_PUBLIC_NOTION_CLIENT_ID: process.env.NEXT_PUBLIC_NOTION_CLIENT_ID,
    NEXT_PUBLIC_REDDIT_CLIENT_ID: process.env.NEXT_PUBLIC_REDDIT_CLIENT_ID,
    NEXT_PUBLIC_SLACK_CLIENT_ID: process.env.NEXT_PUBLIC_SLACK_CLIENT_ID,
    NEXT_PUBLIC_JIRA_CLIENT_ID: process.env.NEXT_PUBLIC_JIRA_CLIENT_ID,
    NEXT_PUBLIC_YOUTUBE_CLIENT_ID: process.env.NEXT_PUBLIC_YOUTUBE_CLIENT_ID,
    // Auth
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    GOOGLE_SIGNUP_CLIENT_ID: process.env.GOOGLE_SIGNUP_CLIENT_ID,
    GOOGLE_SIGNUP_CLIENT_SECRET: process.env.GOOGLE_SIGNUP_CLIENT_SECRET,
    LINKEDIN_CLIENT_ID: process.env.LINKEDIN_CLIENT_ID,
    LINKEDIN_CLIENT_SECRET: process.env.LINKEDIN_CLIENT_SECRET,
    MICROSOFT_AZURE_CLIENT_ID: process.env.MICROSOFT_AZURE_CLIENT_ID,
    MICROSOFT_AZURE_CLIENT_SECRET: process.env.MICROSOFT_AZURE_CLIENT_SECRET,
    MICROSOFT_AZURE_TENANT_ID: process.env.MICROSOFT_AZURE_TENANT_ID,
    // Integrations
    GMAIL_CLIENT_ID: process.env.GMAIL_CLIENT_ID,
    GMAIL_CLIENT_SECRET: process.env.GMAIL_CLIENT_SECRET,
    GOOGLE_CALENDAR_CLIENT_ID: process.env.GOOGLE_CALENDAR_CLIENT_ID,
    GOOGLE_CALENDAR_CLIENT_SECRET: process.env.GOOGLE_CALENDAR_CLIENT_SECRET,
    GOOGLE_DRIVE_CLIENT_ID: process.env.GOOGLE_DRIVE_CLIENT_ID,
    GOOGLE_DRIVE_CLIENT_SECRET: process.env.GOOGLE_DRIVE_CLIENT_SECRET,
    JIRA_CLIENT_ID: process.env.JIRA_CLIENT_ID,
    JIRA_CLIENT_SECRET: process.env.JIRA_CLIENT_SECRET,
    NOTION_CLIENT_ID: process.env.NOTION_CLIENT_ID,
    NOTION_CLIENT_SECRET: process.env.NOTION_CLIENT_SECRET,
    REDDIT_CLIENT_ID: process.env.REDDIT_CLIENT_ID,
    REDDIT_CLIENT_SECRET: process.env.REDDIT_CLIENT_SECRET,
    YOUTUBE_CLIENT_ID: process.env.YOUTUBE_CLIENT_ID,
    YOUTUBE_CLIENT_SECRET: process.env.YOUTUBE_CLIENT_SECRET,
    // Slack
    SLACK_CLIENT_ID: process.env.SLACK_CLIENT_ID,
    SLACK_CLIENT_SECRET: process.env.SLACK_CLIENT_SECRET,
    SLACK_SIGNING_SECRET: process.env.SLACK_SIGNING_SECRET,
    // Server
    PINECONE_API_KEY: process.env.PINECONE_API_KEY,
    PINECONE_INDEX: process.env.PINECONE_INDEX,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    // Github
    GITHUB_TOKEN: process.env.GITHUB_TOKEN,
    GITHUB_OWNER: process.env.GITHUB_OWNER,
    GITHUB_REPO: process.env.GITHUB_REPO,
    // Pusher
    NEXT_PUBLIC_PUSHER_KEY: process.env.NEXT_PUBLIC_PUSHER_KEY,
    NEXT_PUBLIC_PUSHER_CLUSTER: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
    PUSHER_APP_ID: process.env.PUSHER_APP_ID,
    PUSHER_KEY: process.env.PUSHER_KEY,
    PUSHER_SECRET: process.env.PUSHER_SECRET,
    PUSHER_CLUSTER: process.env.PUSHER_CLUSTER,
    // Segment
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
    // Vercel
    VERCEL_API_TOKEN: process.env.VERCEL_API_TOKEN,
    VERCEL_TEAM_ID: process.env.VERCEL_TEAM_ID,
    VERCEL_PROJECT_ID: process.env.VERCEL_PROJECT_ID,
    // Jobs
    JOB_API_URL: process.env.JOB_API_URL,
    // Sendgrid
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
    // Stripe
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_STARTER_PRICE_ID: process.env.STRIPE_STARTER_PRICE_ID,
    STRIPE_PREMIUM_PRICE_ID: process.env.STRIPE_PREMIUM_PRICE_ID,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
})

log(
  'Loaded web environment variables: %O',
  Object.keys(env).map(k => `${k}: ${env[k as keyof typeof env]}`)
)
