'use client'

import { cn } from '@/web/libs/utils'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const BG = ['bg-turquoise', 'bg-violet', 'bg-pink']

const avatarVariants = cva(
  'relative flex shrink-0 overflow-hidden rounded-full',
  {
    variants: {
      size: {
        xs: 'h-4 w-4',
        sm: 'h-5 w-5',
        md: 'h-8 w-8',
        lg: 'h-12 w-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg'
const sizeToPixels: Record<AvatarSize, number> = {
  xs: 16, // Equivalent pixel size for 'h-4 w-4'
  sm: 20, // Equivalent pixel size for 'h-5 w-5'
  md: 24, // Equivalent pixel size for 'h-6 w-6'
  lg: 48, // Equivalent pixel size for 'h-12 w-12'
}

const SizeContext =
  React.createContext<NonNullable<VariantProps<typeof avatarVariants>['size']>>(
    'md'
  )

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> &
    VariantProps<typeof avatarVariants>
>(({ className, size, ...props }, ref) => (
  <SizeContext.Provider value={size || 'md'}>
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(avatarVariants({ size }), className)}
      {...props}
    />
  </SizeContext.Provider>
))
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => {
  const size = React.useContext(SizeContext)

  console.assert(size, 'Size context missing %o: %O', size, props)

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <AvatarPrimitive.Image
      ref={ref}
      className={cn(
        'aspect-square h-full w-full object-cover [min-width:auto]',
        className
      )}
      width={sizeToPixels[size]}
      height={sizeToPixels[size]}
      {...props}
    />
  )
})
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> & {
    themeIndex?: number
  }
>(({ className, themeIndex, ...props }, ref) => {
  const size = React.useContext(SizeContext)
  return (
    <AvatarPrimitive.Fallback
      ref={ref}
      className={cn(
        'flex h-full w-full items-center justify-center rounded-full bg-neutral-300 font-medium text-black [min-width:auto]',
        size === 'sm' && 'text-xs',
        size === 'xs' && 'text-2xs',
        themeIndex != null && `${BG[themeIndex % BG.length]} text-white`,
        className
      )}
      {...props}
    />
  )
})
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export { Avatar, AvatarFallback, AvatarImage }
