import {
  GoogleCalendarSettingsInput,
  type GoogleCalendarSettingsCalendarsType,
} from '@/shared/constants/google-calendar'
import { Button } from '@/web/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/web/components/ui/form'
import { Skeleton } from '@/web/components/ui/skeleton'
import { Switch } from '@/web/components/ui/switch'
import { env } from '@/web/env'
import { cn } from '@/web/libs/utils'
import { api } from '@/web/utils/api'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'

export function GoogleCalendarSettings() {
  const listCalendarsQuery = api.googleCalendar.listCalendars.useQuery()
  const calendars = listCalendarsQuery.data

  const integrationsQueryResult = api.integration.list.useQuery()
  const integrations = integrationsQueryResult.data
  const googleCalendarIntegration = integrations?.find(
    integration => integration.name === IntegrationName.GOOGLE_CALENDAR
  )
  const settings = googleCalendarIntegration?.settings
  const calendarSettings: GoogleCalendarSettingsCalendarsType =
    settings && typeof settings === 'object' && 'calendars' in settings
      ? (settings?.calendars as GoogleCalendarSettingsCalendarsType)
      : []

  const updateGoogleCalendarSettingsMutationResult =
    api.googleCalendar.updateSettings.useMutation({
      onSuccess: () => {
        integrationsQueryResult.refetch()
      },
    })

  const getSettingsCalendars = (
    {
      existingCalendarSettings = calendarSettings,
      update,
    }: {
      existingCalendarSettings?: z.infer<
        typeof GoogleCalendarSettingsInput
      >['calendars']
      update?: { id?: string; isEnabled: boolean }
    } = {
      existingCalendarSettings: calendarSettings,
    }
  ): GoogleCalendarSettingsCalendarsType =>
    calendars?.map(calendar => ({
      id: calendar.id,
      isEnabled:
        update &&
        (update?.id != null
          ? update.id === calendar.id
          : update?.isEnabled != null)
          ? update.isEnabled
          : existingCalendarSettings?.some(
              calendarSetting =>
                calendarSetting.id === calendar.id && calendarSetting.isEnabled
            ),
      name: calendar.summary,
    })) ?? []

  const form = useForm<z.infer<typeof GoogleCalendarSettingsInput>>({
    resolver: zodResolver(GoogleCalendarSettingsInput),
    defaultValues: {
      calendars: getSettingsCalendars(),
    },
  })

  const onSubmit = (data: z.infer<typeof GoogleCalendarSettingsInput>) => {
    console.log('Saving settings', data)
    updateGoogleCalendarSettingsMutationResult.mutate(data)
  }

  // Save form on change
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [form.handleSubmit, form.watch])

  // Update isEnabled
  useEffect(() => {
    if (
      !calendarSettings ||
      _.isEqual(form.getValues()?.calendars, calendarSettings)
    ) {
      return
    }

    console.log('Updating calendarSettings', calendarSettings)
    form.reset({
      calendars: calendarSettings,
    })
  }, [calendarSettings])

  if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log(form.formState)
    }, [form.formState])
  }

  if (listCalendarsQuery.isLoading) {
    return <Skeleton className='h-48 w-full' />
  }

  if (!calendars?.length) {
    return <p className='italic'>No calendars found</p>
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <>
          <FormMessage>{form.formState.errors.root?.message}</FormMessage>
          <FormField
            control={form.control}
            name='calendars'
            render={({ field }) => (
              <FormItem className='flex flex-col gap-2'>
                <FormLabel className='text-base'>Calendars</FormLabel>
                <FormDescription>
                  Select the calendars you want Pulse to manage.
                </FormDescription>
                <div className='grid gap-4 md:grid-cols-2'>
                  <Button
                    variant='outline'
                    theme='success'
                    onClick={() =>
                      form.setValue(
                        'calendars',
                        getSettingsCalendars({ update: { isEnabled: true } })
                      )
                    }
                    disabled={
                      !!calendarSettings?.length &&
                      calendarSettings.every(
                        settingsCalendar => settingsCalendar.isEnabled
                      )
                    }
                    startIcon={<FontAwesomeIcon icon={faCheck} />}>
                    Enable All Calendars
                  </Button>
                  <Button
                    variant='outline'
                    theme='destructive'
                    onClick={() =>
                      form.setValue(
                        'calendars',
                        getSettingsCalendars({ update: { isEnabled: false } })
                      )
                    }
                    disabled={calendarSettings?.every(
                      settingsCalendar => !settingsCalendar.isEnabled
                    )}
                    startIcon={<FontAwesomeIcon icon={faTimes} />}>
                    Disable All Calendars
                  </Button>
                </div>
                {form.formState.errors.calendars ? (
                  <FormMessage>
                    {form.formState.errors.calendars.message}
                  </FormMessage>
                ) : null}
                <div className='space-y-1'>
                  {calendars
                    ?.slice(0)
                    .sort(({ summary: a }, { summary: b }) => (a < b ? -1 : 1))
                    .map(({ id, summary }) => {
                      const backgroundColor = calendars?.find(
                        calendar => calendar.id === id
                      )?.backgroundColor

                      return (
                        <FormItem
                          key={id}
                          className='flex flex-row items-center justify-start gap-2'>
                          <FormControl>
                            <Switch
                              id={id}
                              theme='success'
                              checked={
                                field.value.find(
                                  calendarSetting => calendarSetting.id === id
                                )?.isEnabled ?? false
                              }
                              onCheckedChange={checked => {
                                const updatedValue = getSettingsCalendars({
                                  existingCalendarSettings: field.value,
                                  update: { id, isEnabled: checked },
                                })
                                console.log('Updating calendars', updatedValue)
                                field.onChange(updatedValue)
                              }}
                            />
                          </FormControl>
                          <FormLabel
                            htmlFor={id}
                            className='flex flex-row items-center space-x-2'>
                            <div
                              className={cn(`bg-primary h-4 w-4 rounded-full`)}
                              style={backgroundColor ? { backgroundColor } : {}}
                            />
                            <span>{summary}</span>
                          </FormLabel>
                        </FormItem>
                      )
                    })}
                </div>
              </FormItem>
            )}
          />
        </>
      </form>
    </Form>
  )
}
