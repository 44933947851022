import type { ContactType, SummaryType } from '@/shared/constants/llm'
import _ from 'lodash'

function getContactPromptString(contact: ContactType): string {
  const detail = contact.email ?? contact.phone ?? contact.address
  return `${contact.name}${detail ? ` (${detail})` : ''}`
}

export function getSummaryPromptString(
  summary: SummaryType,
  { isIncludingLinks = true }: { isIncludingLinks?: boolean } = {}
): string {
  const parts = []

  if (summary.summary) {
    parts.push(summary.summary)
  }
  if (summary.links?.length && isIncludingLinks) {
    parts.push(
      `Links: ${summary.links
        .map(({ url, title }) => `${url} (${title})`)
        .join(', ')}`
    )
  }

  if (summary.contacts?.length) {
    parts.push(
      `Contacts: ${summary.contacts.map(getContactPromptString).join(', ')}`
    )
  }

  if (summary.dates?.length) {
    parts.push(
      `Dates: ${summary.dates
        .map(({ title, date }) => `${title}: ${new Date(date).toISOString()}`)
        .join(', ')}`
    )
  }

  if (!_.isEmpty(summary.metadata)) {
    parts.push(
      `Metadata: ${Object.entries(summary.metadata)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')}`
    )
  }

  return parts.join('\n')
}
