import { TYPENAME_ID_FIELDS } from '@/shared/constants/trpc'
import type { Data } from '@normy/core'
import { type NormalizerConfig } from '@normy/core'
import type { DataObject, NormalizedData } from '@normy/core/types/types'
import _ from 'lodash'

export const getNormalizationObjectKey: NonNullable<
  NormalizerConfig['getNormalizationObjectKey']
> = (obj: {
  id?: string
  createdAt?: string
  updatedAt?: string
  __typename?: string
  [key: string]: unknown
}) => {
  if (obj.__typename && obj.__typename in TYPENAME_ID_FIELDS) {
    const fields =
      TYPENAME_ID_FIELDS[obj.__typename as keyof typeof TYPENAME_ID_FIELDS]
    return `${obj.__typename}-${fields
      .map(field => (field in obj ? obj[field] : 'unknown'))
      .join('-')}`
  }

  const key =
    typeof obj.id === 'string' && obj.createdAt && obj.updatedAt
      ? obj.id
      : // Google Calendar object
      typeof obj.id === 'string' &&
        'calendar' in obj &&
        typeof obj.calendar === 'object' &&
        obj.calendar &&
        'id' in obj.calendar &&
        typeof obj.calendar.id === 'string'
      ? `google-calendar-event-${obj.calendar.id}-${obj.id}`
      : undefined
  if (!key) {
    // console.log('Not normalizing object: %O', obj)
    return
  }

  // console.log('Normalizing object %s: %O', key, obj)
  return key
}

export const normalizerConfig = {
  normalize: typeof window !== 'undefined',
  devLogging: true,
  getNormalizationObjectKey,
} as NormalizerConfig

export function getDenormalizedObject(
  normalizedData: NormalizedData,
  id: string,
  seenIds = new Set()
) {
  if (seenIds.has(id)) {
    console.log('Seen id %o', id)
    return null
  }

  const cachedObjectId = `@@${id}`
  const normalizedObject = normalizedData.objects[cachedObjectId]
  seenIds.add(id)
  return denormalize(normalizedData, normalizedObject, seenIds)
}

function denormalize(
  normalizedData: NormalizedData,
  object: Data,
  seenIds = new Set()
): Data | Data[] {
  if (object == null) {
    return object
  }

  // console.debug('Denormalizing %o', object, seenIds)
  if (typeof object === 'string' && object.startsWith('@@')) {
    return (
      getDenormalizedObject(normalizedData, object.substring(2), seenIds) ??
      object
    )
  }
  if (Array.isArray(object)) {
    return object.map(element => denormalize(normalizedData, element)) as Data[]
  }
  if (typeof object === 'object') {
    if (!_.isPlainObject(object)) {
      return object
    }
    const denormalizedObject: DataObject = {}
    for (const key in object) {
      denormalizedObject[key] = denormalize(
        normalizedData,
        object[key as keyof typeof object] as unknown as Data,
        seenIds
      ) as Data
    }
    return denormalizedObject
  }
  return object
}

export function deleteObjectFromNormalizedData(
  normalizedData: NormalizedData,
  id: string
) {
  const cachedObjectId = `@@${id}`
  delete normalizedData.objects[cachedObjectId]
  console.log('Deleted object %o', id, normalizedData.objects)
}
