import { type ActionParametersType } from '@/shared/constants/action'
import {
  type AllContextDataType,
  type IntegrationContextDataType,
} from '@/shared/constants/context'
import type { GmailEmailType } from '@/shared/constants/gmail'
import type { RedditCommentType } from '@/shared/constants/reddit'
import {
  type SlackConversation,
  type SlackMessage,
} from '@/shared/constants/slack'
import {
  type TaskWithContextItemsAndActions,
  type TaskWithRelations,
} from '@/shared/constants/task'
import dayjs from '@/shared/singletons/dayjs'
import { buildContextItems } from '@/shared/utils/context'
import {
  ActionType,
  IntegrationName,
  Score,
  TaskStatus,
  TaskType,
  type Action,
  type Context,
  type ContextItem,
  type Prisma,
} from '@prisma/client'

let id = 0
const getId = () => (id++).toString()

export const BASE_ACTION: Action = {
  id: getId(),
  type: ActionType.CREATE_TASK,
  createdAt: new Date(),
  updatedAt: new Date(),
  reason: null,
  userId: '',
  integrationName: null,
  taskId: null,
  cancelledDate: null,
  completedDate: null,
  order: null,
  tokenCount: 0,
  parameters: null,
  result: null,
  wordCount: null,
  keywordMatchId: null,
}
export const BASE_TASK: TaskWithRelations = {
  id: getId(),
  title: 'Task Title',
  createdAt: new Date(),
  updatedAt: new Date(),
  type: null,
  embeddingId: null,
  description: null,
  reason: null,
  userId: 'me',
  priority: null,
  integrationName: null,
  originalWordCount: null,
  wordCount: null,
  tokenCount: 0,
  assignedDate: null,
  dueDate: null,
  completedDate: null,
  scheduledDate: null,
  links: [],
  feedback: null,
  authorId: 'me',
  lastReadDate: new Date(),
  expiryDate: null,
  status: TaskStatus.TODO,
  actionsUpdatedDate: new Date(),
  manualFields: [],
  hours: null,
  minutes: null,
  importance: null,
  reachScore: null,
  reachScoreReason: null,
  impactScore: null,
  impactScoreReason: null,
  confidenceScore: null,
  confidenceScoreReason: null,
  effortScore: null,
  effortScoreReason: null,
  timeReason: null,
  time: null,
  urgency: null,
  urgencyReason: null,
  dueDateReason: null,
  contexts: [],
  actions: [],
  assignedUserId: null,
  assignedUser: null,
}

const BASE_CONTEXT: Context = {
  id: getId(),
  createdAt: new Date(),
  updatedAt: new Date(),
  integrationName: IntegrationName.SLACK,
  taskId: '1',
  lastGeneratedDate: new Date(),
  tokenCount: 0,
}
const BASE_CONTEXT_ITEM: ContextItem = {
  id: getId(),
  createdAt: new Date(),
  updatedAt: new Date(),
  integrationName: IntegrationName.SLACK,
  contextId: '1',
  childObjectIds: [],
  objectId: '1',
  order: 0,
  isTaskOrigin: null,
  reason: null,
  searchQuery: null,
  summaryId: null,
}
export const BASE_SLACK_CONVERSATION: SlackConversation = {
  id: getId(),
  type: 'dm',
}
export const BASE_SLACK_MESSAGE: SlackMessage = {
  id: getId(),
  date: new Date().toISOString(),
  text: 'Hi there, I am a message.',
  user: {
    id: getId(),
    name: 'John Doe',
  },
}

//////////////
// SAMPLE 1 //
//////////////

const conversationId = getId()
const messageId = getId()
export const DEMO_EMAIL: GmailEmailType = {
  id: getId(),
  subject: 'Feature X Feedback',
  body: {
    plain:
      'I like feature X. It’s a great feature. I love it. When will you add feature Y?',
  },
  date: new Date().toISOString(),
  from: 'user@example.com',
  labels: ['INBOX'],
  to: ['john.doe@example.com'],
  reason: 'The email is related to the feedback for Feature X.',
  searchQuery: 'Feature X',
  summary: 'A user submitted feedback about Feature X.',
}
export const SAMPLE_CONTEXT_DATA_1: AllContextDataType = {
  emails: [DEMO_EMAIL],
  events: [
    {
      id: getId(),
      calendar: {
        id: getId(),
        accessRole: 'freeBusyReader',
        summary: 'Product Team',
      },
      description:
        'A user submitted feedback about Feature X. Let’s discuss it.',
      location: 'Zoom Meeting',
      isAllDay: false,
      isCreator: true,
      title: 'Feature X Feedback',
      attendees: [
        {
          email: 'john.doe@example.com',
          responseStatus: 'accepted',
          name: 'John Doe',
        },
        {
          email: 'lisa.smith@example.com',
          responseStatus: 'accepted',
          name: 'Lisa Smith',
        },
      ],
      start: dayjs().subtract(2, 'day').toDate(),
      end: dayjs().subtract(2, 'day').add(1, 'hour').toDate(),
    },
  ],
  pages: [
    {
      id: getId(),
      searchQuery: 'Feature X',
      reason: 'This page is related to the feedback for Feature X.',
      title: 'Feature X Feedback',
      properties: {
        'Created by': 'John Doe',
        'Last edited': new Date().toLocaleDateString(),
      },
      type: 'page',
      url: '',
      children: [
        {
          id: getId(),
          type: 'block',
          text: 'User 1: I really like feature X.',
          raw: {
            in_trash: false,
            type: 'paragraph',
            has_children: false,
            object: 'block',
            paragraph: {
              rich_text: [],
              color: 'blue',
            },
            parent: {
              type: 'page_id',
              page_id: '123',
            },
            id: '123',
            created_time: '123',
            created_by: {
              id: '123',
              object: 'user',
            },
            last_edited_time: '123',
            last_edited_by: {
              id: '123',
              object: 'user',
            },
            archived: false,
          },
        },
        {
          id: getId(),
          type: 'block',
          text: 'User 2: It’s a great feature.',
          raw: {
            in_trash: false,
            type: 'paragraph',
            object: 'block',
            paragraph: {
              rich_text: [],
              color: 'blue',
            },
            parent: {
              type: 'page_id',
              page_id: '123',
            },
            id: '123',
            created_time: '123',
            created_by: {
              id: '123',
              object: 'user',
            },
            last_edited_time: '123',
            last_edited_by: {
              id: '123',
              object: 'user',
            },
            has_children: false,
            archived: false,
          },
        },
      ],
    },
  ],
  conversations: [
    {
      id: conversationId,
      type: 'channel',
      name: 'product-team',
      memberCount: 3,
      messages: [
        {
          date: dayjs().subtract(2, 'minute').toDate(),
          id: messageId,
          user: {
            id: getId(),
            name: 'John Doe',
            image: '/stock/profile-2.jpg',
          },
          isTaskOrigin: true,
          text: 'Team, I’ve been reviewing feedback on Feature X. Looks like there are some areas we can improve. Let’s discuss!',
          replies: [
            {
              date: dayjs().subtract(1, 'minute').toDate(),
              id: getId(),
              user: {
                id: getId(),
                name: 'Lisa Smith',
                image: '/stock/profile-3.jpg',
              },
              text: 'Agreed, John. I noticed a few recurring themes in the feedback. A meeting to brainstorm enhancements sounds great.',
            },
          ],
        },
      ],
    },
  ],
}

export const SAMPLE_TASK_1: TaskWithContextItemsAndActions = {
  ...BASE_TASK,
  title: 'Evaluate User Feedback for Feature X',
  description: 'Review user feedback and identify improvements for Feature X.',
  reason:
    'You received user feedback about Feature X and mentioned wanting to discuss it with Lisa.',
  integrationName: IntegrationName.SLACK,
  dueDate: dayjs().add(1, 'week').toDate(),
  embeddingId: getId(),
  originalWordCount: 0,
  tokenCount: 0,
  type: TaskType.TODO,
  updatedAt: new Date(),
  userId: getId(),
  wordCount: 100,
  time: Score.MEDIUM,
  timeReason:
    'There is a moderate amount of feedback for Feature X, so it should only take a few hours.',
  hours: 2,
  minutes: 0,
  urgency: Score.MEDIUM,
  urgencyReason:
    'Because Feature X is already in production, identifying improvements is urgent because it could take a while to implement.',
  importance: Score.HIGH,
  reachScore: Score.HIGH,
  reachScoreReason: 'There are many users the improvements could affect.',
  impactScore: Score.HIGH,
  impactScoreReason: 'The feature is already being used by many.',
  confidenceScore: Score.LOW,
  confidenceScoreReason:
    'It’s uncertain how long the improvements will take or how impactful they’ll be.',
  effortScore: Score.MEDIUM,
  effortScoreReason: 'The review should only take about 2 hours.',
  priority: Score.HIGH,
  // history: [],
  authorId: null,
  // NOTE: For demoing, context components should use the `data` attribute instead of loading context data
  contexts: [
    {
      ...BASE_CONTEXT,
      integrationName: IntegrationName.SLACK,
      items: buildContextItems({
        data: SAMPLE_CONTEXT_DATA_1 as IntegrationContextDataType<
          typeof IntegrationName.SLACK
        >,
        integrationName: IntegrationName.SLACK,
      }).map(item => ({
        ...BASE_CONTEXT_ITEM,
        ...item,
        isTaskOrigin: item.integrationName === IntegrationName.SLACK,
      })) as ContextItem[],
    },
  ],
  actions: [
    {
      ...BASE_ACTION,
      integrationName: IntegrationName.SLACK,
      type: ActionType.REPLY_TO_MESSAGE,
      id: getId(),
      completedDate: null,
      cancelledDate: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      order: 0,
      reason: 'To discuss user feedback for Feature X.',
      result: null,
      taskId: getId(),
      tokenCount: 0,
      userId: getId(),
      wordCount: 0,
      parameters: {
        conversationId,
        conversationName: 'product-team',
        originalMessageId: messageId,
        messageOptions: [
          {
            title: 'Schedule Meeting',
            message:
              'Great points, let’s schedule a meeting to dive deeper into this feedback.',
          },
          {
            title: 'Gather More Data',
            message:
              'Before we meet, let’s ensure we have all relevant user feedback compiled.',
          },
          {
            title: 'Prioritize for Next Sprint',
            message:
              'This should be a priority for our next sprint. Let’s allocate some time for it.',
          },
        ],
      } as ActionParametersType<
        typeof ActionType.REPLY_TO_MESSAGE
      > as unknown as Prisma.JsonValue,
    },
    {
      ...BASE_ACTION,
      integrationName: IntegrationName.JIRA,
      type: ActionType.CREATE_TASK,
      id: getId(),
      completedDate: null,
      cancelledDate: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      order: 4,
      reason: 'To track the review of user feedback for Feature X.',
      result: null,
      taskId: getId(),
      tokenCount: 0,
      userId: getId(),
      wordCount: 0,
      parameters: {
        cloudId: 'your-jira-cloud-id', // Replace with your actual Jira Cloud ID
        projectId: 'your-jira-project-id', // Replace with your Jira Project ID
        issueTypeId: 'task', // Use an appropriate issue type ID (e.g., 'task')
        summary: 'Review Feature X Feedback',
        description:
          'Analyze user feedback and collaborate with the team to propose enhancements for Feature X.',
      } as ActionParametersType<
        typeof ActionType.CREATE_TASK
      > as unknown as Prisma.JsonValue,
    },
    {
      ...BASE_ACTION,
      integrationName: IntegrationName.GOOGLE_CALENDAR,
      type: ActionType.CREATE_EVENT,
      id: getId(),
      completedDate: null,
      cancelledDate: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      order: 1,
      reason: 'To schedule a meeting to review user feedback for Feature X.',
      result: null,
      taskId: getId(),
      tokenCount: 0,
      userId: getId(),
      wordCount: 0,
      parameters: {
        summary: 'Feature X Enhancement Review',
        description:
          'Discuss user feedback and potential enhancements for Feature X.',
        start: new Date('2023-12-15T10:00:00Z'),
        details:
          'Meeting to review user feedback on Feature X and identify key areas for improvement.',
        attendees: [
          'john.doe@example.com',
          'lisa.smith@example.com',
          'alex.johnson@example.com',
        ],
        location: 'Conference Room B',
        end: new Date('2023-12-15T11:00:00Z'),
        title: 'Feature X Review Meeting',
      } as ActionParametersType<
        typeof ActionType.CREATE_EVENT
      > as unknown as Prisma.JsonValue,
    },
    {
      ...BASE_ACTION,
      integrationName: IntegrationName.GMAIL,
      type: ActionType.SEND_EMAIL,
      id: getId(),
      completedDate: null,
      cancelledDate: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      order: 2,
      reason: 'To send a summary of user feedback for Feature X.',
      result: null,
      taskId: getId(),
      tokenCount: 0,
      userId: getId(),
      wordCount: 0,
      parameters: {
        from: 'john.doe@example.com',
        to: ['alex.johnson@example.com', 'lisa.smith@example.com'],
        subject: 'Summary of User Feedback for Feature X',
        message: `Hi Team,

Based on our recent user feedback collection for Feature X, here are some key points:
1. Ease of Use: Several users mentioned difficulties with the navigation flow, especially in the settings section.
2. Performance: Feedback indicates a need for faster response times during data processing.
3. Feature Requests: There's a high demand for additional customization options in the user profile section.

Let’s discuss these points in more detail in our upcoming meeting to determine the best course of action for enhancements.

Best,
John Doe`,
      } as ActionParametersType<
        typeof ActionType.SEND_EMAIL
      > as unknown as Prisma.JsonValue,
    },
    {
      ...BASE_ACTION,
      integrationName: null,
      type: ActionType.FOLLOW_LINK,
      id: getId(),
      completedDate: null,
      cancelledDate: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      order: 3,
      reason: 'To monitor the feedback for Feature X.',
      result: null,
      taskId: getId(),
      tokenCount: 0,
      userId: getId(),
      wordCount: 0,
      parameters: {
        url: 'https://analytics.example.com/feature-x',
        title: 'Go to Feature X Feedback Dashboard',
      } as ActionParametersType<
        typeof ActionType.FOLLOW_LINK
      > as unknown as Prisma.JsonValue,
    },
  ],
}

// REDDIT //

export const BASE_REDDIT_COMMENT: RedditCommentType = {
  id: 'test',
  author: '',
  body: 'test',
  created_utc: 0,
  score: 0,
  parent_id: 't1_test',
  subreddit: 'testing',
  link_id: 't3_test',
}
