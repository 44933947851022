import { PUBLIC_PATHS } from '@/web/constants/router'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

export const useHasSidebar = () => {
  const router = useRouter()
  const session = useSession()

  if (
    !router.isReady ||
    PUBLIC_PATHS.includes(
      router.pathname as unknown as (typeof PUBLIC_PATHS)[number]
    ) ||
    session.status !== 'authenticated'
  ) {
    return false
  }

  return true
}
