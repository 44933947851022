import { type SummaryType } from '@/shared/constants/llm'
import type { SearchMatchType } from '@/shared/constants/search'
import { type BlockObjectResponse } from '@notionhq/client/build/src/api-endpoints'
import { z } from 'zod'

export type NotionObject = {
  id: string
  type: string
  createdDate?: Date
  createdBy?: NotionUser
  lastEditedDate?: Date
  lastEditedBy?: NotionUser
}
export interface NotionUser extends NotionObject {
  type: 'user'
  name?: string
  avatarUrl?: string
}
export type NotionUserMap = Record<string, NotionUser>
export type NotionPage = NotionObject &
  Partial<SummaryType> & {
    type: 'page'
    title: string
    url: string
    icon?: string
    properties: Record<string, string>
    children?: NotionBlock[]

    // Context data
    searchQuery?: string // The search query used to find the page
    reason?: string // The reason why the page is provided

    // LLM
    tokenCount?: number

    // Search
    matchType?: SearchMatchType
  }
export interface NotionDatabase extends NotionObject {
  type: 'database'
  title: string
  url: string
  properties: Record<string, string> // name: type
  icon?: string
  rows?: NotionPage[]
}
export interface NotionBlock extends NotionObject {
  type: 'block'
  text: string
  id: string
  children?: NotionBlock[]
  raw: BlockObjectResponse
}
export type NotionAnyObject =
  | NotionUser
  | NotionPage
  | NotionDatabase
  | NotionBlock

export const NotionObjects = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
    isEnabled: z.boolean(),
  })
)
export type NotionObjectsType = z.infer<typeof NotionObjects>

export const NotionSettingsInput = z.object({
  objects: NotionObjects,
})
export type NotionSettingsInputType = z.infer<typeof NotionSettingsInput>

export type NotionContextDataType = {
  pages: NotionPage[]
}
