export type PusherStatusType =
  | 'idle'
  | 'loading'
  | 'running'
  | 'success'
  | 'error'

export const UPDATE_EVENT = 'update'

export type PusherDataType = {
  status?: PusherStatusType
  message?: string
  date?: Date
  duration?: number
  scopeId?: ScopeId
  progress?: number
}

export type ScopeId =
  | 'urgency'
  | 'priority'
  | 'time'
  | 'importance'
  | 'context'
  | 'update'
  | 'actions'
  | 'task'
  | 'compose-email'
  | 'compose-comment'
  | 'process-keyword'
  | 'question'
  | 'embedding'
