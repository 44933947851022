// Brand colors
export const PRIMARY = '#00b8d7'
export const SECONDARY = '#8535f5'
export const TERTIARY = '#f871ff' // hsl(297.04deg 100% 72.16%)
export const PRIMARY_LIGHT = '#89eeff'
export const SECONDARY_LIGHT = '#ceaefb'
export const TERTIARY_LIGHT = '#fcc6ff'

// @see tailwind.config.cjs

export const GREY_LIGHT = '#f7f7f7'
export const GREY_DARK = '#999999'
export const GREY_DARKER = '#6b7280' // NOTE: Same as text-muted-foreground
export const GREY_DARKEST = '#333333'
export const ACCENT = '#1dcdbc'
export const NEUTRAL = '#2b3440'
export const BASE_100 = '#fff'
export const BASE_200 = '#fafafa'
export const BASE_300 = '#F1F1F2'
export const INFO = '#3abff8'
export const SUCCESS = '#36d399'
export const SUCCESS_LIGHT = '#afedd6'
export const SUCCESS_CONTENT = '#fff'
export const WARNING = '#fbbd23'
export const WARNING_LIGHT = '#fde5a7'
export const ERROR = '#f87272'
export const ERROR_LIGHT = '#fcc7c7'

// Themes
export type ColorVariantType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'default'
  | 'destructive'
  | 'warning'
  | 'success'
export type ThemeType = 'light' | 'dark'

export const VARIANT_COLOR: Record<ColorVariantType, string> = {
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: TERTIARY,
  default: GREY_DARK,
  destructive: ERROR,
  warning: WARNING,
  success: SUCCESS,
} as const
export const BRAND_COLORS = [PRIMARY, SECONDARY, TERTIARY] as const
export const VARIANT_LIGHT_COLOR: Record<ColorVariantType, string> = {
  primary: PRIMARY_LIGHT,
  secondary: SECONDARY_LIGHT,
  tertiary: TERTIARY_LIGHT,
  default: GREY_LIGHT,
  destructive: ERROR_LIGHT,
  warning: WARNING_LIGHT,
  success: SUCCESS_LIGHT,
} as const
export const BRAND_LIGHT_COLORS = [
  PRIMARY_LIGHT,
  SECONDARY_LIGHT,
  TERTIARY_LIGHT,
] as const
export const BRAND_THEME_COLOR_MAP: Record<
  ThemeType,
  Record<ColorVariantType, string>
> = {
  light: VARIANT_LIGHT_COLOR,
  dark: VARIANT_COLOR,
}
export const BRAND_THEME_COLORS: Record<
  ThemeType,
  readonly [string, ...string[]]
> = {
  light: BRAND_LIGHT_COLORS,
  dark: BRAND_COLORS,
}
