import type { KeyboardKey } from '@/web/constants/keyboard'
import { useWhatChanged } from '@simbathesailor/use-what-changed'
import { useCallback, useEffect } from 'react'

const KEYS_ALLOWED_INSIDE_INPUTS = [
  'ArrowUp',
  'ArrowLeft',
  'ArrowDown',
  'ArrowRight',
  'Space',
  // 'metaKey',
  // 'shiftKey',
] as const
const codeKeys = ['Space'] as const satisfies KeyboardEvent['code'][]
const specialKeys = [
  'metaKey',
  'shiftKey',
  'ctrlKey',
] as const satisfies (keyof KeyboardEvent)[]

const doesEventHaveKey = (event: KeyboardEvent, key: KeyboardKey) => {
  if (specialKeys.includes(key as (typeof specialKeys)[number]))
    return event[key as keyof KeyboardEvent]
  if (codeKeys.includes(key as (typeof codeKeys)[number]))
    return event.code?.toLowerCase() === key.toLowerCase()
  return (
    typeof event.key === 'string' &&
    event.key?.toLowerCase() === key.toLowerCase()
  )
}

const isAllowedInsideInput = (event: KeyboardEvent) => {
  return !KEYS_ALLOWED_INSIDE_INPUTS.some(key => {
    const hasKeyForInput = doesEventHaveKey(event, key)
    if (hasKeyForInput) {
      console.debug('Cancelling shortcut because of allowed key inside input', {
        event,
        key,
      })
    }

    return hasKeyForInput
  })
}

const isFocusingInput = () => {
  const element = document.activeElement
  const tagName = element?.tagName.toLowerCase()

  return tagName === 'input' || tagName === 'textarea'
}

const hasKeys = (event: KeyboardEvent, keys: KeyboardKey[]) => {
  if (!event) {
    console.warn('No event passed to hasKeys', { event, keys })
    return false
  }

  // Ensure it's allowed if focusing an input
  if (isFocusingInput() && !isAllowedInsideInput(event)) {
    console.debug(
      'Cancelling shortcut %o because focusing input',
      keys,
      event,
      document.activeElement
    )
    return false
  }

  // Ensure none of the wrong keys are being pressed
  const excludedSpecialKeys = specialKeys.filter(
    specialKey => !keys.includes(specialKey)
  )
  if (excludedSpecialKeys.some(key => event[key])) {
    console.debug('Cancelling shortcut %o because of special keys', {
      keys,
      event,
      excludedSpecialKeys,
    })
    return false
  }

  return keys.every(key => doesEventHaveKey(event, key))
}

export const useKeyboardShortcut = (
  keys: KeyboardKey[],
  callback?: () => void,
  isEnabled = true
) => {
  const hasShortcutKeys = useCallback(
    (event: KeyboardEvent) => {
      const result = hasKeys(event, keys)
      console.debug('hasShortcutKeys for keys %o = %o', keys, result, {
        key: event.key,
        ctrl: event.ctrlKey,
        meta: event.metaKey,
        shift: event.shiftKey,
        alt: event.altKey,
      })
      return result
    },
    [keys]
  )

  useEffect(() => {
    if (!callback || !isEnabled) return

    const handleKeyDown = (event: KeyboardEvent) => {
      if (hasShortcutKeys(event)) {
        console.log('Handling shortcut for keys %o', keys, event)
        event.preventDefault() // prevent the default action for this key combination
        event.stopPropagation() // stop the event from bubbling up
        callback()
      } else {
        console.debug('Not handling shortcut for keys %o', keys, event)
      }
    }

    // Attach the event listener
    console.log('Registering keyboard shortcut for keys %o', keys)
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup the event listener on component unmount
    return () => {
      console.log('Unregistering keyboard shortcut for keys %o', keys)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback, hasShortcutKeys, isEnabled])

  useWhatChanged(
    [callback, isEnabled, hasShortcutKeys, keys],
    'callback, isEnabled, hasShortcutKeys, keys',
    `useKeyboardShortcut ${keys.join('+')}`
  )

  return null
}
