import { env } from '@/web/env'
import Pusher from 'pusher-js'

const globalPusherClient = globalThis as unknown as {
  pusher: Pusher | undefined
}

function createPusherClient() {
  console.log('Creating Pusher client')
  return new Pusher(env.NEXT_PUBLIC_PUSHER_KEY, {
    cluster: env.NEXT_PUBLIC_PUSHER_CLUSTER,
  })
}

export const pusherClient = globalPusherClient.pusher ?? createPusherClient()

if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
  globalPusherClient.pusher = pusherClient
}
