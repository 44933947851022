import { type ContextMetadataType } from '@/shared/constants/context'
import { type SummaryType } from '@/shared/constants/llm'
import type { SearchMatchType } from '@/shared/constants/search'
import { type NonEmptyArray } from '@/shared/types'
import { type Message } from '@slack/web-api/dist/response/ConversationsHistoryResponse'
import { type Channel } from '@slack/web-api/dist/response/ConversationsListResponse'
import { type Member } from '@slack/web-api/dist/response/UsersListResponse'
import { z } from 'zod'

export type SlackReaction = {
  name: string
  count: number
  users: SlackMember[]
}

export type SlackMember = {
  name?: string
  email?: string
  username?: string
  id: string
  image?: string
  isBot?: boolean
}
export type SlackMemberMap = Record<string, SlackMember>

export type SlackMessage = Partial<ContextMetadataType> & {
  id: string
  text: string
  user: SlackMember
  date: Date | string
  reactions?: SlackReaction[]
  replies?: SlackMessage[]
  updatedAt?: Date | string
  isTaskOrigin?: boolean
}
export type SlackMessageMetadata = Omit<SlackMessage, 'text'>

export const SLACK_CONVERSATION_TYPES = ['dm', 'group', 'channel'] as const
export type SlackConversationType = (typeof SLACK_CONVERSATION_TYPES)[number]
export const SLACK_CONVERSATION_TYPE_NAME: Record<
  SlackConversationType,
  string
> = {
  dm: 'Direct Message',
  group: 'Group',
  channel: 'Channel',
} as const

export type SlackConversation = Partial<ContextMetadataType> &
  Partial<SummaryType> & {
    name?: string | null
    id: string
    messages?: SlackMessage[] | null
    purpose?: string
    creator?: SlackMember
    memberCount?: number
    memberIds?: string[]
    type: SlackConversationType
    lastUpdatedDate?: Date | string
    tokenCount?: number

    // Search
    matchType?: SearchMatchType
  }
export type NonEmptySlackConversation = Omit<SlackConversation, 'messages'> & {
  messages: NonEmptyArray<SlackMessage>
}
export type SlackChannel = SlackConversation & {
  type: 'channel'
}
export type SlackDM = SlackConversation & {
  type: 'dm'
}
export type SlackGroup = SlackConversation & {
  type: 'group'
}

// @deprecated
export type RawSlackData = {
  members: Member[]
  channels: (Channel & {
    messages?: Message[]
  })[]
}
// @deprecated
export type SlackData = {
  members: SlackMember[]
  conversations: SlackConversation[]
}

export type SlackContextDataType = {
  conversations: SlackConversation[]
}
export type NonEmptySlackContextDataType = {
  conversations: NonEmptyArray<NonEmptySlackConversation>
}

export const SlackConversationsSettingsInputSchema = z.record(z.boolean())
export type SlackIntegrationConversationsSettingsType = z.infer<
  typeof SlackConversationsSettingsInputSchema
>

export const SlackSettingsInputSchema = z.object({
  conversations: SlackConversationsSettingsInputSchema,
})
export type SlackIntegrationSettingsType = z.infer<
  typeof SlackSettingsInputSchema
>

export const SlackTaskGeneralDataSchema = z.object({
  title: z.string().describe('The title of the task in Title Case'),
  messageIds: z
    .array(z.string())
    .describe('The relevant message IDs to the task'),
})
export type SlackTaskGeneralDataType = z.infer<
  typeof SlackTaskGeneralDataSchema
>
export const SlackTasksGeneralDataObjectSchema = z.object({
  tasks: z.array(SlackTaskGeneralDataSchema),
})
export type SlackTasksGeneralDataType = z.infer<
  typeof SlackTasksGeneralDataObjectSchema
>['tasks']
export type SlackTaskGeneralDataWithEmbedding = SlackTaskGeneralDataType & {
  embeddingId: string
}

export type SlackTasksGeneralDataWithEmbedding =
  SlackTaskGeneralDataWithEmbedding[]

export const SLACK_BOT_SCOPES = [
  'app_mentions:read',
  'calls:read',
  'channels:history',
  'channels:join',
  'channels:manage',
  'channels:read',
  'chat:write',
  'conversations.connect:read',
  'emoji:read',
  'files:read',
  'groups:history',
  'groups:read',
  'groups:write',
  'im:history',
  'im:read',
  'metadata.message:read',
  'mpim:history',
  'mpim:read',
  'team:read',
  'usergroups:read',
  'users:read.email',
  'users:read',
  'users.profile:read',
]

export const SLACK_USER_SCOPES = [
  'calls:read',
  'channels:history', // NOT APPROVED
  'channels:read',
  'channels:write',
  'chat:write',
  'files:read',
  'groups:history', // NOT APPROVED
  'groups:read',
  'groups:write',
  'im:history', // NOT APPROVED
  'im:read',
  'im:write',
  'links:read',
  'mpim:history', // NOT APPROVED
  'mpim:read',
  'mpim:write',
  'pins:read',
  'search:read', // NOT APPROVED
  'users:read.email',
  'users:read',
]
