import { useAllIntegrationPermissionErrors } from '@/web/hooks/use-all-integration-permission-errors'
import { useNeedsIntegration } from '@/web/hooks/use-needs-integration'

export const useHasIntegrationWarning = () => {
  const needsIntegration = useNeedsIntegration()
  const allIntegrationPermissionErrors = useAllIntegrationPermissionErrors()

  return (
    ((needsIntegration && 'Connect and enable an integration.') ||
      Object.values(allIntegrationPermissionErrors).find(
        error => !!error.errors?.length
      )?.errors?.[0]) ??
    false
  )
}
