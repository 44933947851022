import { useIntegrationListQueryResult } from '@/web/hooks/useIntegrationListQueryResult'
import { type Integration, type IntegrationName } from '@prisma/client'

export const useIntegration = (name: IntegrationName): Integration | null => {
  const integrationsQueryResult = useIntegrationListQueryResult()
  const integrations = integrationsQueryResult.data
  const integration = integrations?.find(
    integration => integration.name === name
  )

  return integration ?? null
}
