import { type IconType } from '@/web/constants/icon'
import { useId } from 'react'

export const RedditIcon: IconType = ({ isColored, isLight, ...props }) => {
  const id = useId()

  const [
    snooId,
    circle1Id,
    circle2Id,
    antennaId,
    faceId,
    path1Id,
    path2Id,
    path3Id,
    path4Id,
  ] = [
    `${id}-snoo`,
    `${id}-circle1`,
    `${id}-circle2`,
    `${id}-antenna`,
    `${id}-face`,
    `${id}-path1`,
    `${id}-path2`,
    `${id}-path3`,
    `${id}-path4`,
  ]

  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='154 30 546 528'
      xmlSpace='preserve'
      {...props}>
      <symbol id={snooId} viewBox='-216.1 -188.86 432.21 377.72'>
        <g>
          <radialGradient
            id={circle1Id}
            cx='154.385'
            cy='1202.9807'
            r='127.45'
            fx='154.385'
            fy='1212.1007'
            gradientTransform='matrix(1 0 0 0.87 0 -1084.4399)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#FEFFFF' />
            <stop offset='0.4' stopColor='#FEFFFF' />
            <stop offset='0.51' stopColor='#F9FCFC' />
            <stop offset='0.62' stopColor='#EDF3F5' />
            <stop offset='0.7' stopColor='#DEE9EC' />
            <stop offset='0.72' stopColor='#D8E4E8' />
            <stop offset='0.76' stopColor='#CCD8DF' />
            <stop offset='0.8' stopColor='#C8D5DD' />
            <stop offset='0.83' stopColor='#CCD6DE' />
            <stop offset='0.85' stopColor='#D8DBE2' />
            <stop offset='0.88' stopColor='#EDE3E9' />
            <stop offset='0.9' stopColor='#FFEBEF' />
          </radialGradient>
          <circle
            fill={`url(#${circle1Id})`}
            cx='153.06'
            cy='-0.31'
            r='63.05'
          />

          <radialGradient
            id={circle2Id}
            cx='-151.725'
            cy='-37.27'
            r='127.45'
            fx='-151.725'
            fy='-28.15'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#FEFFFF' />
            <stop offset='0.4' stopColor='#FEFFFF' />
            <stop offset='0.51' stopColor='#F9FCFC' />
            <stop offset='0.62' stopColor='#EDF3F5' />
            <stop offset='0.7' stopColor='#DEE9EC' />
            <stop offset='0.72' stopColor='#D8E4E8' />
            <stop offset='0.76' stopColor='#CCD8DF' />
            <stop offset='0.8' stopColor='#C8D5DD' />
            <stop offset='0.83' stopColor='#CCD6DE' />
            <stop offset='0.85' stopColor='#D8DBE2' />
            <stop offset='0.88' stopColor='#EDE3E9' />
            <stop offset='0.9' stopColor='#FFEBEF' />
          </radialGradient>
          <circle
            fill={`url(#${circle2Id})`}
            cx='-153.05'
            cy='-0.31'
            r='63.05'
          />

          <radialGradient
            id={faceId}
            cx='4.035'
            cy='3503.4231'
            r='384.44'
            gradientTransform='matrix(1 0 0 0.7 0 -2506.3501)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#FEFFFF' />
            <stop offset='0.4' stopColor='#FEFFFF' />
            <stop offset='0.51' stopColor='#F9FCFC' />
            <stop offset='0.62' stopColor='#EDF3F5' />
            <stop offset='0.7' stopColor='#DEE9EC' />
            <stop offset='0.72' stopColor='#D8E4E8' />
            <stop offset='0.76' stopColor='#CCD8DF' />
            <stop offset='0.8' stopColor='#C8D5DD' />
            <stop offset='0.83' stopColor='#CCD6DE' />
            <stop offset='0.85' stopColor='#D8DBE2' />
            <stop offset='0.88' stopColor='#EDE3E9' />
            <stop offset='0.9' stopColor='#FFEBEF' />
          </radialGradient>
          <ellipse
            fill={`url(#${faceId})`}
            cx='0.16'
            cy='53.86'
            rx='180'
            ry='135'
          />
          <g>
            <path
              fill='#842123'
              d='M-53.07,40.73C-54.12,63.6-69.29,71.9-86.98,71.9s-31.15-11.71-30.09-34.58
				c1.05-22.87,16.23-38.01,33.91-38.01S-52.01,17.85-53.07,40.73L-53.07,40.73z'
            />
            <path
              fill='#842123'
              d='M117.38,37.32c1.05,22.87-12.42,34.58-30.09,34.58s-32.85-8.3-33.91-31.17C52.33,17.86,65.8-0.69,83.47-0.69
				S116.32,14.44,117.38,37.32L117.38,37.32z'
            />
          </g>

          <radialGradient
            id={path1Id}
            cx='-81.405'
            cy='-2563.4927'
            r='32.12'
            gradientTransform='matrix(1 0 0 1.46 0 3794.9004)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#FF6600' />
            <stop offset='0.5' stopColor='#FF4500' />
            <stop offset='0.7' stopColor='#FC4301' />
            <stop offset='0.82' stopColor='#F43F07' />
            <stop offset='0.92' stopColor='#E53812' />
            <stop offset='1' stopColor='#D4301F' />
          </radialGradient>
          <path
            fill={`url(#${path1Id})`}
            d='M-53.05,42.73
			C-54.04,64.14-68.24,71.9-84.78,71.9s-29.15-11.63-28.16-33.03c0.99-21.41,15.19-35.41,31.73-35.41S-52.07,21.32-53.05,42.73
			L-53.05,42.73z'
          />

          <radialGradient
            id={path2Id}
            cx='21949.6055'
            cy='-2563.4927'
            r='32.12'
            gradientTransform='matrix(-1 0 0 1.46 22031.3203 3794.9004)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#FF6600' />
            <stop offset='0.5' stopColor='#FF4500' />
            <stop offset='0.7' stopColor='#FC4301' />
            <stop offset='0.82' stopColor='#F43F07' />
            <stop offset='0.92' stopColor='#E53812' />
            <stop offset='1' stopColor='#D4301F' />
          </radialGradient>
          <path
            fill={`url(#${path2Id})`}
            d='M53.37,42.73
			C54.35,64.14,68.56,71.9,85.1,71.9s29.15-11.63,28.16-33.03C112.27,17.46,98.07,3.46,81.53,3.46S52.38,21.32,53.37,42.73
			L53.37,42.73z'
          />
          <ellipse fill='#FFC49C' cx='-70.91' cy='23.18' rx='7' ry='7.64' />
          <ellipse fill='#FFC49C' cx='95.54' cy='23.18' rx='7' ry='7.64' />
          <path
            fill='#BBCFDA'
            d='M0.15,87.16c-22.32,0-43.71,1.08-63.49,3.04c-3.38,0.34-5.52,3.78-4.21,6.86
			c11.08,25.97,37.22,44.21,67.7,44.21s56.62-18.24,67.7-44.21c1.31-3.08-0.83-6.52-4.21-6.86C43.86,88.23,22.47,87.16,0.15,87.16
			L0.15,87.16z'
          />
          <path
            fill='#FFFFFF'
            d='M0.15,92.12c-22.25,0-43.57,1.09-63.29,3.09c-3.37,0.34-5.51,3.84-4.2,6.97
			c11.05,26.38,37.1,44.91,67.49,44.91s56.44-18.53,67.49-44.91c1.31-3.12-0.83-6.62-4.2-6.97C43.72,93.21,22.4,92.12,0.15,92.12
			L0.15,92.12z'
          />

          <radialGradient
            id={path3Id}
            cx='-0.175'
            cy='4425.4893'
            r='113.26'
            gradientTransform='matrix(1 0 0 0.66 0 -2769.8699)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#172E35' />
            <stop offset='0.29' stopColor='#0E1C21' />
            <stop offset='0.73' stopColor='#030708' />
            <stop offset='1' stopColor='#000000' />
          </radialGradient>
          <path
            fill={`url(#${path3Id})`}
            d='M0.15,89.54
			c-21.9,0-42.89,1.08-62.3,3.04c-3.32,0.34-5.42,3.78-4.13,6.86c10.87,25.97,36.52,44.21,66.44,44.21s55.56-18.24,66.44-44.21
			c1.29-3.08-0.81-6.52-4.13-6.86C43.06,90.61,22.07,89.54,0.15,89.54L0.15,89.54z'
          />

          <radialGradient
            id={antennaId}
            cx='99.705'
            cy='-15.9996'
            r='99.42'
            gradientTransform='matrix(1 0 0 0.98 0 -169.7198)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#FEFFFF' />
            <stop offset='0.4' stopColor='#FEFFFF' />
            <stop offset='0.51' stopColor='#F9FCFC' />
            <stop offset='0.62' stopColor='#EDF3F5' />
            <stop offset='0.7' stopColor='#DEE9EC' />
            <stop offset='0.72' stopColor='#D8E4E8' />
            <stop offset='0.76' stopColor='#CCD8DF' />
            <stop offset='0.8' stopColor='#C8D5DD' />
            <stop offset='0.83' stopColor='#CCD6DE' />
            <stop offset='0.85' stopColor='#D8DBE2' />
            <stop offset='0.88' stopColor='#EDE3E9' />
            <stop offset='0.9' stopColor='#FFEBEF' />
          </radialGradient>
          <circle
            fill={`url(#${antennaId})`}
            cx='98.74'
            cy='-144.18'
            r='44.68'
          />

          <radialGradient
            id={path4Id}
            cx='58.275'
            cy='-85.04'
            r='81.49'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0.48' stopColor='#7A9299' />
            <stop offset='0.67' stopColor='#172E35' />
            <stop offset='0.75' stopColor='#000000' />
            <stop offset='0.82' stopColor='#172E35' />
          </radialGradient>
          <path
            fill={`url(#${path4Id})`}
            d='M-0.49-75.45
			c-5.35,0-9.69-2.24-9.69-5.69c0-40.03,32.56-72.59,72.59-72.59c5.35,0,9.69,4.34,9.69,9.69c0,5.35-4.34,9.69-9.69,9.69
			c-29.34,0-53.22,23.87-53.22,53.22c0,3.45-4.34,5.69-9.69,5.69L-0.49-75.45z'
          />
          <path
            fill='#FF6101'
            d='M-64.82,53.32c0,8.28-8.81,12-19.69,12s-19.69-3.72-19.69-12s8.81-15,19.69-15S-64.82,45.04-64.82,53.32z'
          />
          <path
            fill='#FF6101'
            d='M104.5,53.32c0,8.28-8.81,12-19.69,12s-19.69-3.72-19.69-12s8.81-15,19.69-15S104.5,45.04,104.5,53.32z'
          />
        </g>
      </symbol>
      <g>
        <path
          fill='#FF4500'
          d='M428,41.28L428,41.28c-139.74,0-253,113.26-253,253l0,0c0,69.86,28.32,133.11,74.1,178.9l-48.19,48.19
		c-9.56,9.56-2.79,25.91,10.73,25.91H428l0,0c139.74,0,253-113.26,253-253l0,0C681,154.54,567.74,41.28,428,41.28z'
        />
        <use
          xlinkHref={`#${snooId}`}
          width='432.21'
          height='377.72'
          x='-216.1'
          y='-188.86'
          transform='matrix(0.937 0 0 0.937 428.0047 292.1532)'
          style={{ overflow: 'visible' }} // style='overflow:visible;'
        />
      </g>
    </svg>
  )
}
