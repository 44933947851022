import { ActionType } from '@prisma/client'
import { z } from 'zod'

export const JiraSettingsInput = z.object({
  projects: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      isEnabled: z.boolean(),
    })
  ),
})
export type JiraSettingsType = z.infer<typeof JiraSettingsInput>

export type JiraResourceType = {
  id: string
  name: string
  url: string
  avatarUrl: string
  scopes: string[]
}

export type JiraProjectCategoryType = {
  id: string
  name: string
  description: string
}

export type JiraProjectType = {
  id: string
  name: string
  avatarUrl: string
  issueCount: number | null
  lastIssueUpdatedDate: Date | null
  category: JiraProjectCategoryType | null
}

export type JiraRawProjectType = {
  id: string
  name: string
  url: string
  avatarUrls: { '48x48': string }
  insight?: {
    totalIssueCount: number
    lastIssueUpdateTime: string
  }
  projectCategory?: {
    id: string
    name: string
    description: string
  }
}

export type JiraRawIssueType = {
  id: string
  key: string
  fields: {
    summary: string
    description: string
    status: {
      name: string
    }
    // Other fields as needed
  }
}

export type JiraIssueType = {
  id: string
  key: string
  summary: string
  status: string
}

export type JiraRawIssueTypeType = {
  id: string
  name: string
  description: string
  // Add other fields as needed
}

export type JiraIssueTypeType = {
  id: string
  name: string
  description: string
}

export const JIRA_ACTION_TYPES = [
  ActionType.CREATE_TASK,
  // ActionType.DELETE_TASK,
  // ActionType.UPDATE_TASK,
  // ActionType.COMPLETE_TASK
]

export const JIRA_CREATE_TASK_PARAMETERS_SCHEMA = z.object({
  cloudId: z.string(),
  projectId: z.string(),
  issueTypeId: z.string(),
  summary: z.string(),
  description: z.string().optional(),
  additionalProperties: z.record(z.string()).optional(),
})
export type JiraCreateTaskParametersType = z.infer<
  typeof JIRA_CREATE_TASK_PARAMETERS_SCHEMA
>
export const JIRA_ACTION_TYPE_PARAMETERS_SCHEMA = {
  [ActionType.CREATE_TASK]: JIRA_CREATE_TASK_PARAMETERS_SCHEMA,
  [ActionType.UPDATE_TASK]: z.object({
    cloudId: z.string(),
    projectId: z.string(),
    issueId: z.string(),
    originalSummary: z.string(),
    summary: z.string().optional(),
    description: z.string().optional(),
    additionalProperties: z.record(z.string()).optional(),
  }),
} as const

export const JIRA_SCOPES = [
  'read:jira-work',
  'read:jira-user',
  'write:jira-work',
  'offline_access',
  'manage:jira-configuration',
  'manage:jira-project',
  'manage:jira-webhook',
]
