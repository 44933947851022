'use client'

import { getMissingScopes } from '@/shared/utils/integrations'
import { INTEGRATION_DATA } from '@/web/constants/integration'
import { useIntegration } from '@/web/hooks/use-integration'
import { type api } from '@/web/utils/api'
import { type IntegrationName } from '@prisma/client'
import { useRouter } from 'next/router'
import pluralize from 'pluralize'
import { useEffect } from 'react'

function getErrorMessage({
  permittedScopes,
  requiredScopes,
  scopeName,
}: {
  permittedScopes: string[]
  requiredScopes: string[]
  scopeName?: string
}) {
  const missingScopes = getMissingScopes({
    permittedScopes,
    requiredScopes,
  })
  if (!missingScopes.length) {
    return null
  }
  return `Missing ${pluralize('scope', missingScopes.length)}${
    scopeName ? ` for ${scopeName}` : ''
  }: ${missingScopes.join(', ')}`
}

export const useIntegrationPermissionErrors = (
  integrationName: IntegrationName
): {
  errors: string[] | null
  lastChecked: Date
  isLoading: boolean
} => {
  const integrationData = INTEGRATION_DATA[integrationName]

  const router = useRouter()

  const shouldPerformQuery = !!integrationData.permissionsQuery
  const permissionsQueryResult = shouldPerformQuery
    ? (
        integrationData.permissionsQuery as typeof api.gmail.permissions
      ).useQuery()
    : null

  // Refetch permissions when integration changes
  const integration = useIntegration(integrationName)
  useEffect(() => {
    if (permissionsQueryResult?.isLoading) {
      return
    }

    console.info('Refetching permissions for %o', integrationName)
    permissionsQueryResult?.refetch()
  }, [integration])

  if (router.query.code || !router.isReady || typeof window === 'undefined') {
    console.log('Not ready to check integration permissions', {
      integrationName,
      code: router.query.code,
      isReady: router.isReady,
      window: typeof window,
    })
    return {
      errors: null,
      isLoading: true,
      lastChecked: new Date(),
    }
  }

  if (!integrationData?.permissionsQuery) {
    console.log('No permissions query', integrationName)
    return {
      errors: null,
      isLoading: false,
      lastChecked: new Date(),
    }
  }

  if (!permissionsQueryResult) {
    console.log('No permissions query result', integrationName)
    return {
      errors: null,
      isLoading: false,
      lastChecked: new Date(),
    }
  }

  const errors = permissionsQueryResult.data?.errors ?? ([] as string[])
  if (permissionsQueryResult.error) {
    console.log('Error getting permissions', {
      error: permissionsQueryResult.error,
    })
    errors.push(
      `There was an error checking your permissions: ${permissionsQueryResult.error.message}.`
    )
  }

  const permittedScopes = permissionsQueryResult.data?.scopes
  const requiredScopes = integrationData.scopes
  // Ensure all scopes are available
  if (Array.isArray(permittedScopes)) {
    const error = getErrorMessage({
      permittedScopes,
      requiredScopes,
    })
    if (error) {
      errors.push(error)
    }
  } else if (!!permittedScopes && typeof permittedScopes === 'object') {
    Object.keys(permittedScopes).forEach(scopeKey => {
      const error = getErrorMessage({
        permittedScopes:
          permittedScopes[scopeKey as keyof typeof permittedScopes]!,
        requiredScopes,
        scopeName: scopeKey,
      })
      if (error) {
        errors.push(error)
      }
    })
  }

  console.debug('%d permissions errors for %o', errors.length, {
    integrationName,
    errors,
    permittedScopes,
    requiredScopes,
  })

  return {
    errors,
    lastChecked: new Date(permissionsQueryResult.dataUpdatedAt),
    isLoading: permissionsQueryResult.isLoading,
  }
}
