import { LogoVertical } from '@/web/components/LogoVertical'
import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import { useSession } from 'next-auth/react'
import Link from 'next/link'

/** Screenshot CSS
 *  --------------
    line-height: 500px;
    font-size: 500px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    position: fixed;
 */

const variants = cva(
  'text-gradient flex flex-col items-center overflow-visible text-center font-semibold tracking-tight transition-all hover:animate-pulse',
  {
    variants: {
      size: {
        sm: 'h-10 2xl:h-12',
        default: 'lg:h-18 h-14',
        lg: 'h-32',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
)
export const Logo = ({ size }: VariantProps<typeof variants>) => {
  const session = useSession()

  return (
    <Link
      href={session.status === 'authenticated' ? '/dashboard' : '/'}
      className={cn(variants({ size }))}>
      <LogoVertical className='h-full' />
    </Link>
  )
}
