import { Role } from '@prisma/client'
import { z } from 'zod'

export const PasswordSchema = z.string().min(8).max(32)
export const RegisterInput = z.object({
  email: z.string().email(),
  password: PasswordSchema,
})

export const LoginSchema = z.object({
  email: z.string().email(),
  password: PasswordSchema,
})

export const AcceptInvitationSchema = z.object({
  token: z.string(),
  password: PasswordSchema,
})

export const SpoofSchema = LoginSchema.extend({
  userId: z.string(),
})

export const TEAM_MANAGEMENT_ROLES: Role[] = [
  Role.ADMIN,
  Role.PARTNER,
  Role.TEAM_ADMIN,
]

export const USER_INCLUDE_ENABLED_INTEGRATIONS = {
  integrations: {
    where: {
      isEnabled: true,
    },
  },
} as const
