import {
  NotionSettingsInput,
  type NotionDatabase,
  type NotionObjectsType,
  type NotionPage,
} from '@/shared/constants/notion'
import { Button } from '@/web/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/web/components/ui/form'
import { ScrollArea } from '@/web/components/ui/scroll-area'
import { Skeleton } from '@/web/components/ui/skeleton'
import { Switch } from '@/web/components/ui/switch'
import { env } from '@/web/env'
import { api } from '@/web/utils/api'
import { faFileLines } from '@fortawesome/free-regular-svg-icons'
import {
  faDatabase,
  faSquareArrowUpRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, type ComponentProps } from 'react'
import { useForm, type ControllerRenderProps } from 'react-hook-form'
import validator from 'validator'
import { type z } from 'zod'

function NotionObjectToggle({
  onCheckedChange,
  objectSettings,
  object,
}: {
  object: NotionPage | NotionDatabase
  onCheckedChange: ComponentProps<typeof Switch>['onCheckedChange']
  objectSettings?: z.infer<typeof NotionSettingsInput>['objects'][number]
}) {
  return (
    <FormItem key={object.id} className='flex flex-row items-center gap-2'>
      <FormControl>
        <Switch
          id={object.id}
          theme='success'
          checked={objectSettings?.isEnabled ?? true}
          onCheckedChange={onCheckedChange}
        />
      </FormControl>
      <FormLabel
        htmlFor={object.id}
        className='grid items-center gap-x-2 [grid-template-columns:auto_minmax(0,1fr)]'>
        {/* eslint-disable-next-line prettier/prettier */}
        <div className='text-muted-foreground relative flex h-4 w-4 items-center justify-center p-0 [grid-column:1] [grid-row:1]'>
          {(object.icon ? (
            validator.isURL(object.icon) ? (
              <Image src={object.icon} alt={object.title} fill />
            ) : object.icon.length < 5 ? (
              <p className='text-xs'>{object.icon}</p>
            ) : undefined
          ) : undefined) ?? (
            <FontAwesomeIcon
              icon={object.type === 'page' ? faFileLines : faDatabase}
              className='text-muted-foreground'
            />
          )}
        </div>
        {/* eslint-disable prettier/prettier */}
        <p className='text-secondary-foreground flex gap-2 [grid-row:1] [grid-column:2]'>
          {object.title}
          <Link href={{ pathname: object.url }} className='[grid-row:1]'>
            <Button variant='ghost' size='none' theme='secondary'>
              <FontAwesomeIcon icon={faSquareArrowUpRight} size='xs' />
            </Button>
          </Link>
        </p>
        <small className='text-muted-foreground [grid-column:2_/_-1] [grid-row:2]'>
          edited{' '}
          {object.lastEditedDate?.toLocaleDateString('en') ??
            'at an unknown time'}{' '}
          by {object.lastEditedBy?.name ?? 'an unknown user'}
        </small>
      </FormLabel>
    </FormItem>
  )
}

export function NotionSettings() {
  const pagesQueryResult = api.notion.listPages.useQuery()
  const pages = pagesQueryResult.data
  const databasesQueryResult = api.notion.listDatabases.useQuery()
  const databases = databasesQueryResult.data

  const integrationsQueryResult = api.integration.list.useQuery()
  const integrations = integrationsQueryResult.data
  const notionIntegration = integrations?.find(
    integration => integration.name === IntegrationName.NOTION
  )
  const notionIntegrationSettings = notionIntegration?.settings
  const notionIntegrationSettingsObjects: NotionObjectsType =
    notionIntegrationSettings &&
    typeof notionIntegrationSettings === 'object' &&
    'objects' in notionIntegrationSettings
      ? (notionIntegrationSettings?.objects as NotionObjectsType)
      : []

  const updateNotionSettingsMutationResult =
    api.notion.updateSettings.useMutation({
      onSuccess: () => {
        integrationsQueryResult.refetch()
      },
    })

  const form = useForm<z.infer<typeof NotionSettingsInput>>({
    resolver: zodResolver(NotionSettingsInput),
  })

  const onSubmit = (data: z.infer<typeof NotionSettingsInput>) => {
    console.log('Saving settings', data)
    updateNotionSettingsMutationResult.mutate(data)
  }

  const toggleEnabled = (
    field: ControllerRenderProps<
      {
        objects: {
          id: string
          title: string
          isEnabled: boolean
        }[]
      },
      'objects'
    >,
    objectId: string,
    checked: boolean
  ) => {
    const updatedValue = field.value.map(existingObject => ({
      ...existingObject,
      isEnabled:
        existingObject.id === objectId ? checked : existingObject.isEnabled,
    }))
    console.log('Updating objects', updatedValue)
    field.onChange(updatedValue)
  }

  // Save form on change
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [form.handleSubmit, form.watch])

  // Set default values
  useEffect(() => {
    if (!pages || !databases || !notionIntegrationSettingsObjects) {
      return
    }
    const defaultObjects = [...pages, ...databases].map(object => ({
      id: object.id,
      title: object.title,
      isEnabled:
        notionIntegrationSettingsObjects.find(
          settingsObject => settingsObject.id === object.id
        )?.isEnabled ?? true,
    }))

    if (_.isEqual(form.getValues()?.objects, defaultObjects)) {
      console.log('No change from default values')
      return
    }

    console.log('Setting default values', defaultObjects)
    form.reset({ objects: defaultObjects })
  }, [pages, databases, notionIntegrationSettingsObjects])

  if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log(form.formState)
    }, [form.formState])
  }

  if (
    pagesQueryResult.isLoading ||
    databasesQueryResult.isLoading ||
    integrationsQueryResult.isLoading
  ) {
    return <Skeleton className='h-64 w-full' />
  }

  if (!notionIntegration) {
    return null
  }

  const errorMessage =
    form.formState.errors.root?.message ??
    form.formState.errors.objects?.message ??
    pagesQueryResult.error?.message ??
    databasesQueryResult.error?.message ??
    integrationsQueryResult.error?.message ??
    updateNotionSettingsMutationResult.error?.message

  const isLoading = pagesQueryResult.isLoading || databasesQueryResult.isLoading

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-5'>
        <FormMessage>{errorMessage}</FormMessage>
        {isLoading && <Skeleton className='h-64 w-full' />}
        <FormField
          control={form.control}
          name='objects'
          render={({ field }) => (
            <section className='grid gap-x-4 gap-y-10 xl:grid-cols-2'>
              {pages && (
                <section className='flex flex-col gap-1'>
                  <FormLabel className='text-secondary-foreground' size='lg'>
                    Pages ({pages?.length})
                  </FormLabel>
                  <FormDescription>
                    Select the pages you want to include.
                  </FormDescription>

                  <ScrollArea
                    type='always'
                    className='bg-base-200 h-64 max-h-fit rounded-sm shadow-inner'>
                    <ul className='space-y-2 p-2'>
                      {pages?.map(page => (
                        <NotionObjectToggle
                          key={page.id}
                          object={page}
                          onCheckedChange={toggleEnabled.bind(
                            null,
                            field,
                            page.id
                          )}
                          objectSettings={field.value?.find(
                            object => object.id === page.id
                          )}
                        />
                      ))}
                    </ul>
                  </ScrollArea>
                </section>
              )}
              {databases && (
                <section className='flex flex-col gap-1'>
                  <FormLabel className='text-secondary-foreground' size='lg'>
                    Databases ({databases?.length})
                  </FormLabel>
                  <FormDescription>
                    Select the databases you want to include.
                  </FormDescription>
                  <ScrollArea
                    type='always'
                    className='bg-base-200 h-64 max-h-fit rounded-sm shadow-inner'>
                    <ul className='space-y-2 p-2'>
                      {databases?.map(database => (
                        <NotionObjectToggle
                          key={database.id}
                          object={database}
                          onCheckedChange={toggleEnabled.bind(
                            null,
                            field,
                            database.id
                          )}
                          objectSettings={field.value?.find(
                            object => object.id === database.id
                          )}
                        />
                      ))}
                    </ul>
                  </ScrollArea>
                </section>
              )}
            </section>
          )}
        />
      </form>
    </Form>
  )
}
