import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const badgeVariants = cva(
  'rounded-full border transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 inline-flex items-center justify-center gap-2 [line-height:0] min-w-fit',
  {
    variants: {
      variant: {
        default: 'border-none',
        outline: 'border bg-transparent hover:opacity-50',
      },
      theme: {
        gradient: 'bg-gradient text-primary-foreground',
        primary: 'bg-primary text-primary-foreground hover:bg-primary/80',
        default: 'bg-slate-400 text-muted hover:bg-slate-400/80',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        success: 'bg-success text-primary-foreground hover:bg-success/80',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/80',
        warning: 'bg-warning text-warning-foreground hover:bg-warning/80',
        input: '',
      },
      size: {
        default: 'h-6 px-2.5 py-0.5 text-xs font-semibold w-auto',
        sm: 'h-4 text-[0.65rem] font-normal px-2.5 py-0.5',
        xs: 'text-[0.65rem] font-normal min-h-5 min-w-5 leading-none',
      },
    },
    defaultVariants: {
      variant: 'default',
      theme: 'secondary',
      size: 'default',
    },
    compoundVariants: [
      {
        variant: 'outline',
        className: 'bg-transparent hover:bg-transparent',
      },
      {
        variant: 'outline',
        theme: 'primary',
        className: 'border-primary text-primary',
      },
      {
        variant: 'outline',
        theme: 'secondary',
        className: 'border-secondary-foreground text-secondary-foreground',
      },
      {
        variant: 'outline',
        theme: 'success',
        className: 'border-success text-success',
      },
      {
        variant: 'outline',
        theme: 'destructive',
        className: 'border-destructive text-destructive',
      },
      {
        variant: 'outline',
        theme: 'warning',
        className: 'border-warning text-warning',
      },
      {
        variant: 'outline',
        theme: 'gradient',
        className: 'border-gradient text-primary',
      },
      {
        variant: 'outline',
        theme: 'input',
        className: 'border-input text-secondary-foreground hover:bg-white',
      },
    ],
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(function Badge(
  { className, variant, theme, size, ...props },
  ref
) {
  return (
    <div
      className={cn(badgeVariants({ variant, theme, size }), className)}
      ref={ref}
      {...props}
    />
  )
})

export { Badge, badgeVariants }
