import { cva, type VariantProps } from 'class-variance-authority'
import Link from 'next/link'
import React from 'react'
import { cn } from '../libs/utils'

const rootVariant = cva('transition-colors', {
  variants: {
    variant: {
      premium:
        'text-gradient hover:border-b-primary border-b border-b-transparent',
      primary: 'text-primary hover:text-primary/50 duration-200',
      secondary: 'text-grey-darkest hover:text-primary duration-200',
      light: 'text-grey hover:text-primary duration-200',
      underline: 'border-b',
    },
    theme: {
      secondary:
        'text-grey-darkest hover:opacity-50 duration-200 border-grey-darkest',
    },
  },
  defaultVariants: {
    variant: 'secondary',
  },
})

interface CustomLinkProps
  extends Omit<React.ComponentProps<typeof Link>, 'href'>,
    VariantProps<typeof rootVariant> {
  children: React.ReactNode
  className?: string
  href?: React.ComponentProps<typeof Link>['href']
}

const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  className,
  variant,
  href,
  theme,
  ...props
}) => {
  const child = (
    <span className={cn(rootVariant({ variant, theme }), className)}>
      {children}
    </span>
  )

  if (!href) return child

  return (
    <Link {...props} href={href}>
      {child}
    </Link>
  )
}

export { CustomLink as Link }
