import { DesktopSidebar } from '@/web/components/DesktopSidebar'
import { Footer } from '@/web/components/Footer'
import { LandingNavbar } from '@/web/components/LandingNavbar'
import { Loader } from '@/web/components/Loader'
import { MobileAuthenticatedNavbar } from '@/web/components/MobileAuthenticatedNavbar'
import { MobileSidebar } from '@/web/components/MobileSidebar'
import { StatusTopBar } from '@/web/components/StatusTopBar'
import { NO_NAVBAR_PATHS, PUBLIC_PATHS, ROUTE } from '@/web/constants/router'
import { env } from '@/web/env'
import { fontVariables } from '@/web/font'
import { useHasSidebar } from '@/web/hooks/use-has-sidebar'
import { useGlobalKeyboardShortcuts } from '@/web/hooks/useGlobalKeyboardShortcuts'
import { cn } from '@/web/libs/utils'
import { segment } from '@/web/singletons/segment'
import { alias, identify } from '@/web/utils/analytics'
import { setUser } from '@sentry/nextjs'
import { type Route } from 'next'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)

  const router = useRouter()
  const session = useSession()

  useGlobalKeyboardShortcuts()

  if (env.NEXT_PUBLIC_DEBUG === 'true') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log('Session changed', { session })
    }, [session])
  }

  // Redirect from non-public paths if unauthenticated
  useEffect(() => {
    if (
      session.status === 'unauthenticated' &&
      !PUBLIC_PATHS.includes(router.pathname as Route)
    ) {
      console.log(
        'Redirecting to sign in from non-public path',
        router.pathname
      )
      router.replace(ROUTE.SIGN_IN)
    }
  }, [session, router])

  // Track user
  useEffect(() => {
    if (!session.data?.user.id) {
      setUser(null)
      return
    }

    if (session.data.user.email) {
      alias(session.data.user.id, session.data.user.email)
    }

    identify(session.data.user.id, {
      ...session.data.user,
    })

    setUser({
      id: session.data.user.id,
      email: session.data.user.email ?? undefined,
      name: session.data.user.name ?? undefined,
    })
  }, [session.data?.user.id])

  // Track page
  useEffect(() => {
    if (!router.isReady) {
      return
    }

    segment.page(router.asPath, {
      route: router.route,
      query: router.query,
      basePath: router.basePath,
    })
  }, [router.asPath, router.isReady])

  // Scroll to top on route change
  const scrollRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!scrollRef.current) {
      return
    }

    scrollRef.current.scrollTo(0, 0)
  }, [router.asPath, scrollRef])

  const hasSidebar = useHasSidebar()
  const hasNavbar =
    !hasSidebar && !NO_NAVBAR_PATHS.includes(router.pathname as Route)

  useEffect(() => {
    if (session.status === 'loading' || !hasNavbar) {
      return
    }

    // Load crisp
    const scriptId = 'crisp-chat-script'
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true
      document.head.appendChild(script)
      // @ts-ignore
      window.$crisp = []
      // @ts-ignore
      window.CRISP_WEBSITE_ID = 'e9c1266b-f8cf-4a7a-99a2-b1d393688c60'
    }
  }, [session.status, hasNavbar])

  if (
    session.status !== 'authenticated' &&
    !PUBLIC_PATHS.includes(router.pathname as Route)
  ) {
    return (
      <main
        className={cn(
          'bg-base-100 relative h-[100dvh] overflow-x-hidden overflow-y-scroll',
          ...fontVariables
        )}>
        <Loader />
      </main>
    )
  }

  if (!hasSidebar) {
    return (
      <main
        ref={scrollRef}
        className={cn(
          'bg-base-100 relative h-[100dvh] overflow-x-hidden overflow-y-scroll',
          ...fontVariables
        )}>
        {hasNavbar && <LandingNavbar />}
        {children}
        <Footer />
      </main>
    )
  }

  return (
    <div className='flex h-[100dvh] w-full flex-col'>
      <StatusTopBar />

      <MobileSidebar
        isOpen={isMobileSidebarOpen}
        setIsOpen={setIsMobileSidebarOpen}
      />

      <MobileAuthenticatedNavbar
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
      />

      <div className='flex flex-1 contain-inline-size'>
        <DesktopSidebar />

        <main
          ref={scrollRef}
          className={cn(
            'bg-base-200 h-full flex-1 overflow-y-auto',
            ...fontVariables
          )}>
          {children}
        </main>
      </div>
    </div>
  )
}
