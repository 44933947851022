import { TopBar } from '@/web/components/TopBar'
import { api } from '@/web/utils/api'

export const StatusTopBar = () => {
  const getStatusResult = api.status.get.useQuery()

  if (getStatusResult.data?.serviceUp !== false) return null

  return (
    <TopBar>
      There is a service outage at the moment and some things may not work as
      expected.
    </TopBar>
  )
}
