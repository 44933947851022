// @see https://www.reddit.com/dev/api

import {
  ActionType,
  ActivityType,
  type Action,
  type KeywordMatch,
  type KeywordMatchType,
} from '@prisma/client'
import { z } from 'zod'

export const SCRIPT_USER_AGENT = 'script:pulse:v1.0.0 (by /u/usepulseai)'

export type RedditUserType = {
  id: string // Unique identifier for the user
  name: string // Username of the user
  created: number // Account creation time in UTC
  link_karma: number // Link karma of the user
  comment_karma: number // Comment karma of the user
  is_gold: boolean // Indicates if the user has Reddit Gold
  is_mod: boolean // Indicates if the user is a moderator
  over_18: boolean // Indicates if the user is over 18
  verified: boolean // Indicates if the user is verified
  icon_img?: string // URL of the user's icon
  snoovatar_img?: string // URL of the user's snoovatar
}

export type RedditSubredditType = {
  display_name: string
  title: string
  subscribers: number
  public_description: string
  url: string
}

// Define the type for a Reddit post
export type RedditPostType = {
  id: string // Unique identifier for the post
  title: string // Title of the post
  author: string // Author of the post
  subreddit: string // Subreddit where the post was made
  subreddit_subscribers: number // Number of subscribers to the subreddit
  url: string // URL of the post
  created_utc: number // Creation time in UTC
  selftext?: string // Text content of the post (if any)
  score: number // Score of the post
  num_comments: number // Number of comments on the post
  is_self: boolean // Indicates if the post is a self post
  over_18: boolean // Indicates if the post is marked as NSFW
  spoiler: boolean // Indicates if the post is marked as a spoiler
  stickied: boolean // Indicates if the post is stickied
}

// Define the type for a Reddit comment
export type RedditCommentType = {
  id: string // Unique identifier for the comment
  author: string // Author of the comment
  body?: string // Text content of the comment
  created_utc: number // Creation time in UTC
  score: number // Score of the comment
  parent_id: string // ID of the parent post or comment
  subreddit: string // Subreddit where the comment was made
  link_id?: string // ID of the post where the comment was made (in the format "t3_<post_id>")
  replies?: RedditCommentType[]
}

export type RedditRawMoreType = {
  count: number
  name: 't1__'
  id: '_'
  parent_id: string
  depth: number
  children: []
}

export type RedditRawCommentType = RedditCommentType & {
  replies?: {
    kind: 'Listing'
    data: {
      children: (
        | { kind: 't1'; data: RedditRawCommentType }
        | { kind: 'More'; data: RedditRawMoreType }
      )[]
    }
  }
}

// Define the schema for Reddit settings
export const RedditSettingsInput = z
  .object({
    subreddits: z.array(z.string()).optional(),
    negativeSubreddits: z.array(z.string()).optional(),
    keywords: z.array(z.string()).optional(),
    isPromotingBusiness: z.boolean().optional(),
  })
  .optional()

export type RedditSettingsType = z.infer<typeof RedditSettingsInput>

// Define the available Reddit action types
export const REDDIT_ACTION_TYPES = [
  ActionType.REPLY_TO_COMMENT,
  ActionType.REPLY_TO_POST,
]

// Define the scopes required for the Reddit API
export const REDDIT_SCOPES = [
  'identity', // used to get their username/id
  'read',
  'history', // used to fetch their comments
  // 'submit',
  // 'privatemessages',
  'mysubreddits', // used as default when searching for subreddits
]

export const REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA = {
  [ActionType.REPLY_TO_POST]: z.object({
    postId: z.string(),
    message: z.string().optional(),
    isPromotingBusiness: z.boolean().optional(),
    isPromotingBusinessReason: z.string().optional(),
  }),
  [ActionType.REPLY_TO_COMMENT]: z.object({
    postId: z.string(),
    commentId: z.string(),
    message: z.string().optional(),
    isPromotingBusiness: z.boolean().optional(),
    isPromotingBusinessReason: z.string().optional(),
  }),
} as const satisfies Partial<Record<ActionType, z.SomeZodObject>>
export type RedditReplyToPostParametersType = z.infer<
  (typeof REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.REPLY_TO_POST]
>
export type RedditReplyToCommentParametersType = z.infer<
  (typeof REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.REPLY_TO_COMMENT]
>

export const REDDIT_FULLNAME_TYPE_PREFIXES = [
  't1',
  't2',
  't3',
  't4',
  't5',
  't6',
] as const
export type RedditFullnamePrefixType =
  (typeof REDDIT_FULLNAME_TYPE_PREFIXES)[number]
export type RedditThingType =
  | 'comment'
  | 'post'
  | 'subreddit'
  | 'award'
  | 'account'
  | 'message'

export const REDDIT_FULLNAME_TYPE: Record<
  RedditFullnamePrefixType,
  RedditThingType
> = {
  t1: 'comment',
  t2: 'account',
  t3: 'post',
  t4: 'message',
  t5: 'subreddit',
  t6: 'award',
}

export type RedditSearchResultType = {
  text: string
  keywords: string[]
} & (
  | {
      type: typeof KeywordMatchType.POST
      post: RedditPostType
    }
  | {
      type: typeof KeywordMatchType.COMMENT
      comment: RedditCommentType
    }
)

export type RedditNewPostCommentType = {
  post: RedditPostType
  comment: string
  tokenCount: number
}

export const MIN_POST_SCORE_TO_REPLY = 0
export const MIN_COMMENT_SCORE_TO_REPLY = 0
export const MIN_SUBREDDIT_SUBSCRIBERS_TO_REPLY = 100

export type RedditCommentThreadWithReplyType = {
  post: RedditPostType
  comment: RedditCommentType
  postAuthor: RedditUserType
  commentUsers: RedditUserType[]
  replyId: string
  action?: Action
}
export type RedditCommentThreadsWithReplyType =
  RedditCommentThreadWithReplyType[]

export type RedditKeywordMatchWithDataType =
  | (KeywordMatch & {
      type: typeof KeywordMatchType.POST
      post: RedditPostType | null
    })
  | (KeywordMatch & {
      type: typeof KeywordMatchType.COMMENT
      comment: RedditCommentType | null
    })

export enum RedditCommentList {
  Generated = 'generated',
  Sent = 'sent',
  Cancelled = 'cancelled',
}
export enum RedditReplyList {
  Inbox = 'inbox',
  Sent = 'sent',
  Cancelled = 'cancelled',
}
export enum RedditKeywordsList {
  Inbox = 'inbox',
  Sent = 'sent',
  Cancelled = 'cancelled',
}

export const REDDIT_ACTIVITY_TYPES = [
  ActivityType.KEYWORD_MATCH_PROCESSED,
  ActivityType.COMMENT_PROCESSED,
  ActivityType.COMMENT_GENERATED,
]

export const SUBREDDIT_RESTRICTIONS = {
  communitymanager: {
    minDays: 10,
  },
  productivity: {
    minDays: 1,
  },
  SocialMediaManagers: {
    minDays: 1,
  },
  PartneredYoutube: {
    minDays: 1, // TBD
  },
}
