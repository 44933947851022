import { Logo } from '@/web/components/Logo'
import { NavButtonList } from '@/web/components/NavButtonList'
import {
  NAVIGATION_SIDEBAR_ITEMS,
  REDDIT_INTEGRATION_SIDEBAR_ITEMS,
  YOUTUBE_INTEGRATION_SIDEBAR_ITEMS,
} from '@/web/constants/navigation'
import { useHasSidebar } from '@/web/hooks/use-has-sidebar'
import { useIntegrationListQueryResult } from '@/web/hooks/useIntegrationListQueryResult'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'
import { Fragment, useEffect, useState } from 'react'

export const MobileSidebar = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const hasSidebar = useHasSidebar()

  const [sidebarItems, setSidebarItems] = useState(NAVIGATION_SIDEBAR_ITEMS)

  const integrationListQueryResult = useIntegrationListQueryResult()
  useEffect(() => {
    if (integrationListQueryResult.isSuccess) {
      const hasRedditIntegration = integrationListQueryResult.data.some(
        integration =>
          integration.name === IntegrationName.REDDIT && integration.isEnabled
      )
      if (hasRedditIntegration) {
        setSidebarItems(items =>
          _.uniqBy([...REDDIT_INTEGRATION_SIDEBAR_ITEMS, ...items], 'href')
        )
      }

      const hasYoutubeIntegration = integrationListQueryResult.data.some(
        integration =>
          integration.name === IntegrationName.YOUTUBE && integration.isEnabled
      )
      if (hasYoutubeIntegration) {
        setSidebarItems(items =>
          _.uniqBy([...YOUTUBE_INTEGRATION_SIDEBAR_ITEMS, ...items], 'href')
        )
      }
    }
  }, [integrationListQueryResult.data])

  if (!hasSidebar) {
    return null
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50 lg:hidden' onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-900/80' />
        </Transition.Child>

        <div className='fixed inset-0 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'>
            <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                  <button
                    type='button'
                    className='-m-2.5 p-2.5'
                    onClick={() => setIsOpen(false)}>
                    <span className='sr-only'>Close sidebar</span>
                    <XMarkIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white p-2'>
                <div className='flex w-full shrink-0 items-center justify-start'>
                  <Logo />
                </div>
                <NavButtonList
                  items={sidebarItems}
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
