import { Logo } from '@/web/components/Logo'
import { NavButtonList } from '@/web/components/NavButtonList'
import { Button } from '@/web/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/web/components/ui/popover'
import { UserAvatar } from '@/web/components/UserAvatar'
import {
  NAVIGATION_SIDEBAR_ITEMS,
  REDDIT_INTEGRATION_SIDEBAR_ITEMS,
  YOUTUBE_INTEGRATION_SIDEBAR_ITEMS,
} from '@/web/constants/navigation'
import { useHasSidebar } from '@/web/hooks/use-has-sidebar'
import { useIntegrationListQueryResult } from '@/web/hooks/useIntegrationListQueryResult'
import { cn } from '@/web/libs/utils'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'
import { ChevronsLeft } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

export const DesktopSidebar = () => {
  const session = useSession()

  const [isOpen, setIsOpen] = useLocalStorageState('sidebar-open', {
    defaultValue: true,
  })

  const [sidebarItems, setSidebarItems] = useState(NAVIGATION_SIDEBAR_ITEMS)

  const integrationListQueryResult = useIntegrationListQueryResult()
  useEffect(() => {
    if (integrationListQueryResult.isSuccess) {
      const hasRedditIntegration = integrationListQueryResult.data.some(
        integration =>
          integration.name === IntegrationName.REDDIT && integration.isEnabled
      )
      if (hasRedditIntegration) {
        setSidebarItems(items =>
          _.uniqBy([...REDDIT_INTEGRATION_SIDEBAR_ITEMS, ...items], 'href')
        )
      }

      const hasYoutubeIntegration = integrationListQueryResult.data.some(
        integration =>
          integration.name === IntegrationName.YOUTUBE && integration.isEnabled
      )
      if (hasYoutubeIntegration) {
        setSidebarItems(items =>
          _.uniqBy([...YOUTUBE_INTEGRATION_SIDEBAR_ITEMS, ...items], 'href')
        )
      }
    }
  }, [integrationListQueryResult.data])

  const hasSidebar = useHasSidebar()
  if (!hasSidebar) {
    return null
  }

  return (
    <div
      className={cn(
        'hidden h-full overflow-y-auto border-r border-gray-200 bg-white px-2 transition-all duration-300 ease-in-out lg:block',
        isOpen ? 'w-64' : 'w-[57px]'
      )}>
      <div className='flex h-full flex-col gap-y-12'>
        <div
          className={cn(
            'flex h-16 shrink-0 items-center justify-between py-2',
            !isOpen && 'px-0'
          )}>
          {isOpen ? <Logo size='sm' /> : null}
          <Button
            variant='sidebar'
            onClick={() => setIsOpen(!isOpen)}
            className='size-10 justify-center p-0'>
            {isOpen ? (
              <ChevronsLeft className='size-4 shrink-0' />
            ) : (
              <Bars3Icon className='size-4 shrink-0' />
            )}
          </Button>
        </div>
        <NavButtonList
          items={sidebarItems}
          isOpen={isOpen}
          isShowingChildren={false}
        />
        <Popover>
          <PopoverTrigger asChild className='mb-2 mt-auto'>
            <Button
              variant='sidebar'
              className={cn(!isOpen && 'justify-center')}>
              <UserAvatar user={session.data?.user} size='sm' />
              {isOpen ? session.data?.user.name : null}
            </Button>
          </PopoverTrigger>
          <PopoverContent className='w-64'>
            <Button
              theme='destructive'
              className='w-full'
              onClick={() => signOut()}>
              Sign out
            </Button>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
