import { PasswordSchema } from '@/shared/constants/user'
import dayjs from '@/shared/singletons/dayjs'
import { Day, Time } from '@prisma/client'
import { z } from 'zod'

export enum WorkRole {
  ACCOUNTANT = 'Accountant',
  ACTUARY = 'Actuary',
  ADJUNCT_PROFESSOR = 'Adjunct Professor',
  ADMINISTRATIVE_ASSISTANT = 'Administrative Assistant',
  AERONAUTICAL_ENGINEER = 'Aeronautical Engineer',
  AEROSPACE_ENGINEER = 'Aerospace Engineer',
  AGRICULTURIST = 'Agriculturist',
  AGRONOMIST = 'Agronomist',
  ANIMATOR = 'Animator',
  ANTHROPOLOGIST = 'Anthropologist',
  ARBORIST = 'Arborist',
  ARCHAEOLOGIST = 'Archaeologist',
  ARCHITECT = 'Architect',
  ARCHIVIST = 'Archivist',
  ART_DIRECTOR = 'Art Director',
  ART_THERAPIST = 'Art Therapist',
  ARTIST = 'Artist',
  ASTRONOMER = 'Astronomer',
  ASTROPHYSICIST = 'Astrophysicist',
  ATHLETIC_COACH = 'Athletic Coach',
  AUDIO_ENGINEER = 'Audio Engineer',
  AUDIOLOGIST = 'Audiologist',
  BACKEND_DEVELOPER = 'Backend Developer',
  BAKER = 'Baker',
  BANKER = 'Banker',
  BARBER = 'Barber',
  BARISTA = 'Barista',
  BARTENDER = 'Bartender',
  BEAUTICIAN = 'Beautician',
  BIOLOGIST = 'Biologist',
  BIOMECHANICAL_ENGINEER = 'Biomechanical Engineer',
  BIOMEDICAL_ENGINEER = 'Biomedical Engineer',
  BOTANIST = 'Botanist',
  BRANCH_MANAGER = 'Branch Manager',
  BRAND_MANAGER = 'Brand Manager',
  BROADCASTER = 'Broadcaster',
  BUSINESS_ANALYST = 'Business Analyst',
  BUSINESS_DEVELOPMENT_MANAGER = 'Business Development Manager',
  CARTOGRAPHER = 'Cartographer',
  CATERER = 'Caterer',
  CBO = 'Chief Business Officer',
  CCO = 'Chief Communications Officer',
  CDO = 'Chief Data Officer',
  CEO = 'Chief Executive Officer',
  CFO = 'Chief Financial Officer',
  CHEF = 'Chef',
  CHEMIST = 'Chemist',
  CHIROPRACTOR = 'Chiropractor',
  CHRO = 'Chief Human Resources Officer',
  CINEMATOGRAPHER = 'Cinematographer',
  CIO = 'Chief Information Officer',
  CISO = 'Chief Information Security Officer',
  CIVIC_PLANNER = 'Civic Planner',
  CIVIL_ENGINEER = 'Civil Engineer',
  CLINICAL_PSYCHOLOGIST = 'Clinical Psychologist',
  CLINICAL_RESEARCHER = 'Clinical Researcher',
  CLO = 'Chief Legal Officer',
  CLOUD_ENGINEER = 'Cloud Engineer',
  CMO = 'Chief Marketing Officer',
  COMEDIAN = 'Comedian',
  COMMUNICATIONS_DIRECTOR = 'Communications Director',
  CONSERVATIONIST = 'Conservationist',
  CONSTRUCTION_MANAGER = 'Construction Manager',
  CONTENT_STRATEGIST = 'Content Strategist',
  COO = 'Chief Operating Officer',
  COPYWRITER = 'Copywriter',
  COSMETOLOGIST = 'Cosmetologist',
  COSTUME_DESIGNER = 'Costume Designer',
  COUNSELOR = 'Counselor',
  CPO = 'Chief Product Officer',
  CRO = 'Chief Revenue Officer',
  CSO = 'Chief Strategy Officer',
  CTO = 'Chief Technology Officer',
  CURATOR = 'Curator',
  CUSTOMER_SERVICE_MANAGER = 'Customer Service Manager',
  CUSTOMER_SERVICE_REPRESENTATIVE = 'Customer Service Representative',
  CUSTOMER_SUCCESS_MANAGER = 'Customer Success Manager',
  CUSTOMER_SUCCESS_REPRESENTATIVE = 'Customer Success Representative',
  CUSTOMER_SUPPORT_REPRESENTATIVE = 'Customer Support Representative',
  DATA_ANALYST = 'Data Analyst',
  DATA_SCIENTIST = 'Data Scientist',
  DATABASE_ADMINISTRATOR = 'Database Administrator',
  DENTAL_HYGIENIST = 'Dental Hygienist',
  DENTIST = 'Dentist',
  DEPARTMENT_HEAD = 'Department Head',
  DERMATOLOGIST = 'Dermatologist',
  DESIGNER = 'Designer',
  DEVOPS_ENGINEER = 'DevOps Engineer',
  DIETARY_AIDE = 'Dietary Aide',
  DIETITIAN = 'Dietitian',
  DIGITAL_MARKETER = 'Digital Marketer',
  DIRECTOR_OF_CUSTOMER_EXPERIENCE = 'Director of Customer Experience',
  DIRECTOR_OF_CUSTOMER_SERVICE = 'Director of Customer Service',
  DIRECTOR_OF_CUSTOMER_SUCCESS = 'Director of Customer Success',
  DIRECTOR_OF_MARKETING = 'Director of Marketing',
  DIRECTOR_OF_SALES = 'Director of Sales',
  DIVISION_MANAGER = 'Division Manager',
  DOCTOR = 'Doctor',
  ECOLOGIST = 'Ecologist',
  ECONOMIST = 'Economist',
  EDITOR = 'Editor',
  EDUCATOR = 'Educator',
  ELECTRICAL_ENGINEER = 'Electrical Engineer',
  ELECTRICIAN = 'Electrician',
  EMBALMER = 'Embalmer',
  EMT = 'Emt',
  ENGINEERING_MANAGER = 'Engineering Manager',
  ENTREPRENEUR = 'Entrepreneur',
  ENVIRONMENTAL_ENGINEER = 'Environmental Engineer',
  ENVIRONMENTALIST = 'Environmentalist',
  EPIDEMIOLOGIST = 'Epidemiologist',
  ESTHETICIAN = 'Esthetician',
  ETHNOGRAPHER = 'Ethnographer',
  EVENT_COORDINATOR = 'Event Coordinator',
  EVENT_PLANNER = 'Event Planner',
  EXECUTIVE_CHEF = 'Executive Chef',
  FASHION_DESIGNER = 'Fashion Designer',
  FILM_DIRECTOR = 'Film Director',
  FINANCE_MANAGER = 'Finance Manager',
  FINANCIAL_ADVISOR = 'Financial Advisor',
  FINANCIAL_ANALYST = 'Financial Analyst',
  FIREFIGHTER = 'Firefighter',
  FISHERMAN = 'Fisherman',
  FLIGHT_ATTENDANT = 'Flight Attendant',
  FLORIST = 'Florist',
  FORENSIC_SCIENTIST = 'Forensic Scientist',
  FORESTER = 'Forester',
  FRONTEND_DEVELOPER = 'Frontend Developer',
  FULLSTACK_DEVELOPER = 'Fullstack Developer',
  GAME_DESIGNER = 'Game Designer',
  GENETICIST = 'Geneticist',
  GEOGRAPHER = 'Geographer',
  GEOLOGIST = 'Geologist',
  GERONTOLOGIST = 'Gerontologist',
  GRAPHIC_DESIGNER = 'Graphic Designer',
  HANDYMAN = 'Handyman',
  HEAD_OF_CUSTOMER_EXPERIENCE = 'Head of Customer Experience',
  HEAD_OF_CUSTOMER_SERVICE = 'Head of Customer Service',
  HEAD_OF_CUSTOMER_SUCCESS = 'Head of Customer Success',
  HEAD_OF_MARKETING = 'Head of Marketing',
  HEAD_OF_SALES = 'Head of Sales',
  HEAD_OF_PRODUCT = 'Head of Product',
  HEAD_OF_OPERATIONS = 'Head of Operations',
  HISTORIAN = 'Historian',
  HORTICULTURIST = 'Horticulturist',
  HOTEL_MANAGER = 'Hotel Manager',
  HR_MANAGER = 'Human Resources Manager',
  HR_SPECIALIST = 'Human Resources Specialist',
  ILLUSTRATOR = 'Illustrator',
  IMMUNOLOGIST = 'Immunologist',
  INDUSTRIAL_DESIGNER = 'Industrial Designer',
  INFECTIOUS_DISEASE_SPECIALIST = 'Infectious Disease Specialist',
  INFORMATION_BROKER = 'Information Broker',
  INSTRUCTIONAL_DESIGNER = 'Instructional Designer',
  INSURANCE_AGENT = 'Insurance Agent',
  INTERIOR_DECORATOR = 'Interior Decorator',
  INTERPRETER = 'Interpreter',
  INVESTIGATOR = 'Investigator',
  INVESTMENT_BANKER = 'Investment Banker',
  IT_MANAGER = 'IT Manager',
  IT_SPECIALIST = 'It Specialist',
  JEWELER = 'Jeweler',
  JOURNALIST = 'Journalist',
  JUDGE = 'Judge',
  LAB_TECHNICIAN = 'Lab Technician',
  LANDSCAPE_ARCHITECT = 'Landscape Architect',
  LAWYER = 'Lawyer',
  LIBRARIAN = 'Librarian',
  LINGUIST = 'Linguist',
  LOAN_OFFICER = 'Loan Officer',
  LOCKSMITH = 'Locksmith',
  LOGISTICIAN = 'Logistician',
  LOGOPAEDIST = 'Logopaedist',
  MAKEUP_ARTIST = 'Makeup Artist',
  MANUFACTURING_ENGINEER = 'Manufacturing Engineer',
  MARINE_BIOLOGIST = 'Marine Biologist',
  MARINE_ENGINEER = 'Marine Engineer',
  MARKETING_DIRECTOR = 'Marketing Director',
  MARKETING_MANAGER = 'Marketing Manager',
  MASSAGE_THERAPIST = 'Massage Therapist',
  MATHEMATICIAN = 'Mathematician',
  MECHANIC = 'Mechanic',
  MECHANICAL_ENGINEER = 'Mechanical Engineer',
  MEDIATOR = 'Mediator',
  MEDICAL_TECHNICIAN = 'Medical Technician',
  METEOROLOGIST = 'Meteorologist',
  MICROBIOLOGIST = 'Microbiologist',
  MIDWIFE = 'Midwife',
  MILITARY_OFFICER = 'Military Officer',
  MILLWRIGHT = 'Millwright',
  MINERALOGIST = 'Mineralogist',
  MOBILE_DEVELOPER = 'Mobile Developer',
  MODEL = 'Model',
  MOTIVATIONAL_SPEAKER = 'Motivational Speaker',
  MUSEUM_GUIDE = 'Museum Guide',
  MUSIC_PRODUCER = 'Music Producer',
  MUSICIAN = 'Musician',
  MYCOLOGIST = 'Mycologist',
  NEONATOLOGIST = 'Neonatologist',
  NETWORK_ENGINEER = 'Network Engineer',
  NOTARY = 'Notary',
  NURSE_PRACTITIONER = 'Nurse Practitioner',
  NUTRITIONIST = 'Nutritionist',
  OCCUPATIONAL_THERAPIST = 'Occupational Therapist',
  ONCOLOGIST = 'Oncologist',
  OPERATIONS_MANAGER = 'Operations Manager',
  OPTOMETRIST = 'Optometrist',
  ORNITHOLOGIST = 'Ornithologist',
  ORTHODONTIST = 'Orthodontist',
  PACKAGING_DESIGNER = 'Packaging Designer',
  PAEDIATRICIAN = 'Paediatrician',
  PAINTER = 'Painter',
  PARALEGAL = 'Paralegal',
  PARAMEDIC = 'Paramedic',
  PASTOR = 'Pastor',
  PATHOLOGIST = 'Pathologist',
  PEDIATRICIAN = 'Pediatrician',
  PERSONAL_CHEF = 'Personal Chef',
  PERSONAL_TRAINER = 'Personal Trainer',
  PETROLEUM_ENGINEER = 'Petroleum Engineer',
  PHARMACEUTICAL_REP = 'Pharmaceutical Rep',
  PHARMACIST = 'Pharmacist',
  PHARMACOLOGIST = 'Pharmacologist',
  PHILOSOPHER = 'Philosopher',
  PHOTOGRAPHER = 'Photographer',
  PHOTOJOURNALIST = 'Photojournalist',
  PHYSICAL_THERAPIST = 'Physical Therapist',
  PHYSICIST = 'Physicist',
  PILOT = 'Pilot',
  PLAYWRIGHT = 'Playwright',
  PLUMBER = 'Plumber',
  PODIATRIST = 'Podiatrist',
  POET = 'Poet',
  POLICE_OFFICER = 'Police Officer',
  POLITICAL_SCIENTIST = 'Political Scientist',
  PRIVATE_INVESTIGATOR = 'Private Investigator',
  PRODUCT_MANAGER = 'Product Manager',
  PRODUCTION_ENGINEER = 'Production Engineer',
  PROJECT_MANAGER = 'Project Manager',
  PROOFREADER = 'Proofreader',
  PROPERTY_MANAGER = 'Property Manager',
  PSYCHIATRIST = 'Psychiatrist',
  PSYCHOLOGIST = 'Psychologist',
  PSYCHOTHERAPIST = 'Psychotherapist',
  PUBLIC_RELATIONS_OFFICER = 'Public Relations Officer',
  QA_ENGINEER = 'QA Engineer',
  QUALITY_ANALYST = 'Quality Analyst',
  RADIO_HOST = 'Radio Host',
  RADIOLOGIST = 'Radiologist',
  REAL_ESTATE_AGENT = 'Real Estate Agent',
  REAL_ESTATE_BROKER = 'Real Estate Broker',
  RECEPTIONIST = 'Receptionist',
  RECREATIONAL_THERAPIST = 'Recreational Therapist',
  RECRUITER = 'Recruiter',
  REFLEXOLOGIST = 'Reflexologist',
  REGISTERED_NURSE = 'Registered Nurse',
  REPORTER = 'Reporter',
  RESEARCH_MANAGER = 'Research Manager',
  RESEARCH_SCIENTIST = 'Research Scientist',
  RHEUMATOLOGIST = 'Rheumatologist',
  ROBOTICS_ENGINEER = 'Robotics Engineer',
  SAFETY_ENGINEER = 'Safety Engineer',
  SALES_ASSOCIATE = 'Sales Associate',
  SALES_MANAGER = 'Sales Manager',
  SCRUM_MASTER = 'Scrum Master',
  SCULPTOR = 'Sculptor',
  SECURITY_ANALYST = 'Security Analyst',
  SET_DESIGNER = 'Set Designer',
  SITE_RELIABILITY_ENGINEER = 'Site Reliability Engineer',
  SOCIAL_MEDIA_MANAGER = 'Social Media Manager',
  SOCIAL_WORKER = 'Social Worker',
  SOCIOLOGIST = 'Sociologist',
  SOFTWARE_ENGINEER = 'Software Engineer',
  SOLUTIONS_ARCHITECT = 'Solutions Architect',
  SPEECH_THERAPIST = 'Speech Therapist',
  SPORTS_ANALYST = 'Sports Analyst',
  STAGE_ACTOR = 'Stage Actor',
  STATISTICAL_ANALYST = 'Statistical Analyst',
  STATISTICIAN = 'Statistician',
  STYLIST = 'Stylist',
  SUBSTANCE_ABUSE_COUNSELOR = 'Substance Abuse Counselor',
  SURGEON = 'Surgeon',
  SYSTEM_ADMINISTRATOR = 'System Administrator',
  SYSTEMS_ANALYST = 'Systems Analyst',
  TAILOR = 'Tailor',
  TAX_CONSULTANT = 'Tax Consultant',
  TAXIDERMIST = 'Taxidermist',
  TEAM_LEAD = 'Team Lead',
  TECHNICAL_WRITER = 'Technical Writer',
  TELECOMMUNICATIONS_ENGINEER = 'Telecommunications Engineer',
  TELEVISION_PRODUCER = 'Television Producer',
  THERAPIST = 'Therapist',
  TOXICOLOGIST = 'Toxicologist',
  TRAVEL_AGENT = 'Travel Agent',
  UI_DESIGNER = 'UI Designer',
  URBAN_PLANNER = 'Urban Planner',
  UROLOGIST = 'Urologist',
  UX_DESIGNER = 'UX Designer',
  VETERINARIAN = 'Veterinarian',
  VETERINARY_TECHNICIAN = 'Veterinary Technician',
  VIDEOGRAPHER = 'Videographer',
  VISUAL_ARTIST = 'Visual Artist',
  VOICE_ACTOR = 'Voice Actor',
  WEB_DEVELOPER = 'Web Developer',
  WILDLIFE_BIOLOGIST = 'Wildlife Biologist',
  WINE_MAKER = 'Wine Maker',
  WRITER = 'Writer',
  ZOOLOGIST = 'Zoologist',
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TIMEZONES = Intl.supportedValuesOf('timeZone')

export const SettingsInput = z.object({
  // Personal
  birthDate: z.date().default(dayjs().subtract(30, 'year').toDate()),
  location: z.string().min(1).max(100).optional(),
  // Work
  workRole: z.nativeEnum(WorkRole).optional(),
  workExperienceYears: z.coerce.number().max(100).min(0).optional(),
  workCompany: z.string().min(1).max(100).or(z.string().length(0)).optional(),
  workDescription: z
    .string()
    .min(20)
    .max(750)
    .or(z.string().length(0))
    .optional(),
  workCompanyWebsite: z.string().url().or(z.string().length(0)).optional(),
  // Email
  emailDays: z
    .array(z.nativeEnum(Day))
    .default([Day.MONDAY, Day.TUESDAY, Day.WEDNESDAY, Day.THURSDAY, Day.FRIDAY])
    .optional(),
  emailTime: z.nativeEnum(Time).default(Time.AM_9).optional(),
  timeZone: z
    .enum(TIMEZONES as [string, ...string[]])
    .default(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .optional(),
})

export const ChangePasswordSchema = z.object({
  currentPassword: PasswordSchema.optional(),
  newPassword: PasswordSchema,
})
