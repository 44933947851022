import { ActionType } from '@prisma/client'
import { z } from 'zod'

// export const TEST_CHANNEL_ID = 'UCX6OQ3DkcsbYNE6H8uQQuVA'
export const TEST_CHANNEL_ID = 'UCSksodwjNeOQ3Oywr1G4Nag' // Engineezy // 'UCyHwggHoB6yRI9MxBqbabew' // Garrett  // 'UCFzqh_Ope7V8Ys4ypPZEyLg' // Blogcast

export const SCOPES = ['https://www.googleapis.com/auth/youtube.force-ssl']

export type YoutubePlaylistType = {
  id: string
  title: string
  description: string
  thumbnail: string
  publishedAt: Date
  itemCount: number
}

export type YoutubeChannelType = {
  id: string
  title: string
  username: string
  description: string
  photoUrl: string
  publishedAt: Date
  subscriberCount: number
  viewCount: number
  videoCount: number
}

export type YoutubeVideoType = {
  id: string
  title: string
  description: string
  thumbnail: string
  publishedAt: Date
  commentCount: number | null
  viewCount: number
  likeCount: number
  dislikeCount: number
  duration: number
}

export type YoutubeCommentType = {
  id: string
  authorChannelId: string
  authorDisplayName: string
  authorProfileImageUrl: string
  textDisplay: string
  replyCount?: number
  publishedAt: Date
  updatedAt: Date
  likeCount: number
  moderationStatus?: 'heldForReview' | 'likelySpam' | 'published' | 'rejected'
  isLikedByUser: boolean
  replies?: YoutubeCommentType[]
  parentCommentId: string | null
  videoId: string
}

export const YoutubeCommentReplyFormSchema = z.object({
  text: z.string().min(1),
})

export type YoutubeSearchResultType = {
  date: Date
  text: string
  embeddingId: string
  type: unknown
} & (
  | {
      type: 'video'
      video: YoutubeVideoType
    }
  | {
      type: 'comment'
      comment: YoutubeCommentType
    }
)

export const YOUTUBE_ACTION_TYPE_PARAMETERS_SCHEMA = {
  [ActionType.REPLY_TO_COMMENT]: z.object({
    parentId: z.string().describe('ID of object being replied to'),
    text: z.string().describe('Text to reply with'),
    videoId: z.string().describe('ID of video'),
    originalText: z.string().describe('Original text of comment'),
  }),
} as const

export type YoutubeReplyToCommentActionResult = z.infer<
  (typeof YOUTUBE_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.REPLY_TO_COMMENT]
> & {
  id: string
}

export const MAX_COMMENTS_PER_DAY_PER_PROJECT = 200

export enum COMMENT_LIST_FILTER {
  ALL = 'All',
  AWAITING_REPLY = 'Awaiting reply',
  DRAFTED = 'Drafted',
  SCHEDULED = 'Scheduled',
  SENT = 'Sent',
}
export type CommentListFilterType = keyof typeof COMMENT_LIST_FILTER
