import { LogoSvg } from '@/web/components/LogoSvg'
import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'

const variants = cva('', {
  variants: {
    size: {
      xs: 'h-10 w-10',
      sm: 'h-20 w-20',
      default: 'h-40 w-40',
      lg: 'h-60 w-60',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

type LoaderPropsType = { className?: string } & VariantProps<
  typeof variants
> & {
    title?: string
    hasIcon?: boolean
  }
export const Loader = forwardRef<HTMLDivElement, LoaderPropsType>(
  function Loader({ className = '', size, title, hasIcon = true }, ref) {
    return (
      <div
        ref={ref}
        className={cn(
          'flex h-full min-h-fit items-center justify-center flex-col gap-2',
          className
        )}>
        {hasIcon ? <LogoSvg className={cn(variants({ size }))} /> : null}
        {title ? <h6 className='text-center text-gradient'>{title}</h6> : null}
      </div>
    )
  }
)
