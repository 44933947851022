import { Badge } from '@/web/components/ui/badge'
import { Button, type ButtonProps } from '@/web/components/ui/button'
import { type NavigationItemType } from '@/web/constants/navigation'
import { cn } from '@/web/libs/utils'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import router from 'next/router'
import { type ComponentProps } from 'react'

function findBestMatchNavigationItem(
  items: NavigationItemType[],
  pathname: string
): NavigationItemType | null {
  const exactMatches = items.filter(
    item => item.href.toLowerCase() === pathname
  )
  const partialMatches = items.filter(
    item =>
      item.href.toLowerCase().startsWith(pathname) ||
      pathname.startsWith(item.href.toLowerCase()) ||
      (item.basePath && pathname.startsWith(item.basePath))
  )

  if (exactMatches.length > 0) {
    const exactMatch =
      exactMatches.find(item => !item.children) ?? exactMatches[0] ?? null
    console.log(
      'Path %o is exact match for navigation item %o',
      pathname,
      exactMatch
    )
    return exactMatch
  }

  if (partialMatches.length > 0) {
    const partialMatch =
      partialMatches.find(item => !item.children) ?? partialMatches[0] ?? null
    console.log(
      'Path %o is partial match for navigation item %o',
      pathname,
      partialMatch
    )
    return partialMatch
  }

  console.log('No matching navigation item found for path %o', pathname, items)
  return null
}

const NavButton = ({
  item,
  selectedNavigationItem,
  onClick,
  isExpanded,
  isShowingChildren,
}: Pick<ComponentProps<typeof NavButtonList>, 'onClick'> & {
  item: NavigationItemType
  selectedNavigationItem: NavigationItemType | null
  isExpanded: boolean
  isShowingChildren: boolean
}) => {
  const badgeHookResult = item.useBadgeHook ? item.useBadgeHook() : null
  const warningHookResult = item.useWarningHook ? item.useWarningHook() : null
  const visibleHookResult = item.useVisibleHook ? item.useVisibleHook() : null
  return (
    <li
      key={item.name}
      className={cn(
        'shrink-0',
        visibleHookResult === null || visibleHookResult === true
          ? undefined
          : 'hidden'
      )}>
      <Link href={item.href} className='w-full'>
        <Button
          variant='sidebar'
          state={selectedNavigationItem === item ? 'selected' : undefined}
          className={cn('gap-4', !isExpanded && 'size-10 justify-center p-0')}
          onClick={onClick}
          startIcon={
            item.icon ? (
              <item.icon className='h-4 w-4 shrink-0' aria-hidden='true' />
            ) : null
          }>
          {isExpanded ? (
            <div className='flex w-full flex-row items-center justify-between'>
              {item.name}
              {badgeHookResult != null ? (
                <Badge size='xs' theme='gradient'>
                  {badgeHookResult}
                </Badge>
              ) : null}
              {warningHookResult ? (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size='lg'
                  className='text-destructive'
                  title={
                    typeof warningHookResult === 'string'
                      ? warningHookResult
                      : undefined
                  }
                />
              ) : undefined}
            </div>
          ) : null}
        </Button>
      </Link>
      {isExpanded &&
      isShowingChildren &&
      item.children &&
      selectedNavigationItem === item ? (
        <NavButtonList
          items={item.children}
          className='ml-5 pl-5 pr-5 pt-2 border-l border-border border-'
          onClick={onClick}
          isOpen={isExpanded}
        />
      ) : null}
    </li>
  )
}

export const NavButtonList = ({
  items,
  className,
  onClick,
  isOpen = true,
  isShowingChildren = true,
}: {
  className?: string
  items: NavigationItemType[]
  onClick?: ButtonProps['onClick']
  isOpen?: boolean
  isShowingChildren?: boolean
}) => {
  const selectedNavigationItem = findBestMatchNavigationItem(
    items,
    router.pathname
  )
  if (!selectedNavigationItem) {
    console.log('No matching navigation item found', items, router.pathname)
  }

  return (
    <ul
      role='list'
      className={cn('flex w-full shrink-0 flex-col gap-y-1', className)}>
      {items.map(item => {
        return (
          <NavButton
            item={item}
            isExpanded={isOpen}
            key={item.name}
            selectedNavigationItem={selectedNavigationItem}
            onClick={onClick}
            isShowingChildren={isShowingChildren}
          />
        )
      })}
    </ul>
  )
}
