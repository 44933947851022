import { api } from '@/web/utils/api'

export const useNeedsIntegration = () => {
  const integrationsQueryResult = api.integration.list.useQuery()
  if (!integrationsQueryResult.isSuccess) {
    return null
  }

  return !integrationsQueryResult.data?.some(integration => {
    return integration.isEnabled
  })
}
