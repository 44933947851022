import {
  SUPPORTED_TASK_CONTEXT_INTEGRATION_NAMES,
  type ContextMetadataType,
  type ContextWithItems,
  type IntegrationContextDataType,
  type IntegrationContextDatumType,
} from '@/shared/constants/context'
import { type GmailEmailType } from '@/shared/constants/gmail'
import { type GoogleCalendarEventType } from '@/shared/constants/google-calendar'
import {
  type ContactType,
  type DateType,
  type LinkType,
  type SummaryMetadataType,
  type SummaryType,
} from '@/shared/constants/llm'
import { type NotionPage } from '@/shared/constants/notion'
import { type NonEmptySlackConversation } from '@/shared/constants/slack'
import {
  TASK_TYPES_WITHOUT_CONTEXT,
  type TaskWithAtLeast1ContextItem,
  type TaskWithIntegrationContextItems,
} from '@/shared/constants/task'
import { isGmailData } from '@/shared/utils/gmail'
import { isGoogleCalendarData } from '@/shared/utils/google-calendar'
import { isNotionData } from '@/shared/utils/notion'
import { isSlackData } from '@/shared/utils/slack'
import {
  IntegrationName,
  type ContextItem,
  type Prisma,
  type Task,
} from '@prisma/client'
import { log } from 'debug'
import _ from 'lodash'

/////////////////
// SUMMARIZING //
/////////////////

export function isSummary(
  summary?: Partial<SummaryType>
): summary is SummaryType {
  return (
    !!summary &&
    typeof summary === 'object' &&
    'summary' in summary &&
    !!summary.summary
  )
}

export function hasSummaryLinks(
  summary: SummaryType
): summary is SummaryType & { links: [LinkType, ...LinkType[]] } {
  return !!summary.links?.length
}
export function hasSummaryDates(
  summary: SummaryType
): summary is SummaryType & { dates: [DateType, ...DateType[]] } {
  return !!summary.dates?.length
}
export function hasSummaryContacts(
  summary: SummaryType
): summary is SummaryType & {
  contacts: [ContactType, ...ContactType[]]
} {
  return !!summary.contacts?.length
}
export function hasSummaryMetadata(
  summary: SummaryType
): summary is SummaryType & { metadata: SummaryMetadataType } {
  return !_.isEmpty(summary.metadata)
}
export function hasSummaryAdditionalData(
  summary?: Partial<SummaryType>
): summary is SummaryType {
  return (
    isSummary(summary) &&
    (hasSummaryLinks(summary) ||
      hasSummaryDates(summary) ||
      hasSummaryContacts(summary) ||
      hasSummaryMetadata(summary))
  )
}

export function hasContextMetadata(
  contextMetadata: unknown
): contextMetadata is ContextMetadataType {
  return (
    !!contextMetadata &&
    typeof contextMetadata === 'object' &&
    ('searchQuery' in contextMetadata || 'reason' in contextMetadata)
  )
}

export function hasContext<TIntegrationName extends IntegrationName>(
  task: Task | TaskWithAtLeast1ContextItem | null,
  integrationName?: IntegrationName
): task is TaskWithIntegrationContextItems<TIntegrationName> {
  return (
    !!task &&
    'contexts' in task &&
    task.contexts.some(
      context =>
        'items' in context &&
        context.items?.length &&
        (!integrationName || context.integrationName === integrationName)
    )
  )
}

export function getMissingGeneratedContextIntegrationNames({
  task,
  contexts,
  integrationNames,
}: {
  task: Task
  contexts: ContextWithItems[]
  integrationNames: IntegrationName[]
}): IntegrationName[] {
  if (task.type && TASK_TYPES_WITHOUT_CONTEXT.includes(task.type)) {
    return []
  }

  console.debug(
    'Looking for missing contexts in task %o in integrations %o: %O',
    task.title,
    integrationNames,
    contexts
  )
  return integrationNames.filter(integrationName => {
    // Context is considered missing if it's one of the specified integrations
    // and it hasn't been generated yet. It could be the task origin, so no
    // additional context was generated after creating the task.
    const existingContext = contexts.find(
      existingContext => existingContext.integrationName === integrationName
    )
    const isContextMissing =
      SUPPORTED_TASK_CONTEXT_INTEGRATION_NAMES.includes(integrationName) &&
      !existingContext?.lastGeneratedDate
    if (isContextMissing) {
      console.log(
        'Missing %o context for task %o user %o',
        integrationName,
        task.title,
        task.userId
      )
    }
    return isContextMissing
  })
}

export function hasMissingGeneratedContext({
  task,
  contexts,
  integrationNames,
}: {
  task: Task
  contexts: ContextWithItems[]
  integrationNames: IntegrationName[]
}): boolean {
  return (
    getMissingGeneratedContextIntegrationNames({
      task,
      contexts,
      integrationNames,
    }).length > 0
  )
}

//////////////
// BUILDING //
//////////////

export function getObjectAndChildIds<TIntegrationName extends IntegrationName>({
  integrationName,
  datum,
}: {
  integrationName: TIntegrationName
  datum: IntegrationContextDatumType<TIntegrationName>
}): { objectId: string; childObjectIds: string[] } {
  if (integrationName === IntegrationName.SLACK) {
    return {
      objectId: (datum as NonEmptySlackConversation).id,
      childObjectIds: (datum as NonEmptySlackConversation).messages?.map(
        message => message.id
      ),
    }
  }

  if (integrationName === IntegrationName.GMAIL) {
    return {
      objectId: (datum as GmailEmailType).id,
      childObjectIds: [
        (datum as GmailEmailType).threadId ?? (datum as GmailEmailType).id,
      ],
    }
  }

  if (integrationName === IntegrationName.NOTION) {
    return {
      objectId: (datum as NotionPage).id,
      childObjectIds: [],
    }
  }

  if (integrationName === IntegrationName.GOOGLE_CALENDAR) {
    return {
      objectId: (datum as GoogleCalendarEventType).calendar.id,
      childObjectIds: [(datum as GoogleCalendarEventType).id],
    }
  }

  log('Unsupported integration name for object ids: %s', integrationName)
  throw new Error('Unsupported integration name')
}

export function buildContextItems<
  TIntegrationName extends IntegrationName,
  TContextData extends IntegrationContextDataType<TIntegrationName>,
>({
  data,
  integrationName,
}: {
  data: TContextData
  integrationName: TIntegrationName
}): Prisma.ContextItemUncheckedCreateWithoutContextInput[] {
  let contextItems: Prisma.ContextItemUncheckedCreateWithoutContextInput[] = []
  if (integrationName === IntegrationName.SLACK) {
    contextItems =
      (isSlackData(data) &&
        data.conversations?.map((conversation, index) => ({
          order: index,
          objectId: conversation.id,
          childObjectIds: conversation.messages?.map(message => message.id),
          integrationName,
          isTaskOrigin: !!conversation.messages?.some(
            message => message.isTaskOrigin
          ),
          reason: conversation.reason, // TODO
          searchQuery: conversation.searchQuery, // TODO
        }))) ||
      []
  } else if (integrationName === IntegrationName.GMAIL) {
    contextItems =
      (isGmailData(data) &&
        data.emails?.map((email, index) => ({
          order: index,
          objectId: email.id,
          childObjectIds: [email.threadId ?? email.id],
          integrationName,
          isTaskOrigin: !!email.isTaskOrigin,
          reason: email.reason, // TODO
          searchQuery: email.searchQuery, // TODO
        }))) ||
      []
  } else if (integrationName === IntegrationName.GOOGLE_CALENDAR) {
    contextItems =
      (isGoogleCalendarData(data) &&
        data.events.map((event, index) => ({
          order: index,
          objectId: event.calendar.id,
          childObjectIds: [event.id],
          integrationName,
          // isTaskOrigin: !!event.isTaskOrigin, // TODO
          reason: event.contextMetadata?.reason, // TODO
          searchQuery: event.contextMetadata?.searchQuery, // TODO
        }))) ||
      []
  } else if (integrationName === IntegrationName.NOTION) {
    contextItems =
      (isNotionData(data) &&
        data.pages.map((page, index) => ({
          order: index,
          objectId: page.id,
          // childObjectIds: page.children?.map(child => child.id),
          integrationName,
          reason: page.reason,
          searchQuery: page.searchQuery,
        }))) ||
      []
  } else {
    console.log(
      'Unsupported integration for context %o or invalid data: %O',
      integrationName,
      data
    )
    throw new Error(`Unsupported integration name: ${integrationName}`)
  }

  return contextItems
}

/////////////////
// TASK ORIGIN //
/////////////////

export function getTaskOriginContextItem(
  integrationName: IntegrationName | null,
  contexts: ContextWithItems[]
): ContextItem | null {
  if (!integrationName) {
    return null
  }

  const taskOriginContext = contexts.find(
    context => context.integrationName === integrationName
  )
  if (!taskOriginContext?.items.length) {
    return null
  }

  // FIXME: This fixes cases where isTaskOrigin was reset but there's only 1
  // viable task origin
  if (taskOriginContext.items.length === 1) {
    return taskOriginContext.items.at(0)!
  }

  return taskOriginContext.items.find(item => item.isTaskOrigin) ?? null
}
