import { useRouter } from 'next/router'

export const usePageTitle = () => {
  const router = useRouter()

  if (router.route.length <= 1) {
    return null
  }

  // Use the capitalized end of the path as the page title
  const path = router.pathname
  const pathParts = path.split('/')
  let title = pathParts
    .pop()
    ?.replace(/-/g, ' ')
    .replace(/^\w/, c => c.toUpperCase())

  if (title === 'Index') {
    title = 'Dashboard'
  }

  if (path.includes('apps/')) {
    // Prefix with the app name, which is the part after /apps/
    const appNameIndex = pathParts.indexOf('apps') + 1
    const appName = pathParts[appNameIndex]
    if (appNameIndex > 0 && appName) {
      title = `${appName.replace(/^\w/, c => c.toUpperCase())} ${title}`
    }
  }

  return title
}
