import { PRIMARY, SECONDARY, TERTIARY } from '@/shared/constants/color'
import { useId, type SVGProps } from 'react'

export const LogoVertical = ({
  isDark,
  ...svgProps
}: { isDark?: boolean } & Partial<SVGProps<SVGSVGElement>>) => {
  const [pId, uId, lId, sId, eId] = [
    useId(),
    useId(),
    useId(),
    useId(),
    useId(),
  ]

  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='20 20 290 119'
      xmlSpace='preserve'
      {...svgProps}>
      <g>
        <linearGradient
          id={pId}
          gradientUnits='userSpaceOnUse'
          x1='128.0676'
          y1='86.6922'
          x2='297.7649'
          y2='83.7914'>
          <stop offset='0' stopColor={PRIMARY} />
          <stop offset='0.4863' stopColor={SECONDARY} />
          <stop offset='1' stopColor={TERTIARY} />
        </linearGradient>
        <path
          fill={`url(#${pId})`}
          d='M163.45,66.54L163.45,66.54
		c-1.54-2.02-3.51-3.66-5.84-4.88c-2.34-1.22-5.17-1.85-8.39-1.85c-2.55,0-4.93,0.51-7.06,1.53c-1.63,0.77-3.06,1.91-4.26,3.39
		v-3.84h-11.74v52.14h12.28V98.19c1.21,1.37,2.65,2.46,4.29,3.25c2.16,1.04,4.55,1.57,7.1,1.57c3.02,0,5.69-0.59,7.96-1.77
		c2.26-1.17,4.17-2.76,5.69-4.73c1.5-1.95,2.64-4.23,3.38-6.76c0.73-2.51,1.1-5.16,1.1-7.88c0-2.86-0.37-5.65-1.1-8.3
		C166.14,70.92,164.99,68.55,163.45,66.54z M155.25,86.13c-0.29,1.45-0.79,2.73-1.48,3.8c-0.68,1.06-1.59,1.93-2.7,2.59
		c-1.09,0.65-2.49,0.98-4.15,0.98c-1.61,0-3-0.33-4.12-0.99c-1.13-0.66-2.05-1.53-2.73-2.59c-0.69-1.08-1.21-2.36-1.52-3.81
		c-0.32-1.48-0.48-3.01-0.48-4.55c0-1.59,0.15-3.15,0.45-4.64c0.29-1.45,0.79-2.74,1.48-3.85c0.68-1.08,1.59-1.98,2.71-2.67
		c1.09-0.67,2.48-1.01,4.14-1.01c1.61,0,2.97,0.34,4.06,1.01c1.11,0.69,2.04,1.6,2.75,2.71c0.72,1.13,1.25,2.44,1.56,3.89
		c0.32,1.48,0.48,3.01,0.48,4.55C155.7,83.11,155.55,84.64,155.25,86.13z'
        />

        <linearGradient
          id={uId}
          gradientUnits='userSpaceOnUse'
          x1='128.001'
          y1='82.797'
          x2='297.6983'
          y2='79.8962'>
          <stop offset='0' stopColor={PRIMARY} />
          <stop offset='0.4863' stopColor={SECONDARY} />
          <stop offset='1' stopColor={TERTIARY} />
        </linearGradient>
        <path
          fill={`url(#${uId})`}
          d='M196.07,82.41c0,3.88-0.63,6.68-1.86,8.32
		c-1.21,1.6-3.14,2.38-5.92,2.38c-2.39,0-3.99-0.69-4.89-2.11c-0.95-1.5-1.43-3.84-1.43-6.98V60.89h-12.28v25.14
		c0,2.5,0.23,4.81,0.67,6.87c0.45,2.11,1.26,3.94,2.39,5.42c1.15,1.5,2.73,2.67,4.7,3.48c1.95,0.8,4.45,1.21,7.45,1.21
		c2.39,0,4.77-0.54,7.05-1.6c1.81-0.84,3.36-2.1,4.65-3.76v4.36h11.74V60.89h-12.28V82.41z'
        />

        <linearGradient
          id={lId}
          gradientUnits='userSpaceOnUse'
          x1='127.9002'
          y1='76.9005'
          x2='297.5975'
          y2='73.9997'>
          <stop offset='0' stopColor={PRIMARY} />
          <stop offset='0.4863' stopColor={SECONDARY} />
          <stop offset='1' stopColor={TERTIARY} />
        </linearGradient>

        <rect
          fill={`url(#${lId})`}
          x='213.68'
          y='48.65'
          width='12.28'
          height='53.37'
        />

        <linearGradient
          id={sId}
          gradientUnits='userSpaceOnUse'
          x1='128.0126'
          y1='83.4761'
          x2='297.7099'
          y2='80.5753'>
          <stop offset='0' stopColor={PRIMARY} />
          <stop offset='0.4863' stopColor={SECONDARY} />
          <stop offset='1' stopColor={TERTIARY} />
        </linearGradient>
        <path
          fill={`url(#${sId})`}
          d='M263.36,80.21
		c-1.23-0.88-2.65-1.6-4.22-2.13c-1.53-0.52-3.12-0.96-4.72-1.33c-1.54-0.36-3.06-0.69-4.55-1c-1.46-0.3-2.76-0.64-3.88-1.02
		c-1.06-0.36-1.93-0.82-2.58-1.37c-0.56-0.48-0.84-1.09-0.84-1.87c0-0.66,0.15-1.16,0.46-1.52c0.34-0.41,0.75-0.71,1.25-0.93
		c0.52-0.23,1.12-0.38,1.76-0.45c0.7-0.07,1.35-0.11,1.93-0.11c1.83,0,3.43,0.35,4.76,1.04c1.22,0.63,1.88,1.85,2.02,3.72l0.05,0.63
		h11.75l-0.06-0.74c-0.21-2.56-0.88-4.72-1.99-6.43c-1.1-1.69-2.5-3.07-4.17-4.09c-1.65-1.01-3.55-1.74-5.64-2.16
		c-4.12-0.83-8.69-0.82-12.83-0.04c-2.11,0.4-4.05,1.1-5.75,2.08c-1.73,1-3.15,2.39-4.2,4.12c-1.06,1.74-1.6,3.98-1.6,6.66
		c0,1.86,0.39,3.46,1.17,4.77c0.76,1.28,1.79,2.37,3.05,3.22c1.23,0.83,2.64,1.5,4.21,2.01c1.53,0.49,3.12,0.93,4.72,1.29
		c3.83,0.81,6.87,1.63,9.01,2.43c1.9,0.72,2.83,1.72,2.83,3.06c0,0.83-0.19,1.51-0.56,2.03c-0.4,0.56-0.91,1.01-1.51,1.34
		c-0.64,0.35-1.35,0.61-2.12,0.78c-1.92,0.41-3.63,0.3-5.23-0.11c-0.91-0.23-1.73-0.6-2.43-1.09c-0.68-0.48-1.25-1.1-1.69-1.86
		c-0.42-0.74-0.64-1.64-0.64-2.7v-0.68h-11.77l0.03,0.7c0.11,2.77,0.75,5.12,1.91,6.99c1.15,1.85,2.65,3.36,4.44,4.48
		c1.77,1.11,3.83,1.92,6.11,2.4c2.24,0.47,4.56,0.71,6.91,0.71c2.28,0,4.56-0.22,6.79-0.67c2.25-0.45,4.28-1.24,6.04-2.36
		c1.77-1.13,3.23-2.64,4.33-4.49c1.11-1.87,1.67-4.2,1.67-6.92c0-1.96-0.39-3.64-1.17-4.99C265.63,82.24,264.61,81.11,263.36,80.21z
		'
        />

        <linearGradient
          id={eId}
          gradientUnits='userSpaceOnUse'
          x1='128.0247'
          y1='84.1799'
          x2='297.7219'
          y2='81.2791'>
          <stop offset='0' stopColor={PRIMARY} />
          <stop offset='0.4863' stopColor={SECONDARY} />
          <stop offset='1' stopColor={TERTIARY} />
        </linearGradient>
        <path
          fill={`url(#${eId})`}
          d='M308.92,75.09
		c-0.74-2.89-1.95-5.51-3.61-7.77c-1.67-2.28-3.83-4.12-6.43-5.47c-2.6-1.35-5.68-2.04-9.16-2.04c-3.11,0-5.98,0.56-8.54,1.67
		c-2.56,1.11-4.79,2.65-6.64,4.58c-1.85,1.93-3.3,4.25-4.3,6.88c-1,2.63-1.51,5.51-1.51,8.55c0,3.15,0.49,6.07,1.47,8.7
		c0.98,2.64,2.38,4.94,4.18,6.84c1.8,1.91,4.03,3.4,6.63,4.43c2.58,1.03,5.51,1.55,8.71,1.55c4.62,0,8.63-1.07,11.9-3.19
		c3.3-2.13,5.77-5.69,7.35-10.59l0.29-0.89h-11.05l-0.14,0.49c-0.31,1.07-1.21,2.13-2.66,3.13c-1.47,1.02-3.25,1.53-5.3,1.53
		c-2.88,0-5.1-0.74-6.6-2.19c-1.4-1.35-2.22-3.54-2.44-6.52h28.6l0.04-0.63C309.92,81.01,309.65,77.97,308.92,75.09z M281.1,76.51
		c0.08-0.53,0.21-1.12,0.4-1.75c0.25-0.88,0.7-1.73,1.34-2.53c0.63-0.79,1.49-1.47,2.55-2.01c1.05-0.54,2.41-0.81,4.04-0.81
		c2.45,0,4.29,0.65,5.46,1.93c1.09,1.2,1.89,2.93,2.4,5.17H281.1z'
        />
      </g>
      {/* <text
            transform='matrix(1 0 0 1 312.0561 83.7402)'
            fill={TERTIARY}
            style={{ fontSize: 30.4718 }}>
            ®
          </text> */}
      <g>
        <path
          fill={PRIMARY}
          d='M76.01,35.4C76.01,35.4,76.01,35.4,76.01,35.4c-0.69,0-1.37,0.02-2.04,0.06c-4.92-0.1-9.39-1.99-12.79-5.06
		c-0.32-0.29-0.63-0.59-0.94-0.9c-4.07-3.69-9.47-5.94-15.4-5.94c-12.65,0-22.91,10.26-22.91,22.91c0,6.16,2.43,11.74,6.38,15.86
		c0.79,0.82,1.63,1.58,2.53,2.28c3.31,3.53,5.35,8.28,5.35,13.5c0,5.17-1.99,9.87-5.23,13.39c-0.4,0.43-0.81,0.84-1.24,1.24
		c-4.55,4.82-7.35,11.32-7.35,18.47c0,14.87,12.05,26.92,26.92,26.92c12.08,0,22.31-7.96,25.72-18.93c0.19-0.61,0.35-1.23,0.49-1.85
		c1.79-5.57,5.98-10.07,11.37-12.25c0.61-0.2,1.21-0.41,1.81-0.64c13.45-5.11,23.02-18.12,23.02-33.37
		C111.7,51.38,95.72,35.4,76.01,35.4z'
        />
        <path
          fill={SECONDARY}
          d='M54,122.74c8.84,0,16.32-5.82,18.81-13.84c0.14-0.44,0.26-0.9,0.36-1.35c1.31-4.08,4.38-7.36,8.31-8.96
		c0.45-0.14,0.89-0.3,1.32-0.47c9.84-3.74,16.84-13.25,16.84-24.41c0-14.42-11.69-26.11-26.11-26.11c0,0,0,0,0,0
		c-0.5,0-1,0.02-1.49,0.05c-3.6-0.07-6.87-1.46-9.36-3.7c-0.24-0.21-0.46-0.43-0.68-0.66c-2.98-2.7-6.93-4.35-11.26-4.35
		c-9.26,0-16.76,7.5-16.76,16.76c0,4.5,1.78,8.59,4.67,11.6c0.57,0.6,1.19,1.16,1.85,1.67c2.42,2.58,3.91,6.06,3.91,9.88
		c0,3.78-1.45,7.22-3.83,9.79c-0.29,0.31-0.59,0.62-0.91,0.9c-3.33,3.53-5.37,8.28-5.37,13.51C34.3,113.92,43.12,122.74,54,122.74z'
        />
        <path
          fill={TERTIARY}
          d='M71.07,59.8C71.07,59.8,71.07,59.8,71.07,59.8c-0.32,0-0.63,0.01-0.95,0.03c-2.28-0.05-4.35-0.92-5.92-2.34
		c-0.15-0.13-0.29-0.27-0.43-0.42c-1.88-1.71-4.38-2.75-7.13-2.75c-5.86,0-10.61,4.75-10.61,10.61c0,2.85,1.13,5.44,2.95,7.34
		c0.36,0.38,0.76,0.73,1.17,1.05c1.53,1.63,2.48,3.83,2.48,6.25c0,2.39-0.92,4.57-2.42,6.2c-0.18,0.2-0.37,0.39-0.58,0.57
		c-2.11,2.23-3.4,5.24-3.4,8.55c0,6.88,5.58,12.47,12.47,12.47c5.6,0,10.33-3.69,11.91-8.76c0.09-0.28,0.16-0.57,0.23-0.86
		c0.83-2.58,2.77-4.66,5.26-5.67c0.28-0.09,0.56-0.19,0.84-0.29c6.23-2.37,10.66-8.39,10.66-15.45C87.6,67.2,80.2,59.8,71.07,59.8z'
        />
      </g>
    </svg>
  )
}
