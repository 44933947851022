import { AiSchema } from '@/shared/constants/ai'
import { type ContextMetadataType } from '@/shared/constants/context'
import type { SimilarityScoreType } from '@/shared/constants/embedding'
import {
  type SummaryType,
  type TokenCountMetadataType,
} from '@/shared/constants/llm'
import type { SearchMatchType } from '@/shared/constants/search'
import { ActionType } from '@prisma/client'
import { z } from 'zod'

export type GmailAttachmentType = GmailAttachmentMetadataType & {
  content?: Blob | null
}

export type GmailAttachmentMetadataType = Partial<TokenCountMetadataType> &
  Partial<SummaryType> & {
    filename: string
    mimeType?: string | null
    size?: number | null
    attachmentId: string
    partId?: string | null
  }

export type GmailEmailType = Partial<SimilarityScoreType> &
  Partial<ContextMetadataType> &
  Partial<SummaryType> &
  Partial<TokenCountMetadataType> & {
    // Raw email data
    id: string
    snippet?: string // A brief preview of the email's content.
    from: string // Sender's email address.
    to: string[] // List of primary recipients' email addresses.
    cc?: string[] // List of CC (carbon copy) recipients.
    bcc?: string[] // List of BCC (blind carbon copy) recipients.
    subject: string
    date: Date | string // Received date or sent date.
    isRead?: boolean // Indicates whether the email has been read.
    hasAttachments?: boolean // Indicates if the email contains any attachments.
    labels: string[] // List of Gmail labels applied to the email.
    threadId?: string // ID of the thread to which this email belongs.
    bodyPreview?: string // A longer preview of the email's body (e.g., first few lines).
    mimeType?: string // The MIME type of the email (e.g., "text/plain", "text/html").
    sizeEstimate?: number // Estimated size of the email in bytes.
    importance?: 'HIGH' | 'NORMAL' | 'LOW' // Importance flag, if set.
    inReplyTo?: string // The email ID to which this email is replying.
    references?: string[] // List of email IDs referenced in this conversation.
    isCreatedByPulse?: boolean

    // Body extraction
    body: {
      plain?: string
      html?: string
    } | null

    // Attachments
    attachmentsMetadata?: GmailAttachmentMetadataType[]

    // Task
    isTaskOrigin?: boolean

    // Search
    matchType?: SearchMatchType
  }

export type GmailLabelType = {
  id: string // Unique identifier for the label.
  name: string // Display name of the label.
  color?: {
    textColor?: string // Color of the label's text.
    backgroundColor?: string // Background color of the label.
  } // Optional color settings for the label.
  type: 'user' | 'system' // Indicates if the label was created by the user or is a system label (e.g., INBOX, SPAM).
  messagesTotal?: number // Total number of messages with this label.
  messagesUnread?: number // Number of unread messages with this label.
  threadsTotal?: number // Total number of threads with this label.
  threadsUnread?: number // Number of unread threads with this label.
  visibility: 'labelShow' | 'labelHide' | 'labelShowIfUnread' // Determines how the label is shown in the UI.
  showInMessageList?: boolean // Indicates if the label is shown in the message list.
  showInLabelList?: boolean // Indicates if the label is shown in the label list.
}

export const GmailSettingsInput = z.object({
  inboxLabel: z.string().optional(),
  sentLabel: z.string().optional(),
  draftLabel: z.string().optional(),
  isAutoDrafting: z.boolean().default(true),
})

export type GmailSettingsType = z.infer<typeof GmailSettingsInput>

export type GmailContextDataType = {
  emails: GmailEmailType[]
}

export const MessageOptionsSchema = z.array(
  z.object({
    title: z.string(),
    message: z.string().optional(),
    isSelected: z.boolean().optional(),
  })
)
export type MessageOptionsType = z.infer<typeof MessageOptionsSchema>

export const GMAIL_ACTION_TYPE_PARAMETERS_SCHEMA = {
  [ActionType.SEND_EMAIL]: z.object({
    from: z.string(),
    to: z.array(z.string().email()),
    subject: z.string(),
    message: z.string(),
  }),
  [ActionType.REPLY_TO_EMAIL]: AiSchema.extend({
    emailId: z.string(),
    to: z.array(z.string()),
    cc: z.array(z.string()).optional(),
    from: z.string(),
    message: z.string(),
    draftId: z.string().optional(),
    messageOptions: MessageOptionsSchema.optional(),
    email: z.record(z.any()).optional(),
  }),
  [ActionType.FORWARD_EMAIL]: z.object({
    emailId: z.string().describe('Email ID'),
    forwardTo: z.string().email().describe('Email'),
  }),
  [ActionType.ARCHIVE_EMAILS]: z.object({
    emailIds: z.array(z.string()).describe('Email IDs to archive'),
  }),
  [ActionType.ARCHIVE_EMAIL]: z.object({
    emailId: z.string().describe('Email ID to archive'),
  }),
  [ActionType.REPORT_SPAM_EMAIL]: z.object({
    emailId: z.string().describe('Email ID to report as spam'),
  }),
  [ActionType.REPORT_SPAM_EMAILS]: z.object({
    emailIds: z.array(z.string()).describe('Email IDs to report as spam'),
  }),
} as const satisfies Partial<Record<ActionType, z.SomeZodObject>>

export const GMAIL_ACTION_TYPES = [
  ActionType.SEND_EMAIL,
  ActionType.REPLY_TO_EMAIL,
  ActionType.ARCHIVE_EMAILS,
  ActionType.ARCHIVE_EMAIL,
  ActionType.REPORT_SPAM_EMAIL,
  ActionType.REPORT_SPAM_EMAILS,
]
// TODO
// export const GMAIL_ACTION_TYPES = Object.keys(
//   GMAIL_ACTION_TYPE_PARAMETERS_SCHEMA
// ) as (keyof typeof GMAIL_ACTION_TYPE_PARAMETERS_SCHEMA)[]

export type GmailSendEmailActionResult = z.infer<
  (typeof GMAIL_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.SEND_EMAIL]
> & {
  emailId: string
}
export type GmailReplyToEmailActionResult = z.infer<
  (typeof GMAIL_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.REPLY_TO_EMAIL]
> & {
  isArchived?: boolean
}

// NOTE: Should be synced with @/gmail-addon/appscript.json
export const GMAIL_ADDON_SCOPES = [
  'https://www.googleapis.com/auth/contacts.readonly',
  'https://www.googleapis.com/auth/gmail.readonly',
  'https://www.googleapis.com/auth/gmail.modify',
  'https://www.googleapis.com/auth/gmail.settings.basic',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
]
export const GMAIL_SCOPES = [
  ...GMAIL_ADDON_SCOPES,
  'https://www.googleapis.com/auth/gmail.send',
]
