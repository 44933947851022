import {
  type NotionAnyObject,
  type NotionBlock,
  type NotionContextDataType,
  type NotionDatabase,
  type NotionObject,
  type NotionPage,
  type NotionUser,
} from '@/shared/constants/notion'
import {
  type TaskWithContextItems,
  type TaskWithIntegrationContextItems,
} from '@/shared/constants/task'
import { getSummaryPromptString } from '@/shared/summary'
import { hasContext, isSummary } from '@/shared/utils/context'
import { IntegrationName } from '@prisma/client'
import { log } from 'debug'
import _ from 'lodash'

export const isNotionData = (data: unknown): data is NotionContextDataType => {
  return !!data && typeof data === 'object' && 'pages' in data
}

export function hasNotionData(
  task: TaskWithContextItems
): task is TaskWithIntegrationContextItems<typeof IntegrationName.NOTION> {
  return hasContext(task, IntegrationName.NOTION)
}

////////////
// STRING //
////////////

export const getPageSnippetString = (page: NotionPage): string => {
  return (
    page.children
      ?.map(child =>
        convertNotionObjectToString(child, {
          isPlain: true,
        })
      )
      .join(', ')
      .trim() ?? ''
  )
}

export const getPagePropertiesString = (
  properties: NotionPage['properties']
): string => {
  return Object.entries(properties)
    .filter(([_, value]) => value?.length > 0)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ')
}

export const convertNotionObjectToString = (
  object: NotionPage | NotionDatabase | NotionBlock | NotionUser,
  {
    filter,
    prefix = '',
    useSummary,
    isPlain = false,
  }: {
    filter?: (obj: NotionObject) => boolean
    prefix?: string
    useSummary?: boolean
    isPlain?: boolean
  } = {}
): string => {
  const nextPrefix = prefix + '  '
  const nextOptions = {
    filter,
    prefix: nextPrefix,
  }

  if (filter && !filter(object as NotionObject)) {
    log('Filtered out object: %O', object)
    return ''
  }

  const basicInfoString = isPlain
    ? ''
    : `${prefix}[id: ${object.id}][type: ${object.type}][created by: ${
        object.createdBy?.name ?? 'missing'
      }][last edited by: ${
        object.lastEditedBy?.name ?? 'missing'
      }][last edited: ${
        _.isDate(object.lastEditedDate)
          ? object.lastEditedDate.toISOString()
          : object.lastEditedDate ?? 'missing'
      }]`

  if (object.type === 'page') {
    const pageContentString =
      useSummary && isSummary(object)
        ? getSummaryPromptString(object)
        : object.children
            ?.map(child => convertNotionObjectToString(child, nextOptions))
            .join('\n')

    return `${prefix}${isPlain ? '' : 'PAGE: '}${
      object.title
    }\n${prefix}(${getPagePropertiesString(
      object.properties
    )})\n${basicInfoString}\n${pageContentString}`
  }
  if (object.type === 'database') {
    return `${prefix}${isPlain ? '' : 'DATABASE: '}${
      object.title
    }\n${basicInfoString}${isPlain ? '' : '\n"""\n'}${object.rows
      ?.map(child => convertNotionObjectToString(child, nextOptions))
      .join('\n')}${isPlain ? '' : '\n"""\n'}`
  }
  if (object.type === 'block') {
    return `${prefix}${object.text ?? ''}${
      object.children
        ? '\n' +
          object.children
            ?.map(child => convertNotionObjectToString(child, nextOptions))
            .join('\n')
        : ''
    }`
  }
  if (object.type === 'user') {
    return `${prefix}${isPlain ? '' : 'USER: '}${object.name}`
  }

  log('Unhandled object type in string conversion:', object)
  return isPlain ? '' : '<UNHANDLED>'
}

export const toMarkdown = (
  object: NotionPage | NotionDatabase | NotionBlock | NotionUser,
  {
    filter,
    prefix = '',
  }: { filter?: (obj: NotionObject) => boolean; prefix?: string } = {}
): string => {
  const nextPrefix = prefix + '  '
  const nextOptions = {
    filter,
    prefix: nextPrefix,
  }

  if (filter && !filter(object as NotionObject)) {
    console.log('Filtered out object: %O', object)
    return ''
  }

  if (object.type === 'page') {
    return (
      object.children
        ?.map(child => toMarkdown(child, nextOptions))
        .join('\n') ?? ''
    )
  }
  if (object.type === 'database') {
    return (
      object.rows?.map(child => toMarkdown(child, nextOptions)).join('\n') ?? ''
    )
  }
  if (object.type === 'block') {
    if (object.raw.type === 'table') {
      if (
        object.raw.table.has_row_header &&
        object.children?.length &&
        !!object.children[0]
      ) {
        return (
          toMarkdown(object.children[0], {
            ...nextOptions,
            prefix: '',
          }) +
          '\n' +
          object.text +
          '\n' +
          object.children
            ?.slice(1)
            .map(tableRow =>
              toMarkdown(tableRow, { ...nextOptions, prefix: '' })
            )
            .join('\n')
        )
      }

      return (
        object.text +
        '\n' +
        object.children
          ?.map(tableRow =>
            toMarkdown(tableRow, { ...nextOptions, prefix: '' })
          )
          .join('\n')
      )
    }
    return `${prefix}${object.text ?? ''}${
      object.children
        ? '\n' +
          object.children
            ?.map(child => convertNotionObjectToString(child, nextOptions))
            .join('\n')
        : ''
    }`
  }
  if (object.type === 'user') {
    return `${prefix}${object.name}`
  }

  console.log('Unhandled object type in markdown conversion:', object)
  return ''
}

export const convertNotionObjectsToStrings = (
  objects: NotionAnyObject[],
  { useSummary }: { useSummary?: boolean } = {}
): string[] =>
  objects.map(object => convertNotionObjectToString(object, { useSummary }))
