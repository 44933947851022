import { Link } from '@/web/components/Link'
import { ROUTE } from '@/web/constants/router'

export function RedditSettings() {
  return (
    <section>
      <h6>
        Navigate to the{' '}
        <Link href={ROUTE.REDDIT_DASHBOARD} variant='premium'>
          Reddit Dashboard
        </Link>{' '}
        to configure your account.
      </h6>
    </section>
  )
}
