import type { Action } from '@prisma/client'
import { z } from 'zod'

export const QuestionsSchema = z.object({
  questions: z.array(z.string()),
  reason: z.string(),
})

export const AnswerSchema = z.string().optional()
export type AnswerType = z.infer<typeof AnswerSchema>
export const AnswerToQuestionSchema = z
  .object({ answer: AnswerSchema })
  .describe(
    'An object containing any data that can be used to answer the user’s question.'
  )

export const AnswersSchema = z.record(AnswerSchema)
export type AnswersType = z.infer<typeof AnswersSchema>

export const AiSchema = z.object({
  answers: AnswersSchema.optional(),
  questions: z
    .object({
      question: z.string(),
      searchResultsAndScores: z.array(
        z.tuple([z.record(z.any()), z.number(), z.record(z.number())])
      ),
      features: z.record(z.number()),
    })
    .optional(),
  examples: z.array(z.string()).optional(),
  toolKeys: z.array(z.string()).optional(),
})
export type AiSchemaType = z.infer<typeof AiSchema>

export type GeneratedActionResultType = [false, Action | null] | [true, Action]
