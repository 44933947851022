import { env } from '@/web/env'
import { AnalyticsBrowser } from '@segment/analytics-next'

export const segment = AnalyticsBrowser.load(
  {
    writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  },
  {
    disable: env.NEXT_PUBLIC_NODE_ENV === 'development',
  }
)
