import { cn } from '@/web/libs/utils'
import { motion, type SVGMotionProps } from 'framer-motion'
import React, { useEffect, useState } from 'react'

// const colors = ['#f871ff', '#8535f5', '#00b8d7']
const colors = ['#e879f9', '#7c3aed', '#06b6d4']

// const drawStagger = 0.5
// const drawDuration = 1
// const drawTotalDuration = drawStagger * 2 + drawDuration

const scaleStagger = 0.15
const scaleInitial = 1
const scaleChange = 0.2

const colorDuration = 8
const colorStagger = 0.75

// const animate = ['scalePulse', 'colorPulse']
const animate = ['colorPulse']

const variants: SVGMotionProps<SVGPathElement>['variants'] = {
  initial: _i => ({
    // pathLength: 0,
  }),
  // draw: i => ({
  //   pathLength: 1,
  //   transition: {
  //     delay: drawStagger * i,
  //     duration: drawDuration,
  //     ease: 'easeOut',
  //   },
  // }),
  scalePulse: i => ({
    transform: [
      `scale(${scaleInitial})`,
      `scale(${scaleInitial + scaleChange * (1 - i / 3)})`,
      `scale(${scaleInitial})`,
      `scale(${scaleInitial})`,
      `scale(${scaleInitial + scaleChange * (1 - i / 3)})`,
      `scale(${scaleInitial})`,
      `scale(${scaleInitial})`,
    ],
    transition: {
      times: [0, 0.05, 0.1, 0.15, 0.2, 0.25, 1],
      // delay: drawTotalDuration + (i * scaleStagger) / 2,
      delay: (i * scaleStagger) / 2,
      duration: 4,
      repeat: Infinity,
      ease: 'easeOut',
      repeatType: 'loop',
    },
  }),
  colorPulse: i => ({
    color: [...colors, ...colors].slice(0, colors.length + 1).reverse(),
    transition: {
      duration: colorDuration,
      repeat: Infinity,
      repeatType: 'loop',
      ease: 'easeInOut',
      delay: -colors.length - 1 + i * colorStagger,
    },
  }),
}

type PropsType = {
  isAnimated?: boolean
  className?: string
  isScalingOnScroll?: boolean
  scrollRef?: React.RefObject<HTMLElement>
}
export const LogoSvg: React.FC<PropsType> = ({
  isAnimated = true,
  className,
}) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const canAnimate = isAnimated && isMounted

  // let scrollYProgress: MotionValue<number> | undefined = undefined
  // if (isScalingOnScroll) {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   scrollYProgress = useScroll({
  //     target: scrollRef,
  //     offset: ['end end', 'start start'],
  //   }).scrollYProgress
  // }

  return (
    <motion.svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='76 43 215 274'
      className={cn('h-full w-full', className)}
      // style={{ scaleX: scrollYProgress }}
      xmlSpace='preserve'>
      <g>
        <motion.path
          custom={2}
          variants={canAnimate ? variants : undefined}
          initial='initial'
          animate={animate}
          fill={canAnimate ? 'currentColor' : colors[2]}
          d='M205.24,71.51C205.24,71.51,205.24,71.51,205.24,71.51c-1.64,0-3.26,0.06-4.87,0.15
		c-11.74-0.24-22.42-4.76-30.54-12.08c-0.77-0.69-1.51-1.42-2.23-2.16c-9.71-8.82-22.61-14.19-36.76-14.19
		c-30.22,0-54.71,24.49-54.71,54.71c0,14.7,5.81,28.03,15.24,37.86c1.88,1.96,3.9,3.77,6.04,5.43c7.91,8.43,12.77,19.77,12.77,32.24
		c0,12.33-4.74,23.56-12.5,31.96c-0.95,1.03-1.93,2.01-2.97,2.95c-10.87,11.51-17.54,27.03-17.54,44.11
		c0,35.5,28.78,64.29,64.29,64.29c28.85,0,53.27-19.01,61.4-45.19c0.45-1.45,0.83-2.93,1.18-4.42
		c4.27-13.31,14.29-24.03,27.14-29.26c1.46-0.47,2.9-0.98,4.32-1.52c32.12-12.21,54.96-43.26,54.96-79.67
		C290.45,109.66,252.3,71.51,205.24,71.51z'
        />
        <motion.path
          custom={1}
          variants={canAnimate ? variants : undefined}
          initial='initial'
          animate={animate}
          fill={canAnimate ? 'currentColor' : colors[1]}
          d='M152.68,280.05c21.11,0,38.96-13.9,44.91-33.05c0.33-1.06,0.61-2.14,0.86-3.23
		c3.12-9.73,10.45-17.58,19.85-21.4c1.07-0.34,2.12-0.71,3.16-1.11c23.5-8.93,40.2-31.65,40.2-58.28
		c0-34.43-27.91-62.33-62.33-62.34c0,0,0,0-0.01,0c-1.2,0-2.38,0.04-3.56,0.11c-8.59-0.18-16.4-3.48-22.34-8.84
		c-0.56-0.51-1.11-1.04-1.63-1.58c-7.11-6.45-16.54-10.38-26.89-10.38c-22.1,0-40.02,17.92-40.02,40.02
		c0,10.75,4.25,20.5,11.15,27.69c1.37,1.43,2.85,2.76,4.42,3.98c5.79,6.17,9.34,14.46,9.34,23.58c0,9.02-3.47,17.23-9.14,23.38
		c-0.69,0.75-1.41,1.47-2.17,2.16c-7.95,8.42-12.83,19.77-12.83,32.26C105.66,259,126.71,280.05,152.68,280.05z'
        />
        <motion.path
          custom={0}
          variants={canAnimate ? variants : undefined}
          initial='initial'
          animate={animate}
          fill={canAnimate ? 'currentColor' : colors[0]}
          d='M193.45,129.77C193.45,129.77,193.45,129.77,193.45,129.77c-0.76,0-1.51,0.03-2.26,0.07
		c-5.43-0.11-10.38-2.21-14.14-5.59c-0.36-0.32-0.7-0.66-1.03-1c-4.5-4.08-10.47-6.57-17.02-6.57c-13.99,0-25.33,11.34-25.33,25.33
		c0,6.8,2.69,12.98,7.05,17.53c0.87,0.91,1.8,1.75,2.8,2.52c3.66,3.9,5.91,9.15,5.91,14.93c0,5.71-2.2,10.91-5.79,14.8
		c-0.44,0.47-0.9,0.93-1.37,1.37c-5.03,5.33-8.12,12.51-8.12,20.42c0,16.44,13.33,29.76,29.76,29.76c13.36,0,24.66-8.8,28.43-20.92
		c0.21-0.67,0.39-1.36,0.55-2.05c1.98-6.16,6.62-11.13,12.57-13.55c0.68-0.22,1.34-0.45,2-0.7c14.87-5.65,25.45-20.03,25.45-36.89
		C232.9,147.43,215.24,129.77,193.45,129.77z'
        />
      </g>
      {/* <motion.g>
        <motion.path
          custom={2}
          variants={canAnimate ? variants : undefined}
          initial='initial'
          animate={['draw', 'colorPulse']}
          fill='none'
          stroke='currentColor'
          d='M205.24,71.51C205.24,71.51,205.24,71.51,205.24,71.51c-1.64,0-3.26,0.06-4.87,0.15
		c-11.74-0.24-22.42-4.76-30.54-12.08c-0.77-0.69-1.51-1.42-2.23-2.16c-9.71-8.82-22.61-14.19-36.76-14.19
		c-30.22,0-54.71,24.49-54.71,54.71c0,14.7,5.81,28.03,15.24,37.86c1.88,1.96,3.9,3.77,6.04,5.43c7.91,8.43,12.77,19.77,12.77,32.24
		c0,12.33-4.74,23.56-12.5,31.96c-0.95,1.03-1.93,2.01-2.97,2.95c-10.87,11.51-17.54,27.03-17.54,44.11
		c0,35.5,28.78,64.29,64.29,64.29c28.85,0,53.27-19.01,61.4-45.19c0.45-1.45,0.83-2.93,1.18-4.42
		c4.27-13.31,14.29-24.03,27.14-29.26c1.46-0.47,2.9-0.98,4.32-1.52c32.12-12.21,54.96-43.26,54.96-79.67
		C290.45,109.66,252.3,71.51,205.24,71.51z'
        />
        <motion.path
          custom={1}
          variants={canAnimate ? variants : undefined}
          initial='initial'
          animate={['draw', 'colorPulse']}
          fill='none'
          stroke='currentColor'
          d='M152.68,280.05c21.11,0,38.96-13.9,44.91-33.05c0.33-1.06,0.61-2.14,0.86-3.23
		c3.12-9.73,10.45-17.58,19.85-21.4c1.07-0.34,2.12-0.71,3.16-1.11c23.5-8.93,40.2-31.65,40.2-58.28
		c0-34.43-27.91-62.33-62.33-62.34c0,0,0,0-0.01,0c-1.2,0-2.38,0.04-3.56,0.11c-8.59-0.18-16.4-3.48-22.34-8.84
		c-0.56-0.51-1.11-1.04-1.63-1.58c-7.11-6.45-16.54-10.38-26.89-10.38c-22.1,0-40.02,17.92-40.02,40.02
		c0,10.75,4.25,20.5,11.15,27.69c1.37,1.43,2.85,2.76,4.42,3.98c5.79,6.17,9.34,14.46,9.34,23.58c0,9.02-3.47,17.23-9.14,23.38
		c-0.69,0.75-1.41,1.47-2.17,2.16c-7.95,8.42-12.83,19.77-12.83,32.26C105.66,259,126.71,280.05,152.68,280.05z'
        />
        <motion.path
          variants={canAnimate ? variants : undefined}
          initial='initial'
          animate={['draw', 'colorPulse']}
          fill='none'
          stroke='currentColor'
          custom={0}
          d='M193.45,129.77C193.45,129.77,193.45,129.77,193.45,129.77c-0.76,0-1.51,0.03-2.26,0.07
		c-5.43-0.11-10.38-2.21-14.14-5.59c-0.36-0.32-0.7-0.66-1.03-1c-4.5-4.08-10.47-6.57-17.02-6.57c-13.99,0-25.33,11.34-25.33,25.33
		c0,6.8,2.69,12.98,7.05,17.53c0.87,0.91,1.8,1.75,2.8,2.52c3.66,3.9,5.91,9.15,5.91,14.93c0,5.71-2.2,10.91-5.79,14.8
		c-0.44,0.47-0.9,0.93-1.37,1.37c-5.03,5.33-8.12,12.51-8.12,20.42c0,16.44,13.33,29.76,29.76,29.76c13.36,0,24.66-8.8,28.43-20.92
		c0.21-0.67,0.39-1.36,0.55-2.05c1.98-6.16,6.62-11.13,12.57-13.55c0.68-0.22,1.34-0.45,2-0.7c14.87-5.65,25.45-20.03,25.45-36.89
		C232.9,147.43,215.24,129.77,193.45,129.77z'
        />
      </motion.g> */}
    </motion.svg>
  )
}

LogoSvg.displayName = 'LogoSvg'
