import {
  type GoogleCalendarContextDataType,
  type GoogleCalendarCreateEventType,
  type GoogleCalendarEventType,
} from '@/shared/constants/google-calendar'
import {
  type TaskWithContextItems,
  type TaskWithIntegrationContextItems,
} from '@/shared/constants/task'
import dayjs from '@/shared/singletons/dayjs'
import { hasContext } from '@/shared/utils/context'
import { compareDate } from '@/shared/utils/sort'
import { IntegrationName } from '@prisma/client'
import { endOfDay, isSameDay, isWithinInterval, startOfDay } from 'date-fns'
// FIXME: v8 requires ESM
import queryString from 'query-string'
// import { rrulestr } from 'rrule'
// import { rrulestr } from 'rrule/dist/esm/rrulestr'
import * as rrule from 'rrule'

// console.log('Using rrule on shared', rrule)
const { rrulestr } = rrule

//////////////
// CHECKERS //
//////////////

export const isCreateEvent = (
  event: GoogleCalendarCreateEventType | GoogleCalendarEventType
): event is GoogleCalendarCreateEventType => {
  return (
    'title' in event &&
    'attendees' in event &&
    typeof event.attendees === 'string'
  )
}

export function isSameEvent(
  a: GoogleCalendarEventType,
  b: GoogleCalendarEventType
) {
  return a.id === b.id && a.calendar.id === b.calendar.id
}

export const isGoogleCalendarData = (
  data: unknown
): data is GoogleCalendarContextDataType => {
  return !!data && typeof data === 'object' && 'events' in data
}

export function hasGoogleCalendarData(
  task: TaskWithContextItems
): task is TaskWithIntegrationContextItems<
  typeof IntegrationName.GOOGLE_CALENDAR
> {
  return hasContext(task, IntegrationName.GOOGLE_CALENDAR)
}

export function isEventOnDay(event: GoogleCalendarEventType, day: Date) {
  if (event.start && event.end && !event.recurrence) {
    const eventStart = startOfDay(new Date(event.start))
    const eventEnd = endOfDay(new Date(event.end).getTime() - 1)

    return isWithinInterval(day, { start: eventStart, end: eventEnd })
  }

  if (event.recurrence) {
    const nextOccurrence = getNextOccurrence(startOfDay(day), event.recurrence)
    return nextOccurrence && isSameDay(day, nextOccurrence)
  }

  return false
}

export function isBusyScheduledEvent(
  event: GoogleCalendarEventType
): event is GoogleCalendarEventType &
  Required<Pick<GoogleCalendarEventType, 'start' | 'end'>> & { isBusy: true } {
  return (
    !!event.start &&
    !!event.end &&
    event.isAllDay !== true &&
    event.status !== 'cancelled' &&
    event.isBusy === true
  )
}

/////////////
// ACTIONS //
/////////////

const formatDateTime = (date: Date) => {
  // Format the date-time in YYYYMMDDTHHmmssZ format
  return dayjs(date).utc().format('YYYYMMDDTHHmmss') + 'Z'
}

export function getCreateEventUrl({
  title,
  details,
  location,
  start,
  end,
  attendees,
}: GoogleCalendarCreateEventType): string {
  const params = {
    action: 'TEMPLATE',
    text: title,
    details,
    location,
    dates: `${formatDateTime(start)}/${formatDateTime(end)}`,
    add: attendees.join(','),
  }

  const url = `https://calendar.google.com/calendar/render?${queryString.stringify(
    params
  )}`

  return url
}

export const getNextOccurrence = (
  date: Date,
  recurrenceString: string | undefined
): Date | undefined => {
  if (!recurrenceString) {
    return date
  }

  try {
    const rule = rrulestr(recurrenceString)
    const nextDate = rule.after(date, true)

    if (nextDate) {
      // Use dayjs to combine the date from nextDate with the time from date
      const combinedDate = dayjs(nextDate)
        .hour(dayjs(date).hour())
        .minute(dayjs(date).minute())
        .second(dayjs(date).second())
        .millisecond(dayjs(date).millisecond())
        .toDate()

      return combinedDate
    }
  } catch (error) {
    console.error('Error parsing RRULE:', error)
  }

  return undefined
}

/////////////
// SORTING //
/////////////

export function sortByStartTime(
  a: GoogleCalendarEventType,
  b: GoogleCalendarEventType
) {
  return compareDate(
    a.start ? new Date(a.start) : null,
    b.start ? new Date(b.start) : null
  )
}
