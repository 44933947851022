import { UPDATE_EVENT, type PusherDataType } from '@/shared/constants/pusher'
import { getTasksUpdateChannel } from '@/shared/utils/pusher'
import { pusherClient } from '@/web/singletons/pusher'
import { tasksUpdateDataState } from '@/web/state/tasks-update-data'
import { api } from '@/web/utils/api'
import dayjs from 'dayjs'
import { useSession } from 'next-auth/react'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { toast } from 'sonner'

export const useTasksUpdateData = () => {
  const session = useSession()

  const [taskUpdateData, setTaskUpdateData] =
    useRecoilState(tasksUpdateDataState)
  const taskUpdateDataRef = React.useRef(taskUpdateData)

  const utils = api.useUtils()
  useEffect(() => {
    if (taskUpdateData?.status === 'success' && !taskUpdateData?.scopeId) {
      console.log('Task update job succeeded - refetching tasks list')
      utils.tasks.count.invalidate()
      utils.tasks.orderedList.invalidate()
      toast.success('Finished updating tasks', {
        description: taskUpdateData.message,
      })
      return
    }
  }, [taskUpdateData])

  const userId = session.data?.user.id
  useEffect(() => {
    if (!userId) {
      return
    }

    console.log(
      'Subscribing to task update channel',
      getTasksUpdateChannel({ userId })
    )
    const channel = pusherClient.subscribe(getTasksUpdateChannel({ userId }))
    const onUpdate = (data: PusherDataType) => {
      if (
        taskUpdateDataRef.current?.date &&
        dayjs(data.date).isBefore(dayjs(taskUpdateDataRef.current?.date))
      ) {
        console.log('Ignoring outdated task update', {
          old: data,
          current: taskUpdateDataRef.current,
        })
        return
      }

      console.log(
        'Received %o message from tasks updating job %o',
        data.status,
        data.message,
        {
          previous: taskUpdateDataRef.current,
          current: data,
        }
      )
      setTaskUpdateData(data)
    }
    channel.bind(UPDATE_EVENT, onUpdate)

    return () => {
      console.log(
        'Unsubscribing from tasks updating channel',
        getTasksUpdateChannel({ userId })
      )
      channel.unbind(UPDATE_EVENT, onUpdate)
    }
  }, [userId])

  useEffect(() => {
    taskUpdateDataRef.current = taskUpdateData
  }, [taskUpdateData])

  return null
}
