export type KeyboardKey =
  | 'Backspace'
  | 'Enter'
  | 'Escape'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'metaKey'
  | 'shiftKey'
  | 'ctrlKey'
  | string

export const KEYBOARD_SHORTCUT = {
  TASKS_FIRST: ['metaKey', 'ArrowUp'],
  TASKS_LAST: ['metaKey', 'ArrowDown'],
  TASKS_NEXT: ['ArrowDown'],
  TASKS_PREVIOUS: ['ArrowUp'],
  TASKS_TOGGLE_OPEN: ['Enter'],
  TASK_COMPLETE_TOGGLE: ['Space'],
  TASK_CREATE: ['Enter'],
  TASK_CREATE_FOCUS: ['metaKey', 'Enter'],
  TASK_BLUR: ['Escape'],
  SEARCH_ON_ENTER: ['Enter'],
  SEARCH_FOCUS: ['ctrlKey', 'F'],
  SEARCH_CLEAR: ['metaKey', 'Backspace'],
} as const satisfies Record<string, KeyboardKey[]>
