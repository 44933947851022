import { SettingsInput } from '@/shared/constants/settings'
import { api } from '@/web/utils/api'

export const useNeedsSettings = () => {
  const settingsQueryResult = api.settings.get.useQuery()
  if (!settingsQueryResult.isSuccess) {
    return null
  }

  const validationResult = SettingsInput.safeParse(settingsQueryResult.data)
  return !validationResult.success
}
