import localFont from 'next/font/local'

export const stolzlBold = localFont({
  // @important must match tailwind.config.js
  variable: '--font-stolzl-bold',
  preload: true,
  src: './public/fonts/Stolzl Bold.ttf',
})

export const helveticaNeue = localFont({
  // @important must match tailwind.config.js
  variable: '--font-helvetica-neue',
  preload: true,
  src: [
    {
      style: 'normal',
      weight: '900',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Black.otf',
    },
    {
      style: 'italic',
      weight: '900',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-BlackItalic.ttf',
    },
    {
      style: 'normal',
      weight: '800',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Semibold.otf',
    },
    {
      style: 'italic',
      weight: '800',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-SemiboldItalic.otf',
    },
    {
      style: 'normal',
      weight: '700',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Bold.ttf',
    },
    {
      style: 'italic',
      weight: '700',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-BoldItalic.ttf',
    },
    {
      weight: '600',
      style: 'normal',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Medium.ttf',
    },
    {
      weight: '600',
      style: 'italic',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-MediumItalic.ttf',
    },
    {
      weight: '500',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Regular.ttf',
    },
    {
      weight: '400',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Regular.ttf',
    },
    {
      style: 'normal',
      weight: '300',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Light.ttf',
    },
    {
      style: 'italic',
      weight: '300',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-LightItalic.ttf',
    },
    {
      style: 'normal',
      weight: '200',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-Thin.ttf',
    },
    {
      style: 'italic',
      weight: '200',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-ThinItalic.ttf',
    },
    {
      style: 'normal',
      weight: '100',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-UltraLight.ttf',
    },
    {
      style: 'italic',
      weight: '100',
      path: './public/fonts/Helvetica-Neue/HelveticaNeue-UltraLightItalic.ttf',
    },
  ],
})

export const fonts = [stolzlBold, helveticaNeue]
export const fontVariables = [stolzlBold.variable, helveticaNeue.variable]
